import React from "react";
// Customizable Area Start
import {
    Box,
    styled,
    IconButton,
    Typography,
    Avatar,
    Button,
    CircularProgress,
    TextField,
    Modal,
    Snackbar,
} from "@material-ui/core";
import Loader from "../../../components/src/Loader.web";
import { arrowDownIcon, avatarImg, instagramLogo } from "./assets";
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import PostCard from "../../../components/src/PostCard.web";
import BuyerFeedController, { configJSON, Props, PostDataPayload } from "./BuyerFeedController.web";
import { conditionalRenderer } from "../../../components/src/utils";
import StoryListing from "../../../components/src/CustomStoryListing.web";
import {  
    FacebookShareButton,
    WhatsappShareButton,
    FacebookIcon,
    WhatsappIcon,
    TelegramIcon,
    TelegramShareButton
 } from "react-share";
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { cancelIcon, profileImg } from "../../landingpage/src/assets";
import Alert from '@material-ui/lab/Alert';
// Customizable Area End



export default class BuyerFeed extends BuyerFeedController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderStorySection = () => {
        return (
            <div
                className="storyViewContainer" ref={this.storyBoxRef} >
                {this.state.showLeftStories &&
                    <IconButton className="leftArrow" data-test-id="prevBtnTestID" onClick={this.scrollStoriesLeft}>
                        <ArrowBackIos />
                    </IconButton>}
        {this.state.allStoryHeader.map((item, userIndex) => (
            <Box
                className="feedBtn"
                data-test-id={`openStoryTestID${userIndex}`}
                key={userIndex}
                onClick={() => this.handleClickOpen(userIndex)}>
                <Box
                    className={item.attributes.seen ? "storyBorderSeen storyBorder" : "storyBorder"}>
                    <img
                        data-test-id={`imgUserTestID${userIndex}`}
                        className="story"
                        src={item.attributes.creator_details.profile_url ?item.attributes.creator_details.profile_url : profileImg} />
                </Box>
            </Box>
        ))}
        {this.state.showRightStories && <IconButton data-test-id="nextBtnTestID" className="rightArrow" onClick={this.scrollStoriesRight}>
            <ArrowForwardIos />
        </IconButton>}
        <StoryListing
            allStoryHeader={this.state.allStoryHeader}
            toggleMute={this.toggleMute}
            storyMuted={this.state.storyMuted}
            likeStory={this.likeStory}
            calculateHours={this.calculateHours}
            data-test-id="storyModalTestId"
            currentStoryIndex={this.state.currentStoryIndex}
            setSwiperRef={this.setSwiperRef}
            showNextButton={this.showNextButton}
            userStory={this.state.userStory}
            changeStory={this.changeStory}
            showPrevButton={this.showPrevButton}
            instaModalIndex={this.state.instaModalIndex}
            instaModal={this.state.instaModal}
            handleNext={this.handleNext}
            handleClose={this.handleClose}
            handlePrevNext={this.handlePrevNext}
            handlePrev={this.handlePrev}
        />
    </div>)};

    renderShareModalView = () => {
        const { shareUrl, isShareModal } = this.state;
        return (
            <CustomShareModal open={isShareModal} onClose={this.handleShareModalToogle}>
                <Box className="shareModalContainer">
                    <Box className="shareModalHeader">
                        <Typography className="headingModal">{configJSON.shareTxt}</Typography>
                        <Box className="cancelIconBox">
                            <IconButton onClick={this.handleShareModalToogle}><img src={cancelIcon} /></IconButton>
                        </Box>
                    </Box>
                    <Box className="shareButtonContainer">
                        <Box className="shareButtonBox">
                            <FacebookShareButton className="socialButton" url={shareUrl} quote="Check out this Flixoo Live Post!!">
                                <FacebookIcon size={50} round={true} />
                            </FacebookShareButton>
                            <Typography className="shareLabelTxt">{configJSON.facebookTxt}</Typography>
                        </Box>

                        <Box className="shareButtonBox">
                            <WhatsappShareButton className="socialButton" url={shareUrl} title="Check out this Flixoo Live Post!!">
                                <WhatsappIcon size={50} round={true} />
                            </WhatsappShareButton>
                            <Typography className="shareLabelTxt">{configJSON.whatsappTxt}</Typography>
                        </Box>

                        <Box className="shareButtonBox">
                            <IconButton className="customSocialBtn">
                                <img src={instagramLogo} alt="Instagram" className="instagramIcon" />
                            </IconButton>
                            <Typography className="shareLabelTxt">{configJSON.instagramTxt}</Typography>
                        </Box>

                        <Box className="shareButtonBox">
                            <TelegramShareButton className="socialButton" url={shareUrl} title="Check out this Flixoo Live Post!!">
                                <TelegramIcon size={50} round={true} />
                            </TelegramShareButton>
                            <Typography className="shareLabelTxt">{configJSON.telegramTxt}</Typography>
                        </Box>

                        <Box className="shareButtonBox">
                            <IconButton className="customSocialBtn" onClick={this.handleCopyLink} data-test-id="copyLinkTestId">
                                <FileCopyOutlinedIcon className="copyIcon" />
                            </IconButton>
                            <Typography className="shareLabelTxt">{configJSON.copyLink}</Typography>
                        </Box>
                    </Box>
                </Box>
            </CustomShareModal>
        )
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <PostListingStyleWrapper>
                {this.renderShareModalView()}
                <Loader loading={this.state.isLoading} />
                <Box className="mainContainer">
                    <Snackbar data-test-id="snackbarTestId" open={this.state.isToastOpen} autoHideDuration={6000} onClose={this.handleToggleToast} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} >
                        <Alert severity={this.state.severity} onClose={this.handleToggleToast}>
                            {this.state.toastMessage}
                        </Alert>
                    </Snackbar>
                    <Box className="gridBox">
                        <Box className="headerCategoryBox">
                            <Typography className="categoryTxt">{configJSON.categoriesTxt}</Typography>
                        </Box>
                        <Box className="categoriesBox">
                            {this.state.categoriesData.map((category, index) => (
                                <Box className="categoryInnerBox" key={index}>
                                    <Box>
                                        <Typography className="categoryTitleTxt">{category}</Typography>
                                    </Box>
                                    <Box>
                                        <img src={arrowDownIcon} />
                                    </Box>
                                </Box>
                            ))}
                            
                        </Box>
                    </Box>
                    <Box className="middleGridBox">
                        {this.renderStorySection()}
                        <PostListContainer>
                            <div className="postMainContainer" ref={this.postContainerRef}>
                                <Box className="postContainer">
                                    {this.state.buyerPostData.map((postData: PostDataPayload, postIndex: number) => {
                                        return (
                                            <PostCard
                                                data-test-id={`postCardCompTestId${postIndex}`}
                                                item={postData}
                                                postKeyId={postIndex}
                                                postId={postData.id}
                                                handleToggleLikePost={this.handleToggleLikePost}
                                                handleToggleComment={this.handleToggleComment}
                                                clickFollowButton={this.handleCreatorFollow}
                                                handlePostShareToggle={this.handlePostShareToggle}
                                            />
                                        )
                                    })}
                                    {(this.state.isFeedLoader && this.state.hasMorePosts ) ? <CircularProgress />: ''}
                                </Box>
                            </div>
                        </PostListContainer>
                    </Box>

                    {
                        this.state.showCommentBox === "commentbox" ?
                            <Box className="suggestionCommentBox">
                                <Typography className="suggestionForTxt">Comments</Typography>
                                <Box className="suggestionMainCommentContainer">
                                    {this.state.listCommentData.map((item, itemIndex) => (
                                        <Box className="suggestedUserBox" key={itemIndex}>
                                            <Box className="suggestionLeftBoxForComment">
                                                <Box className="avatarSuggestionBox">
                                                    <Avatar src={item?.attributes?.details?.profile_photo} className="suggestionAvatar" />
                                                </Box>
                                                <Box className="suggestionInfoBox">
                                                    <Typography className="userNameTxt">{item?.attributes?.details?.full_name}</Typography>
                                                    <Typography className="userFullNameTxt">{item?.attributes?.details?.comment}</Typography>

                                                    <Box className="suggestionLinkComment">
                                                        <Typography className="userNameTxt" style={{ marginTop: '4px' }}>{item?.attributes?.details?.created_at}</Typography>
                                                        <Typography data-test-id="likeComment" onClick={() => this.likeCommentApiCall(item.id)} style={{ cursor: 'pointer' , color: conditionalRenderer(item?.attributes?.details?.like_count > 0, 'blue', '#44444480') }} className="userFullNameTxt">{item?.attributes?.details?.like_count} Likes</Typography>
                                                        <Typography className="userFullNameTxt" style={{ cursor: 'pointer'}} data-test-id="doubleTap" onClick={() => this.clickOnReplyForGetId(item.id)}>Reply</Typography>
                                                    </Box>

                                                    {item?.attributes?.details?.replies_count > 0 &&
                                                        <ReplyContainer>
                                                            <div className="blankLine"></div>
                                                            <Typography variant="body2" data-test-id='clickReply' className="text" onClick={() => this.viewDetilsExpandable(parseInt(item?.id))}>
                                                              {!this.state.clickReply.includes(parseInt(item.id))  ? "View" : "Hide"} {item?.attributes?.details?.replies_count} replies
                                                            </Typography>
                                                        </ReplyContainer>}

                                                    {
                                                        this.state.clickReply.includes(parseInt(item.id)) &&
                                                        item.attributes.details.sub_post_comments.map((value, itemIndex) => (
                                                            <Box className="suggestionLeftBoxForComment" key={itemIndex}>
                                                                <Box className="avatarSuggestionBox">
                                                                    <Avatar src={value.profile_photo} className="suggestionAvatar" />
                                                                </Box>
                                                                <Box className="suggestionInfoBox">
                                                                    <Typography className="userNameTxt">{value.full_name}</Typography>
                                                                    <Typography className="userFullNameTxt">{value.comment}</Typography>

                                                                    <Box className="suggestionLinkComment">
                                                                        <Typography className="userNameTxt" style={{ marginTop: '4px' }}>{value.created_at}</Typography>
                                                                        <Typography data-test-id="replyCmmntId" style={{ cursor: 'pointer', color:conditionalRenderer(value?.like_count > 0, 'blue', '#44444480') }} onClick={() => this.likeReplyCommentApiCall(value?.sub_comment_id)} className="userFullNameTxt">{value.like_count} Likes</Typography>
                                                                    </Box>

                                                                </Box>
                                                            </Box>
                                                        ))
                                                    }
                                                </Box>
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                                <CommentContainer>
                                    <Avatar
                                        className="avatar"
                                        src={avatarImg}
                                        alt="User Avatar"
                                    />
                                    <TextField
                                        variant="outlined"
                                        placeholder="Add a Comment"
                                        className="input"
                                        fullWidth
                                        data-test-id="changeValue"
                                        value={this.state.addComment}
                                        onChange={this.handleInputChange} 
                                    />
                                    <Button
                                        variant="outlined"
                                        color="default"
                                        className="postButton"
                                        onClick={ this.state.setReplyId === "" ? this.postCommentApiCall : this.postCommentOnCommentApiCall}
                                    >
                                        POST
                                    </Button>
                                </CommentContainer>
                            </Box> :
                            <Box className="suggestionBox">
                                <Typography className="suggestionForTxt">Suggested for you</Typography>
                                <Box className="suggestionMainContainer">
                                    {[1, 2, 3, 4, 5, 6].map((__, itemIndex) => (
                                        <Box className="suggestedUserBox" key={itemIndex}>
                                            <Box className="suggestionLeftBox">
                                                <Box className="avatarSuggestionBox">
                                                    <Avatar src={avatarImg} className="suggestionAvatar" />
                                                </Box>
                                                <Box className="suggestionInfoBox">
                                                    <Typography className="userNameTxt">QuantumLeap77</Typography>
                                                    <Typography className="userFullNameTxt">Quantum Leap</Typography>
                                                </Box>
                                            </Box>
                                            <Box className="followBox">
                                                <Button className="followUnfollowBtn">Unfollow</Button>
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                    }
                </Box>
            </PostListingStyleWrapper>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const PostListingStyleWrapper = styled(Box)({
    marginTop: "104px",
    "@media(max-width: 1150px)": {
        marginTop: "71px",
    },
    "& .mainContainer":{
        display:"flex"
    },
    "& .gridBox": {
        width: "100%",
        maxWidth:"244px",
        borderRight:"3px solid #D9D9D94D",
        padding:"0px 20px",
        "@media(max-width:700px)": {
            display:"none"
        },
    },
    "& .middleGridBox": {
        width: "100%",
        // maxWidth:"1000px",
        position: "relative",
        overflowX:"hidden",
        display: "flex",
        flexDirection: "column"
    },
    "& .suggestionBox": {
        borderLeft: "1px solid #44444433",
        width: "100%",
        maxWidth:"427px",
        marginTop: "30px",
        paddingLeft: "24px",
        paddingRight: "60px",
        "@media(max-width:800px)": {
            display:"none"
        },
    },
    "& .suggestionCommentBox": {
        borderLeft: "1px solid #44444433",
        width: "100%",
        maxWidth:"440px",
        marginTop: "30px",
        paddingLeft: "24px",
        paddingRight: "60px",
        "@media(max-width:800px)": {
            display:"none"
        },
    },
    "& .suggestionMainCommentContainer":{
        height: 'calc(100vh - 230px)',
        overflow: 'scroll'
    },
    "& .storyViewContainer": {
        width:"100%",
        display: "flex",
        padding: "0px 20px",
        boxSizing: "border-box",
        gap: "24px",
        overflowX: "scroll",
        scrollbarWidth: "none",
        marginTop: "46px",
        paddingBottom: "12px",
        scrollBehavior: "smooth",
        marginBottom: "20px",
        "&:hover": {
            "& .leftArrow, & .rightArrow": {
                display: "flex"
            }
        },
        "&::-webkit-scrollbar": {
            height: "4px",
        },
        "&::-webkit-scrollbar-track": {
            borderRadius: "10px",
            background: "#D9D9D955"
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#D9D9D9",
            borderRadius: "10px",
        },
    },
    "& .feedBtn": {
        cursor: "pointer",
    },
    "& .storyBorder": {
        width: "74px",
        height: "74px",
        borderRadius: "60px",
        display: "flex",
        padding: "2.46px",
        background: "linear-gradient(130.56deg, #EB3AA7 9.42%, #6649D9 87%)",
        justifyContent: "center",
        alignItems: "center"
    },
    "& .storyBorderSeen": {
        background: "grey !important"
    },
    "& .story": {
        borderRadius: "60px",
        padding: "4.07px",
        background: "#FFFFFF",
        width: "70px",
        height: "70px",
    },
    "& .leftArrow, & .rightArrow": {
        position: "absolute",
        top: "84px",
        transform: "translateY(-50%)",
        zIndex: 1,
        backgroundColor: "#FFFFFF",
        borderRadius: "50%",
        width: "28px",
        height: "28px",
        display: "none",
        alignItems: "center",
        justifyContent: "center",
        padding: "4px"
    },
    "& .rightArrow": {
        right: 10
    },
    "& .leftArrow": {
        left:10
    },
    "& .suggestionForTxt": {
        fontWeight: 500,
        fontSize: "16px",
        color: "#000000",
    },
    "& .userNameTxt": {
        color: "#444444",
        fontWeight: 500,
        fontSize: "14px",
    },
    "& .userFullNameTxt": {
        color: "#44444480",
        fontWeight: 400,
        fontSize: "14px",
        marginTop: "4px"
    },
    "& .suggestedUserBox": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "16px"
    },
    "& .suggestionLeftBox": {
        display: "flex",
        gap: "12px",
        justifyContent: "center",
        alignItems: "center"
    },
    "& .followUnfollowBtn": {
        textTransform: "none",
        border: "1px solid #F5EA16",
        borderRadius: "50px",
        padding: "6px 16px"
    },
    "& .categoryTxt":{
        color:"#9695A1",
        fontSize:"16px",
        fontWeight:400,
        paddingLeft:"20px"
    },
    "& .headerCategoryBox":{
        paddingBottom:"10px",
        borderBottom:"1px solid #F2F3F6",
        marginTop:"40px"
    },
    "& .categoryInnerBox":{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        marginTop:"27px",
        marginLeft:"20px"
    },
    "& .categoriesBox":{
        marginTop:"14px"
    },
    "& .categoryTitleTxt":{
        fontSize:"12px",
        fontWeight:400,
        color:"#000000"
    },
    "& .suggestionLeftBoxForComment":{
        display: "flex",
        gap: "12px",
        // justifyContent: "center",
        alignItems: "start",
        marginTop: '10px'
    },
    "& .suggestionLinkComment":{
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        gap: '15px'
    }
});
const PostListContainer = styled(Box)({
    "& .postMainContainer": {
        height: "calc(100vh - 152px)",
        overflowY: "auto",
        scrollBehavior: "smooth",
    },
    "& .postContainer": {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
})

const CommentContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    border: '1px solid black',
    borderRadius: '50px',
    // padding: '10px',
    maxWidth: '500px',
    marginRight: '10px',
    marginTop: '20px',
    "& .avatar":{
        marginLeft: '10px',
    },
    "& .input": {
        flex: 1,
        '& .MuiOutlinedInput-root': {
            borderRadius: '30px',
            borderColor: 'transparent',
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
            }
        },
    },
    "& .postButton": {
        marginLeft: '10px',
        borderRadius: '30px',
        fontWeight: 'bold',
        padding: '6px 16px',
        border: 'none'
      },
})

const ReplyContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    marginTop: '5px',
    '& .blankLine': {
        width: '50px',
        height: '3px',
        background: '#B5B3A76E',
        marginRight: '10px'
    },
    '& .text': {
        color: '#1E1A00', // light grey color
        cursor: 'pointer', // Change cursor to pointer on hover
        '&:hover': {
            textDecoration: 'underline',
        },
    },
})

const CustomShareModal = styled(Modal)({
    "& .shareModalContainer": {
        top: "50%",
        position: "absolute",
        border: "none",
        left: "50%",
        padding: "10px 20px",
        transform: "translate(-50%, -50%)",
        background: "white",
        borderRadius: "24px",
        maxWidth: "fit-content",
        width: "100%",
    },
    "& .shareButtonBox": {
        flexDirection: "column",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    "& .shareButtonContainer": {
        gap: "25px",
        display: "flex",
        flexWrap: "wrap",
        padding: "30px",
        alignItems: "center",
        paddingTop:"14px",
        justifyContent: "center",
    },
    "& .shareModalHeader": {
        justifyContent: "space-between",
        display: "flex",
        padding: "0px 2px",
        marginTop: "10px",
        alignItems: "center",
    },
    "& .headingModal": {
        fontWeight: 600,
        fontSize: "18px",
    },
    "& .socialButton": {
        padding: "12px !important"
    },
    "& .customSocialBtn":{
        outline: "none", 
        background: "transparent", 
        border: 'none', 
    },
    "& .copyIcon":{
        height:"50px",
        width:"50px",
    },
    "& .instagramIcon":{
        borderRadius: '50%' ,
        width: '50px', 
        height: '50px', 
    },
    "& .shareLabelTxt":{
        fontWeight:600,
        fontSize:"16px",
        color:"grey",
    }
});
// Customizable Area End
