import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area Start
interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object | string;
}
interface ValidResponse {
  data: object;
  inventory_details: object;
  inventory: object;
}
interface ValidResponseMessage {
  message: string;
}
export interface ErrorResponse {
  errors: [
    {
      token: string;
      Tasklist: string;
    }
  ]
}

export interface ShipmentOptionArrayData  {
  fullfilment_by: [
  ],
  procurement_type: [
  ],
  hsn_code: [
  ],
tax_code: [
  ]
}

export interface ShipmentBackButtonData{
  inventory: {
      id: number,
      fullfilment_by: string,
      procurement_type: string,
      procurement_sla: string,
      account_id: number,
      stock_quantity: number,
      catalogue_id: number,
      created_at: string,
      updated_at: string
  },
  shipment_charge: {
      id: number,
      local_delivery_charge: string,
      zonal_delivery_charge: string,
      national_delivery_charge: string,
      account_id: string,
      catalogue_id: string,
      created_at: string,
      updated_at: string
  }
}

export interface ShipmentInventry {
  inventory_details: {
    data: {
        id: number,
        type: string,
        attributes: ShipmentOptionArrayData
    }
}
}
export interface ShipmentDataResponse {
  catalogue_id: number,
  inventory: {
    fullfilment_by: string,
    procurement_type: string,
    procurement_sla: string,
    stock_quantity: number
  },
  shipment_charge: {
    local_delivery_charge: number,
    zonal_delivery_charge: number,
    national_delivery_charge: number
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  classes: Record<string, string>;
  handleActiveStep: (step: number) => void;
  // Customizable Area End
};
export interface S {
  // Customizable Area Start
  fulfilmentBy: string | unknown;
  procurementType: string ;
  procurementSLA: string;
  stockQuantity: string;
  localCharge: string;
  zonalCharge: string;
  nationalCharge: string;
  procurementSLArequried: boolean;
  stockQuantityrequried: boolean;
  localChargerequried: boolean;
  zonalChargerequried: boolean;
  nationalChargerequried: boolean;
  postApiHit:boolean;
  shipmentInvenrtyResponse: ShipmentOptionArrayData;
  isStockQtyDisable: boolean
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}


export default class ShipmentProductController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  postShipmentApiCallId: string = "";
  getShipmentDetailApiCallId: string = "";
  getShipmentBackButtonDataApiCallId: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    // Customizable Area Start
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      fulfilmentBy: '',
      procurementType: '',
      procurementSLA: '',
      stockQuantity: '',
      localCharge: '',
      zonalCharge: '',
      nationalCharge: '',
      procurementSLArequried: false,
      stockQuantityrequried: false,
      localChargerequried: false,
      zonalChargerequried: false,
      nationalChargerequried: false,
      postApiHit:true,
      shipmentInvenrtyResponse: {} as ShipmentOptionArrayData,
      isStockQtyDisable: false
      // Customizable Area End
    };
  }

  // Customizable Area Start
  async componentDidMount() {
    await setStorageData('currentStep',2);
    this.selectResponse();
    this.backButtonResponse();
    const storetype = localStorage.getItem('storeType');
        if(storetype === 'offline'){
          this.setState({isStockQtyDisable:true})
        }
    }

    handleFulfilmentBy = (event: React.ChangeEvent<{ value: unknown }>) => {
      this.setState({
          fulfilmentBy: event.target.value as string,
      })   
  };
  
  handleProcurementType = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({
      procurementType: event.target.value as string,
    });
  };

  handleProcurementSLA = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      procurementSLA: event.target.value.trimStart(),
      procurementSLArequried: false,
    });
    if (this.state.isStockQtyDisable) {
      this.setState({
        stockQuantity: "10"
      })
    }
  };

  handleStockQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const numberReg = /^[0-9\b]+$/;
    if (numberReg.test(event.target.value) || event.target.value === "") {
      this.setState({
        stockQuantity: event.target.value,
        stockQuantityrequried: false,
      });
    }
  };

  handleLocalCharge = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^[\d]*(\.[\d]*)?$/;
    if (event.target.value === '' || regex.test(event.target.value)) {
      this.setState({
        localCharge: event.target.value,
        localChargerequried: false,
      });
    }
  };

  handleZonalCharge = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^[\d]*(\.[\d]*)?$/;
    if (event.target.value === '' || regex.test(event.target.value)) {
      this.setState({
        zonalCharge: event.target.value,
        zonalChargerequried: false,
      });
    }
  };

  handleNationalCharge = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^[\d]*(\.[\d]*)?$/;
    if (event.target.value === '' || regex.test(event.target.value)) {
      this.setState({
        nationalCharge: event.target.value,
        nationalChargerequried: false,
      });
    }
  };

  handleShipmentSubmit = (preventEvent: { preventDefault: () => void }) => {
    preventEvent.preventDefault()

    if (this.state.procurementSLA.length === 0) {
      this.setState({
        procurementSLArequried: true,
      });
    }
    if (this.state.localCharge.length === 0) {
      this.setState({
        localChargerequried: true,
      });
    }
    if (this.state.zonalCharge.length === 0) {
      this.setState({
        zonalChargerequried: true,
      });
    }
    if (this.state.nationalCharge.length === 0) {
      this.setState({
        nationalChargerequried: true,
      });
    }
    
    if (this.state.fulfilmentBy !== "" && this.state.procurementType !== "" && this.state.procurementSLA.length !== 0 && this.state.localCharge.length !== 0 && this.state.zonalCharge.length !== 0 && this.state.nationalCharge.length !== 0) {
      this.postAllTaskListDataShow();
    }

  };


  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.isValidResponse(responseJson)) {
        this.responseSuccessCall(apiRequestCallId, responseJson);
      } else if (this.isValidResponseMessage(responseJson)) {
        this.responseSuccessCall(apiRequestCallId, responseJson);
      } else if (this.isInValidResponse(responseJson)) {
        this.responseFailureCall(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  isValidResponse = (responseJson: ValidResponse) => {
    return responseJson && (responseJson.inventory_details || responseJson.inventory);
  };
  isValidResponseMessage = (responseJson: ValidResponseMessage) => {
    return responseJson && responseJson.message;
  };
  isInValidResponse = (responseJson: ErrorResponse) => {
    return responseJson && responseJson.errors;
  };

  postAllTaskListDataShow = async () => {
    const catalogueID =  await getStorageData("catalogueID");
    let raw = JSON.stringify({
      "catalogue_id": catalogueID,
      "inventory": {
        "fullfilment_by": this.state.fulfilmentBy,
        "procurement_type": this.state.procurementType,
        "procurement_sla": this.state.procurementSLA,
        "stock_quantity": Number(this.state.stockQuantity)
      },
      "shipment_charge": {
        "local_delivery_charge": Number(this.state.localCharge),
        "zonal_delivery_charge": Number(this.state.zonalCharge),
        "national_delivery_charge": Number(this.state.nationalCharge)
      }
    });
    this.postShipmentApiCallId = this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: this.state.postApiHit ? configJSON.exampleAPiMethod : configJSON.exampleAPiMethodPut,
      endPoint: configJSON.shipmentApiendPoint,
      body: raw
    });
  };

  apiCall = (data: APIPayloadType) => {
    const token = localStorage.getItem("singupLogin");
    let { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token : token ? token : ""
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    body &&
      requestMessage.addData(

        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  selectResponse = () => {
    this.getShipmentDetailApiCallId = this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.shipmentDetailApiEndPoint
    });
  };


  backButtonResponse = async() => {
    const catalogueID =  await getStorageData("catalogueID");
    this.getShipmentBackButtonDataApiCallId = this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.shipmentBackButtonDataApiEndPoint+catalogueID}`
    });
  };

  responseSuccessCall = async (apiRequestCallId: string, responseJson: ShipmentDataResponse & ShipmentInventry & ShipmentBackButtonData) => {
    if (apiRequestCallId === this.postShipmentApiCallId) {
      this.postInventorySuccessCall();
    }
    if (apiRequestCallId === this.getShipmentDetailApiCallId) {
      this.getShipmentDetailSuccessCall(responseJson);
    }
    if (apiRequestCallId === this.getShipmentBackButtonDataApiCallId) {
      this.getShipmentBackButtonSuccessCall(responseJson);
    }
  };

  responseFailureCall = async (apiRequestCallId: string, responseJson: ShipmentDataResponse) => {
    if (apiRequestCallId === this.postShipmentApiCallId) {
      this.postInventoryFailureCall();
    }
  };

  postInventorySuccessCall = () => {
    this.props.handleActiveStep(3)
  };

  postInventoryFailureCall = () => {
    alert("Api Failed")
  }

  getShipmentDetailSuccessCall = (responseJson: ShipmentInventry) => {
    this.setState({
      shipmentInvenrtyResponse: responseJson.inventory_details.data.attributes
    })
  };

  getShipmentBackButtonSuccessCall = (responseJson: ShipmentBackButtonData) => {
    if (responseJson.inventory=== null) {
      this.setState({
        postApiHit: true
      })
    } else {
      this.setState({
        fulfilmentBy: responseJson.inventory.fullfilment_by,
        procurementType: responseJson.inventory.procurement_type,
        procurementSLA: responseJson.inventory.procurement_sla,
        stockQuantity: String(responseJson.inventory.stock_quantity),
        localCharge: responseJson.shipment_charge.local_delivery_charge,
        zonalCharge: responseJson.shipment_charge.zonal_delivery_charge,
        nationalCharge: responseJson.shipment_charge.national_delivery_charge,
        postApiHit: false
      })
    }
  };
  // Customizable Area End
}