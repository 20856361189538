import { getStorageData } from "framework/src/Utilities";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
const navigation = require('react-navigation');
import { conditionalRenderer } from "../../../components/src/utils";

interface ApiResponseDataPayload{
    contentType?: string;
    body?: object | string;
    endPoint: string;
    type?: string;
    method: string;
}

interface ImageOrVideo {
    filename: string;
    url: string;
    type: string;
    id: number;
}

interface CreatorDetails {
    full_name: string;
    id: number;
    profile_url: string | null;
}

interface PostAttributes {
    name: string | null;
    id: number;
    body: string;
    description: string | null;
    account_id: number;
    location: string | null;
    active_message: boolean;
    created_at: string;
    updated_at: string;
    images_and_videos: ImageOrVideo[];
    like_count: number;
    creator_details: CreatorDetails;
    comment_count: number;
    is_liked: boolean;
    last_liked_by: null | string;
    is_following_seller: boolean;
}

export interface PostDataPayload {
    type: string;
    id: string;
    attributes: PostAttributes;
}

interface PostShareDataApiResponse {
    data: PostDataPayload;
}


interface CommentDetails {
    comment: string;
    profile_photo: string;
    like_count: number;
    sub_post_comments: CommentDetails[];
    self_like: boolean;
    replies_count: number;
    full_name: string | null;
    created_at: string;
    sub_comment_id: string;
}
interface Comment {
    type: string;
    id: string;
    attributes: CommentAttributes;
}

interface Meta {
    message: string;
}
interface CommentAttributes {
    details: CommentDetails;
}

interface CommentsResponse {
    data: Comment[];
    meta: Meta;
}


interface LikeDataAttributes {
    likeable_type: string;
    likeable_id: number;
    created_at: string;
    updated_at: string;
    like_by_id: number;
}

interface LikeData {
    type: string;
    attributes: LikeDataAttributes;
    id: string;
}

interface LikeResponse {
    message: string;
    data: LikeData;
}

interface SubPostCommentAttributes {
    account_id: number;
    id: number;
    comment: Comment;
    comment_text: string;
    updated_at: string;
    created_at: string;
}

interface SubPostCommentData {
    type: string;
    attributes: SubPostCommentAttributes;
    id: string;
}

interface SubPostCommentResponse {
    data: SubPostCommentData;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    id: string;
    navigation: typeof navigation;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isShareModal: boolean;
    shareUrl: string;
    sharePostId: string;
    isAppLoader: boolean;
    currentPostCommentId: string;
    isCommentBox: string;
    sharedPostData: PostDataPayload | null;
    currentPostId: string;
    isSideBarOpen: true;
    openClickReply: number[];
    listCmtData: Comment[];
    commentValue: string;
    setCommentReplyId: string;
    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class PostShareViewController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    postLikeUnlikeApiCallID : string = "";
    getSharePostDataApiCallID: string = "";
    postReCommentApiCallID: string = "";
    getPostCommentDataApiCallID: string = "";
    postCommentOnPostApiCallID: string = "";
    likeOnCommentAPiCallID: string = "";
    likeOnReplyCommentApiCallID: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.NavigationPropsMessage),
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.NavigationTargetMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isShareModal: false,
            shareUrl: '',
            sharePostId: '',
            isAppLoader: false,
            currentPostCommentId: '',
            isCommentBox: '',
            sharedPostData: null,
            currentPostId: '',
            isSideBarOpen: true,
            openClickReply: [],
            listCmtData: [],
            commentValue: "",
            setCommentReplyId: "",
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let apiResponseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            )
            if (apiResponseJson.status === 500) {
                this.showAlert("Error", "Internal Server Error!!");
                this.setState({ isAppLoader: false });
                return;
            }
            
            if (apiResponseJson && !apiResponseJson.errors) {
                switch (apiRequestId) {
                    case this.getPostCommentDataApiCallID:
                        this.getCommentListingSuccessCallBack(apiResponseJson);
                        break;
                    case this.postCommentOnPostApiCallID:
                        this.getCommentAddSuccessCallBack(apiResponseJson);
                        break;
                    case this.postReCommentApiCallID:
                        this.getReCommentSuccessCallBack(apiResponseJson);
                        break;
                    case this.likeOnCommentAPiCallID:
                        this.likeCommentCallback(apiResponseJson);
                        break;
                    case this.likeOnReplyCommentApiCallID:
                        this.likeReplyCommentCallback(apiResponseJson);
                        break;
                    case this.getSharePostDataApiCallID:
                        this.getSharedPostDataSuccessCallBack(apiResponseJson);
                        break;
                    default:
                        break;
                }
            }

        }
        // Customizable Area End
    }


    // Customizable Area Start
    async componentDidMount() {
        const array = window.location.pathname.split("/");
        const postId = array[array.length - 1];
        this.getSharePostData(postId);
    };

    apiCall = async (apiReqData: ApiResponseDataPayload) => {
        const { contentType, method, endPoint, body, type } = apiReqData;
        let sellerToken = await getStorageData("singupLogin");
        let buyerToken = await getStorageData("buerLoginToken");
        let authToken;
        if (buyerToken) {
            authToken = buyerToken;
        } else if (sellerToken) {
            authToken = sellerToken;
        }
        const header = {
            "Content-Type": contentType,
            token: authToken,
        };
        const postViewRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        postViewRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        postViewRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
        postViewRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
        body && type != 'formData' ?
            postViewRequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body))
            : postViewRequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
        runEngine.sendMessage(postViewRequestMessage.id, postViewRequestMessage);
        return postViewRequestMessage.messageId;
    };

    handlePostShareToggle = (postId: string) => {
        this.setState({ isShareModal: true, sharePostId: postId }, () => {
            this.setState({ shareUrl: `/Post/Share/${this.state.sharePostId}` })
        });
    };

    handleToggleComment = (postId: string, commentbox: string) => {
        this.getAllPostCommentApiCall(postId);
        this.setState({ currentPostCommentId: postId, isCommentBox: commentbox })
    };

    handleToggleLikePost = (postId: string) => {
        if (this.state.sharedPostData) {
            const updatedPostData = this.state.sharedPostData;
            const isLiked = updatedPostData.attributes.is_liked;
            const likeCnt = updatedPostData.attributes.like_count;
            if (isLiked === true) {
                updatedPostData.attributes.is_liked = false;
                if (likeCnt > 0) {
                    updatedPostData.attributes.like_count = likeCnt - 1;
                }
                this.setState({ sharedPostData: updatedPostData }, () => {
                    this.postApiCallFn(postId);
                });
            } else {
                updatedPostData.attributes.is_liked = true;
                updatedPostData.attributes.like_count = likeCnt + 1;
                this.setState({ sharedPostData: updatedPostData }, () => {
                    this.postApiCallFn(postId);
                });
            }
        }
    };

    postApiCallFn = async (postId: string) => {
        this.postLikeUnlikeApiCallID = await this.apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.PostAPiMethod,
            endPoint: configJSON.postLikeUnlikeEndPoint,
            body: {
                "data": {
                    "attributes": {
                        "likeable_id": Number(postId),
                        "likeable_type": "BxBlockPosts::Post"
                    }
                }
            },
            type: ''
        })
    };

    getSharePostData = async (postId: string) => {
        this.getSharePostDataApiCallID = await this.apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.getPostListingDataEndPoint + `/${postId}`,
            body: '',
            type: ''
        })
    };

    getSharedPostDataSuccessCallBack = (responseJSON: PostShareDataApiResponse) => {
        this.setState({ sharedPostData: responseJSON.data })
    };

    handleCommentInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ commentValue: event.target.value })
    };

    postCommentApiCallFn = async () => {
        this.postCommentOnPostApiCallID = await this.apiCall({
            contentType: configJSON.validationApiContentType,
            endPoint: configJSON.postCommentEndPoint,
            method: configJSON.PostAPiMethod,
            body: {
                "comment": {
                    "comment": this.state.commentValue,
                    "post_id": this.state.currentPostCommentId
                }
            },
            type: ''
        })
    };

    getAllPostCommentApiCall = async (postId: string) => {
        this.getPostCommentDataApiCallID = await this.apiCall({
            endPoint: `${configJSON.getPostCommentListEndPoint}?post_id=${postId}`,
            method: configJSON.validationApiMethodType,
            type: '',
            contentType: configJSON.validationApiContentType,
        })
    };

    postReCommentApiCall = async () => {
        this.postReCommentApiCallID = await this.apiCall({
            method: configJSON.PostAPiMethod,
            contentType: configJSON.validationApiContentType,
            endPoint: configJSON.postCommentOnCommentEndPoint,
            body: {
                "comment": {
                    "comment_id": this.state.setCommentReplyId,
                    "post_id": this.state.currentPostCommentId,
                    "comment": this.state.commentValue,
                }
            },
            type: ''
        })
    };

    getCommentAddSuccessCallBack = (responseJson: CommentsResponse) => {
        if (responseJson && responseJson.data) {
            this.getAllPostCommentApiCall(this.state.currentPostCommentId);
            this.setState({ commentValue: "" });
        }
    };

    getCommentListingSuccessCallBack = (responseJson: CommentsResponse) => {
        if (responseJson && responseJson.data) {
            this.setState({ listCmtData: responseJson.data })
        }
    };

    getReCommentSuccessCallBack = (responseJson: SubPostCommentResponse) => {
        if (responseJson && responseJson.data) {
            this.getAllPostCommentApiCall(this.state.currentPostCommentId);
            this.setState({ commentValue: "", setCommentReplyId: "" });
        }
    };

    viewExpandableDetails = (expandableID: number) => {
        const expandSet = new Set(this.state.openClickReply);
        if (!expandSet.has(expandableID)) {
            expandSet.add(expandableID);
        } else {
            expandSet.delete(expandableID);
        }
        this.setState({ openClickReply: Array.from(expandSet) });
    };

    clickOnReplyForGetId = (postId: string) => {
        this.setState({ setCommentReplyId: postId });
    };

    likeCommentApiCall = async (postId: string) => {
        this.likeOnCommentAPiCallID = await this.apiCall({
            contentType: configJSON.validationApiContentType,
            endPoint: configJSON.likeCommentEndPoint,
            method: configJSON.PostAPiMethod,
            body: {
                "data": {
                    "attributes": {
                        "likeable_type": "BxBlockComments::Comment",
                        "likeable_id": postId,
                    }
                }
            },
            type: ''
        })
    };

    likeReplyCommentApiCall = async (postId: string) => {
        this.likeOnReplyCommentApiCallID = await this.apiCall({
            endPoint: configJSON.likeCommentEndPoint,
            contentType: configJSON.validationApiContentType,
            method: configJSON.PostAPiMethod,
            body: {
                "data": {
                    "attributes": {
                        "likeable_type": "BxBlockComments::SubPostComment",
                        "likeable_id": postId,
                    }
                }
            },
            type: ''
        })
    };

    likeReplyCommentCallback = (responseJson: LikeResponse) => {
        if (responseJson) {
            this.getAllPostCommentApiCall(this.state.currentPostCommentId);
        }
    };

    likeCommentCallback = (responseJson: LikeResponse) => {
        if (responseJson) {
            this.getAllPostCommentApiCall(this.state.currentPostCommentId);
        }
    };

    customStyle = (value: { like_count: number }) => {
        return {
            cursor: 'pointer',
            color: conditionalRenderer(value?.like_count > 0, 'blue', '#44444480'),
        }
    };
    // Customizable Area End
}