import { getStorageData } from "framework/src/Utilities";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { createRef } from "react";

// Customizable Area Start
import SwiperCore, { Navigation } from 'swiper';
const navigation = require('react-navigation');
const frameWorkBaseUrl = require("../../../framework/src/config.js").baseURL;
interface ApiPayloadType{
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object | string;
    type?: string;
}
interface ImageOrVideo {
    id: number;
    filename: string;
    url: string;
    type: string;
}

interface CreatorDetails {
    id: number;
    full_name: string;
    profile_url: string | null;
}

interface PostAttributes {
    id: number;
    name: string | null;
    description: string | null;
    body: string;
    location: string | null;
    account_id: number;
    active_message: boolean;
    created_at: string;
    updated_at: string;
    images_and_videos: ImageOrVideo[];
    creator_details: CreatorDetails;
    like_count: number;
    is_liked: boolean;
    comment_count: number;
    is_following_seller: boolean;
    last_liked_by: null | string
}

export interface PostDataPayload {
    id: string;
    type: string;
    attributes: PostAttributes;
}
interface PostApiData {
    data: PostDataPayload[]
}

interface CommentDetails {
    profile_photo: string;
    full_name: string | null;
    comment: string;
    like_count: number;
    replies_count: number;
    self_like: boolean;
    created_at: string;
    sub_comment_id: string;
    sub_post_comments: CommentDetails[];  // Assuming sub_post_comments follow the same structure
  }
  
  interface CommentAttributes {
    details: CommentDetails;
  }
  
  interface Comment {
    id: string;
    type: string;
    attributes: CommentAttributes;
  }
  
  interface Meta {
    message: string;
  }
  
  interface CommentsResponse {
    data: Comment[];
    meta: Meta;
  }

  interface LikeDataAttributes {
    likeable_id: number;
    likeable_type: string;
    like_by_id: number;
    created_at: string;
    updated_at: string;
  }
  
  interface LikeData {
    id: string;
    type: string;
    attributes: LikeDataAttributes;
  }
  
  interface LikeResponse {
    data: LikeData;
    message: string;
  }

  interface Comment {
    id: string;
    account_id: number;
    commentable_id: number;
    commentable_type: string;
    comment: string;
    created_at: string;
    updated_at: string;
  }
  
  interface SubPostCommentAttributes {
    id: number;
    account_id: number;
    comment_text: string;
    comment: Comment;
    created_at: string;
    updated_at: string;
  }
  
  interface SubPostCommentData {
    id: string;
    type: string;
    attributes: SubPostCommentAttributes;
  }
  
  interface SubPostCommentResponse {
    data: SubPostCommentData;
  }
  

  export interface StoryUploaders {
    type: string;
    id: string;
    attributes: StoryAttributes;
  }
  
  interface StoryAttributes {
    title: string;
    id: number;
    duration: number; 
    description: string;
    location: string | null; 
    seen: boolean;
    activated: boolean;
    color: string | null; 
    updated_at: string; 
    created_at: string; 
    media: StoryMedia;
    creator_details: CreatorDetails;
    liked: boolean;
    duration_with_seconds: string;
  }
  
  interface CreatorDetails {
    full_name: string;
    id: number;
    profile_url: string | null;
  }
  
  interface StoryMedia {
    filename: string;
    id: number;
    type: string;
    url: string;
  }
  
  interface AllStoryResponse {
    data: Array<StoryUploaders>
  }
  
  SwiperCore.use([Navigation]);

  interface FollowResponse {
    data: {
      id: string;
      type: string;
      attributes: {
        id: number;
        account_id: number;
        followable_id: number;
        created_at: string;
        updated_at: string;
        account: {
          id: number;
          first_name: string | null;
          full_phone_number: string;
          country_code: number;
          phone_number: number;
          email: string;
          activated: boolean;
          device_id: string;
          unique_auth_id: string;
          password_digest: string;
          created_at: string;
          updated_at: string;
          user_name: string;
          platform: string | null;
          user_type: string | null;
          app_language_id: number | null;
          last_visit_at: string | null;
          is_blacklisted: boolean;
          suspend_until: string | null;
          status: string;
          stripe_id: string | null;
          stripe_subscription_id: string | null;
          stripe_subscription_date: string | null;
          role_id: number;
          full_name: string;
          email_verified: boolean;
          phone_verified: boolean;
          business_name: string;
          business_type: string;
          admin_verified: boolean;
          updated_mobile_number: string | null;
          is_disabled: boolean;
          my_bio: string | null;
          updated_email: string | null;
          business_popup: boolean;
          password_digests: string[];
          shipment_type: string;
          same_drop_off: boolean;
          marked_destroy: boolean;
          upi_id: string | null;
          qr_generated: boolean;
          latitude: number | null;
          longitude: number | null;
          current_city: string | null;
          store_type: string;
          can_livestream: boolean | null;
          instagram_stream_url: string | null;
          facebook_stream_url: string | null;
          youtube_stream_url: string | null;
        };
      };
    };
    meta: {
      message: string;
    };
  }
  
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: typeof navigation;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isLoading: boolean;
    categoriesData : Array<string>;
    showComingSoon: boolean;
    currentIndexMap: { [key: number]: number };
    currentProductIndex: number;
    buyerPostData: PostDataPayload[];
    buyerPostLoader: boolean;
    isNotification_Checked: boolean;
    isAllRead: boolean;
    feedPageNo: number;
    isFeedLoader: boolean;
    islatestPost: boolean;
    hasMorePosts: boolean;
    tokenPresentOrNot: string | null;
    clickReply: number[];
    showCommentBox: string;
    addComment: string;
    setPostCommentId: string;
    listCommentData: Comment[];
    setReplyId: string;
    instaModalIndex: number;
    instaModal: boolean;
    userStory : Array<StoryUploaders>;
    allStoryHeader: Array<StoryUploaders>;
    storyMuted: boolean;
    goToSlide: string;
    currentSlide: number;
    currentStoryIndex: number;
    storyPaused: boolean;
    showRightStories: boolean;
    showLeftStories: boolean;
    isPostData: boolean;
    isShareModal : boolean;
    shareUrl: string;
    sharePostId : string;
    toastMessage: string;
    isToastOpen: boolean;
    severity: 'info' | 'error' | 'success' | 'warning';
    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class BuyerFeedController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getPostDataApiCallId : string = "";
    postContainerRef: React.RefObject<HTMLDivElement> = createRef();
    storyBoxRef : React.RefObject<HTMLDivElement> = createRef();
    postLikeUnlikeApiCallID: string = "";
    postCommentApiCallID: string = "";
    getPostCommentApiCallID: string = "";
    postCommentOnCommentApiCallID: string = "";
    likeCommentApiCallID: string = "";
    likeReplyCommentApiCallID: string = "";
    likeStoryCallId:string = "";
    allUserStoriesCallId: string = "";
    viewStoryCallId: string = "";
    swiperInstance: SwiperCore | null = null;
    followSellerApiCallID: string = "";
    getFollowSellerApiCallID: string = "";
    unFollowSellerApiCallID: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.NavigationTargetMessage),
            getName(MessageEnum.NavigationPropsMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isLoading: false,
            categoriesData: ['Furniture & Interior ', 'Antiques ', 'Sports', 'Toys', 'Beauty & Make up', 'Sneakers '],
            buyerPostLoader: false,
            currentProductIndex: 0,
            isNotification_Checked: false,
            isAllRead: false,
            feedPageNo: 1,
            isFeedLoader: false,
            buyerPostData: [],
            showComingSoon: false,
            currentIndexMap: {},
            islatestPost: false,
            hasMorePosts: true,
            tokenPresentOrNot: null,
            clickReply: [],
            showCommentBox: "",
            addComment: "",
            setPostCommentId: "",
            listCommentData: [],
            setReplyId: "",
            allStoryHeader: [],
            instaModal: false,
            goToSlide: "",
            userStory : [],
            storyPaused: false,
            storyMuted: false,
            currentSlide: 0,
            currentStoryIndex: 0,
            showRightStories: false,
            showLeftStories: false,
            instaModalIndex: 0,
            isPostData : true,
            isShareModal: false,
            shareUrl: '',
            sharePostId: '',
            toastMessage: '',
            isToastOpen: false,
            severity: 'success'
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let apiResponseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            )
            if (apiResponseJson.status === 500) {
                this.showAlert("Error", "Internal Server Error!!");
                this.setState({ isLoading: false });
                return;
            }
            if (apiResponseJson && !apiResponseJson.errors) {
              if(this.getPostDataApiCallId === apiRequestId){
                this.postListingDataSuccessCallback(apiResponseJson);
              }
            }

            if (apiRequestId && apiResponseJson) {
                switch (apiRequestId) {
                    case this.getPostCommentApiCallID:
                        this.getListingDataSuccessCallback(apiResponseJson);
                        break;
                    case this.postCommentApiCallID:
                        this.getAddDataSuccessCallback(apiResponseJson);
                        break;
                    case this.postCommentOnCommentApiCallID:
                        this.postCommentOnCommentDataSuccessCallback(apiResponseJson);
                        break;
                    case this.likeCommentApiCallID:
                        this.likeCommentCallback(apiResponseJson);
                        break;
                    case this.likeReplyCommentApiCallID:
                        this.likeReplyCommentCallback(apiResponseJson);
                        break;
                    // case this.getFollowSellerApiCallID:
                    //     this.getFollowSellerCallback(apiResponseJson);
                    //     break;
                    default:
                        break;
                }
            }
            this.handleOtherApis(message);

        }
        // Customizable Area End
    }


    // Customizable Area Start
    async componentDidMount() {
        let buyerToken = await getStorageData("buerLoginToken");
        this.setState({ tokenPresentOrNot: buyerToken },()=>
        {
        this.fetchAllUserStories();
        });
        this.checkTokenPresentOrNot();
        this.getPostListingData();
        // this.getFollowSellerApiCall()
        if (this.postContainerRef.current) {
            this.postContainerRef.current.addEventListener('scroll', this.handleScroll);
        }
    };

    checkTokenPresentOrNot = () => {
        if (this.state.tokenPresentOrNot === null) {
            const message = new Message(getName(MessageEnum.NavigationMessage));
            message.addData(getName(MessageEnum.NavigationTargetMessage), "GetStartedLogin");
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message)
        }
    };

    handleScroll = () =>{
        if (this.postContainerRef.current) {
            const container = this.postContainerRef.current;
            if ( container.scrollTop + container.clientHeight >= container.scrollHeight -10  &&  !this.state.isLoading && this.state.hasMorePosts && this.state.isPostData ) {
                this.setState({ isPostData: false })
                this.getPostListingData(true); 
            }
        }
    };

    getPostListingData = async (isFromPagination = false) => {
        if (isFromPagination) {
            this.setState({ isFeedLoader: true});
        }

        this.getPostDataApiCallId = await this.apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.getPostListingDataEndPoint + `?page=${this.state.feedPageNo}&per_page=5`,
            body: '',
            type: ''
        });
    };

    apiCall = async (apiReqData: ApiPayloadType) => {
        const { contentType, method, endPoint, body, type } = apiReqData;
        let token = await getStorageData("buerLoginToken");
        const header = {
            "Content-Type": contentType,
            token: token,
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        body && type != 'formData' ?
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )
            : requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    postListingDataSuccessCallback = (responseJson: PostApiData) => {
        if (responseJson && responseJson.data) {
            if(responseJson.data.length === 0){
                this.setState({hasMorePosts: false});
            } else{
                this.setState((prevState)=> ({
                    buyerPostData: [ ...prevState.buyerPostData, ...responseJson.data ], 
                    buyerPostLoader: false,
                    isFeedLoader: false,
                    islatestPost: false,
                    feedPageNo: prevState.feedPageNo + 1,
                    isPostData: true,
                  }));
            }
          }
    };

    handleToggleLikePost = (postId: string) => {
        const newData = [...this.state.buyerPostData];
        const postIndex = newData.findIndex(post => post.id === postId);

        if (postIndex !== -1) {
            const isLikedOrNot = newData[postIndex].attributes.is_liked;
            const likeCount = newData[postIndex].attributes.like_count;
            if (isLikedOrNot === true) {
                newData[postIndex].attributes.is_liked = false;
                if (likeCount > 0) {
                    newData[postIndex].attributes.like_count = likeCount - 1;
                }
                this.setState({ buyerPostData: newData }, () =>{
                    this.postApiCallFn(postId);
                });
            } else {
                newData[postIndex].attributes.is_liked = true;
                newData[postIndex].attributes.like_count = likeCount + 1;
                this.setState({ buyerPostData: newData }, () => {
                    this.postApiCallFn(postId);
                });
            }
        }
    };

    postApiCallFn = async (postId: string) => {
        this.postLikeUnlikeApiCallID = await this.apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.PostAPiMethod,
            endPoint: configJSON.postLikeUnlikeEndPoint,
            body: {
                "data": {
                    "attributes": {
                        "likeable_id": Number(postId),
                        "likeable_type": "BxBlockPosts::Post"
                    }
                }
            },
            type: ''
        })
    };

    clickOnReply = () => {
        // this.setState({ clickReply : !this.state.clickReply })
    }

    handleToggleComment = (postId:string,commentbox:string) => {
        this.setState({ setPostCommentId: postId, showCommentBox : commentbox })
        this.getPostCommentApiCall(postId)
    }

    postCommentApiCall = async () => {
        this.postCommentApiCallID = await this.apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.PostAPiMethod,
            endPoint: configJSON.postCommentEndPoint,
            body: {
                "comment": {
                    "post_id": this.state.setPostCommentId,
                    "comment": this.state.addComment
                }
            },
            type: ''
        })
    };

    handleInputChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ addComment: event.target.value}) 
    }

    getPostCommentApiCall = async (postId:string) => {
        this.getPostCommentApiCallID = await this.apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint: `${configJSON.getPostCommentListEndPoint}?post_id=${postId}`,
            type: '',
        })
    };

    postCommentOnCommentApiCall = async () => {
        this.postCommentOnCommentApiCallID = await this.apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.PostAPiMethod,
            endPoint: configJSON.postCommentOnCommentEndPoint,
            body: {
                "comment": {
                    "post_id": this.state.setPostCommentId,
                    "comment_id": this.state.setReplyId,
                    "comment": this.state.addComment
                }
            },
            type: ''
        })
    };

    getListingDataSuccessCallback = (responseJson: CommentsResponse) => {
        if (responseJson && responseJson.data) {
            this.setState({ listCommentData: responseJson.data })
          }
    };

    getAddDataSuccessCallback = (responseJson: CommentsResponse) => {
        if (responseJson && responseJson.data) {
            this.setState({ addComment: "" });
            this.getPostCommentApiCall(this.state.setPostCommentId);
          }
    };

    postCommentOnCommentDataSuccessCallback = (responseJson:SubPostCommentResponse) => {
        if (responseJson && responseJson.data) {
            this.setState({ addComment: "", setReplyId : "" });
            this.getPostCommentApiCall(this.state.setPostCommentId);
        }
    }

    clickOnReplyForGetId = (id:string) => {
        this.setState({ setReplyId: id });
    }

    viewDetilsExpandable = (viewDetilsExpandableId: number) => {
        const viewDetailsExpandSet = new Set(this.state.clickReply);
        if (!viewDetailsExpandSet.has(viewDetilsExpandableId)) {
          viewDetailsExpandSet.add(viewDetilsExpandableId);
        } else {
          viewDetailsExpandSet.delete(viewDetilsExpandableId);
        }
        this.setState({ clickReply: Array.from(viewDetailsExpandSet) });
    }

    likeCommentApiCall = async (id:string) => {
        this.likeCommentApiCallID = await this.apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.PostAPiMethod,
            endPoint: configJSON.likeCommentEndPoint,
            body: {
                  "data": {
                    "attributes": {
                      "likeable_id": id, //enter comment id
                      "likeable_type": "BxBlockComments::Comment"
                    }
                  }
                },
            type: ''
        })
    };

    likeCommentCallback = (responseJson:LikeResponse) => {
        if (responseJson) {
            console.log(responseJson,'check json data');
            
            this.getPostCommentApiCall(this.state.setPostCommentId);
        }
    }

    likeReplyCommentApiCall = async (id:string) => {
        this.likeReplyCommentApiCallID = await this.apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.PostAPiMethod,
            endPoint: configJSON.likeCommentEndPoint,
            body: {
                  "data": {
                    "attributes": {
                      "likeable_id": id, //enter comment id
                      "likeable_type": "BxBlockComments::SubPostComment"
                    }
                  }
                },
            type: ''
        })
    };

    likeReplyCommentCallback = (responseJson:LikeResponse) => {
        if (responseJson) {            
            this.getPostCommentApiCall(this.state.setPostCommentId);
        }
    }
    
    handleShareModalToogle = () => {
        this.setState({ isShareModal: !this.state.isShareModal });
    };


  viewStory = async (index: number) => {
    this.viewStoryCallId = await this.apiCall(
      {
        contentType: configJSON.validationApiContentType,
        method: configJSON.validationApiMethodType,
        endPoint: configJSON.viewStoryEndPoint.replace("{userId}", this.state.allStoryHeader[index].attributes.creator_details.id),
        type: ""
      }
    );
  };

  handleClickOpen = (index: number) => {
    this.viewStory(index);
    this.setState({ instaModalIndex: index, currentSlide: index })
  };

  handleClose = () => {
    this.setState({ instaModal: false })
  };

  handleNext = (userIndex: number, direct?: boolean, event?: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => {
    event?.stopPropagation();
    let { allStoryHeader } = this.state;
    if (this.state.currentStoryIndex + 1 < this.state.userStory.length && !direct) {
      this.changeStory("next");
    } else {
      if (this.swiperInstance) {
        allStoryHeader[this.state.currentSlide].attributes.seen = true;
        this.setState({
          allStoryHeader
        }, () => {
          this.viewStory(userIndex + 1);
          this.setState({ goToSlide: "next", currentSlide: userIndex + 1 });
        })
      }
    }
  };

  setSwiperRef = (swiper: SwiperCore) => {
    this.swiperInstance = swiper;
  };

  handlePrevNext = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, userIndex: number) => {
    const element = event.currentTarget;
    const parent = element.parentElement
    const classNames = parent?.classList;
    if (classNames?.contains('swiper-slide-prev')) {
      this.handlePrev(userIndex + 1, true, event);
    } else if (classNames?.contains('swiper-slide-next')) {
      this.handleNext(userIndex - 1, true, event);
    }
  };

  handlePrev = (userIndex: number, direct?: boolean, event?: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => {
    event?.stopPropagation();
    if (this.state.currentStoryIndex - 1 >= 0 && !direct) {
      this.changeStory("prev");
    } else {
      if (this.swiperInstance) {
        this.viewStory(userIndex - 1);
        this.setState({ goToSlide: "prev", currentSlide: userIndex - 1 });
      }
    }
  };

  fetchAllUserStories = async () => {
    this.allUserStoriesCallId = await this.apiCall({
      method: configJSON.validationApiMethodType,
      contentType: configJSON.validationApiContentType,
      endPoint: configJSON.getAllStoriesEndPoint,
      type: ""
    });
  };

  allUserStoriesSuccessCallBack = (response: AllStoryResponse) => {
    if (response.data) {
      this.setState({
        allStoryHeader: response.data
      },()=>{
        if (this.state.allStoryHeader.length){
          this.setState({showRightStories: true});
          this.updateScrollButtons();
          this.storyBoxRef.current?.addEventListener('scroll', this.handleStoryBoxScroll);
        }
      })
    }
  };

  viewStorySuccessCallBack = (response: AllStoryResponse) => {
    let { goToSlide } = this.state;
    this.setState({ instaModal: true, userStory : response.data, currentStoryIndex: 0 }, () => {
      if (goToSlide === "next") {
        this.swiperInstance?.slideNext();
      } else if (goToSlide === "prev") {
        this.swiperInstance?.slidePrev();
      }
      this.setState({ goToSlide: "" });
    });
  };

  calculateHours = (createdAt: string) => {
    const createdTime = new Date(createdAt).getTime();
    const currentTime = Date.now();
    const timeDifference = currentTime - createdTime;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    if (hours > 0) {
      return `${hours}h`;
    } else if (minutes > 0) {
      return `${minutes}m`;
    } else {
      return `${seconds}s`;
    }
  };

  toggleMute = () => {
    this.setState({ storyMuted: !this.state.storyMuted });
  };

  likeStory = async (storyId: number) => {
    this.likeStoryCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.PostAPiMethod,
      endPoint: configJSON.postLikeUnlikeEndPoint,
      body: {
        "data": {
          "attributes": {
            "likeable_id": storyId,
            "likeable_type": "BxBlockPosts::Story"
          }
        }
      },
      type: ""
    })
  };

  likeStorySuccessCallBack = (response: LikeResponse) => {
    let { userStory } = this.state;
    const oldStory = userStory;
    if (response.data) {
      oldStory.forEach((item, index) => {
        if (item.attributes.id === response.data.attributes.likeable_id) {
          userStory[index].attributes.liked = !item.attributes.liked;
        }
      })
    };
    if (response.message) {
      userStory[this.state.currentStoryIndex].attributes.liked = !userStory[this.state.currentStoryIndex].attributes.liked;
    }
    this.setState({ userStory });
  };

  showNextButton = (userIndex: number) => {
    const { allStoryHeader, userStory, currentStoryIndex } = this.state;
    if (userIndex + 1 < allStoryHeader.length || (userIndex + 1 === allStoryHeader.length && currentStoryIndex + 1 < userStory.length)) {
      return true;
    }
    return false
  };

  showPrevButton = (userIndex: number) => {
    const { currentStoryIndex } = this.state;
    if (userIndex > 0 || (userIndex === 0 && currentStoryIndex !== 0)) {
      return true;
    }
    return false
  };

  changeStory = (direction: "next" | "prev") => {
    let { currentStoryIndex, userStory, currentSlide, allStoryHeader } = this.state;

    if (userStory.length === 0) return;

    let newIndex = currentStoryIndex;

    if (direction === 'next') {
      newIndex = currentStoryIndex + 1;
    } else if (direction === 'prev') {
      newIndex = currentStoryIndex - 1;
    }
    if (newIndex < 0) {
      this.handlePrev(currentSlide, true);
      newIndex = 0;
    } else if (newIndex === userStory.length) {
      if (currentSlide + 1 < allStoryHeader.length) {
        this.handleNext(currentSlide, true);
      } else {
        allStoryHeader[currentSlide].attributes.seen = true;
        this.handleClose();
      }
      newIndex = 0;
    }

    this.setState({ currentStoryIndex: newIndex, allStoryHeader });
  };

  handleOtherApis = (message: Message) => {
      const apiRequestId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJSON = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
      if (apiRequestId === this.allUserStoriesCallId) {
        this.allUserStoriesSuccessCallBack(responseJSON)
      };

      if (apiRequestId === this.likeStoryCallId) {
        this.likeStorySuccessCallBack(responseJSON)
      };

      if (apiRequestId === this.viewStoryCallId) {
        this.viewStorySuccessCallBack(responseJSON)
      };
  };

  handleCreatorFollow = async (id: number, postId: string) => {
    // Create a copy of the state
    const newData = [...this.state.buyerPostData];
    
    // Find all posts from the user with the specified `id`
    const updatedData = newData.map(post => {
        if (post.attributes.account_id === id) {
            // Toggle the `is_following_seller` status for all posts of the same user
            return {
                ...post,
                attributes: {
                    ...post.attributes,
                    is_following_seller: !post.attributes.is_following_seller
                }
            };
        }
        return post;
    });

    // Update the state with all affected posts
    this.setState({ buyerPostData: updatedData });

    // Make the API call to follow/unfollow the user
    this.followSellerApiCallID = await this.apiCall({
        contentType: configJSON.validationApiContentType,
        method: configJSON.PostAPiMethod,
        endPoint: configJSON.followSellar,
        body: {
            "data": {
                "attributes": {
                    "followable_id": id
                }
            }
        },
        type: ''
    });
};

  updateScrollButtons = () => {
    const container = this.storyBoxRef.current;
    if (container) {
      const { scrollLeft, scrollWidth, clientWidth } = container;
      const atEnd = scrollLeft + clientWidth >= scrollWidth;
      const atStart = scrollLeft === 0;
      this.setState({ showLeftStories: !atStart, showRightStories: !atEnd });
    };
  };

  scrollStoriesLeft = () => { this.storyBoxRef.current?.scrollBy({ left: -200, behavior: 'smooth' }); };

  handleStoryBoxScroll = () => { this.updateScrollButtons(); };

  scrollStoriesRight = () => { this.storyBoxRef.current?.scrollBy({ left: 200, behavior: 'smooth' }); };

    handlePostShareToggle = (postId: string) => {
        this.setState({ isShareModal: true, sharePostId: postId }, () => {
            this.setState({ shareUrl: `${frameWorkBaseUrl}/visit_post/${this.state.sharePostId}` })
        });
    };

    handleCopyLink = () => {
        const { shareUrl } = this.state;
        navigator.clipboard.writeText(shareUrl)
            .then(() => {
                this.setState({ toastMessage: 'Link Copied Succesfully!', isToastOpen: true, severity: 'info' });
                setTimeout(() => {
                    this.setState({ toastMessage: '', isToastOpen: false });
                }, 6000);
            });
    };

    handleToggleToast = () => {
        this.setState({ isToastOpen: !this.state.isToastOpen })
    };
    // Customizable Area End
}