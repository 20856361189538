import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  Modal,
  Backdrop,
  TextField
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
export const baseConfig = require('../../../framework/src/config')
import { withRouter } from 'react-router-dom';
import ReactImageMagnify from 'react-image-magnify';
import {liveArchive, liveArchiveBlank,crossImage, cancelIcon} from './assets'
import { withStyles } from "@material-ui/core/styles";
const base_url = baseConfig.baseURL;
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import { SizeDataI } from "./ProductDescriptionController";
// Customizable Area End

import ProductDescriptionController, {
  Props,
  configJSON,
} from "./ProductDescriptionController";
import MessageModalWeb from "../../../components/src/MessageModal.web";
import Loader from "../../../components/src/Loader.web";
import StayTuned from "../../../components/src/StayTuned.web";
import { isEmpty } from "framework/src/Utilities";

class ProductDescription extends ProductDescriptionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderVariantSize() {
   return(
    <SizeNameWrapper>
      {
        this.state.selectedVariant?.sizes_data?.map((size:SizeDataI)=> {
           return size.attributes.catalogue_variant_size?.name && <SizeName className={this.state.activeSize.sizeName === size.attributes.catalogue_variant_size.name ? "activeSize" : ""} onClick={()=> this.handleSize(size)} data-test-id="sizeTxtId">{size.attributes.catalogue_variant_size?.name}</SizeName>
         })
      }
    </SizeNameWrapper>
   )
   
  }

  renderVariantColor() {
    return this.state.catalogueVariants.map(
      (variant: any, index: number) => {
        return (
             <Box
              key={index}
              data-test-id='buttonSelectColor'
              onClick={() => this.handleSelectVariant(variant)}
              style={{ cursor: "pointer" }}
              className={this.props.classes.colorButton}
              sx={{
                borderRadius: "50%",
                border: variant[1]?.catalogue_variant_color_id === this.state.selectedVariant?.catalogue_variant_color_id ? "1.27px #000 solid" : "1.27px solid transparent",
                bgcolor: "white",
                padding: "4px",
                width: "47px",
                height: "47px",
              }}
            >
              <Box
                sx={{
                  borderRadius: "50%",
                  border: "1.27px #CCCCCC solid",
                  bgcolor:
                    variant[0],
                  width: "100%",
                  height: "100%",
                }}
              />
            </Box> 
        )
      }
    )
  }

  renderImages () {
    return this.state.selectedVariant?.catalogue_variant_images?.map(
      (imgE: any, index: number) => {
        return (
          <img
          data-test-id="variantImgID"
            key={index}
            style={{
              width: "100px",
              height: "90px",
              borderRadius: "20px",
              cursor: "pointer",
              objectFit: 'cover',
              border:
                imgE.id === this.state.selectedImage?.id &&
                !this.state.isShowingVideo
                  ? "5px #F5EA16 solid"
                  : "none",
            }}
            src={`${base_url}${imgE.url}`}
            onClick={() => this.handleSelectedImage(imgE)}
          />
        );
      }
    )
  }

  renderMakeOfferModal = () => {
    const { classes } = this.props;
    const { isMakeOfferModal, productPrice, } = this.state;

    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isMakeOfferModal}
        onClose={this.handleModalToggle}
        BackdropComponent={Backdrop}
        BackdropProps={{
          classes: {
            root: classes.backdrop,
          }
        }}
        data-test-id="modalTestId"
      >
        <Box className={classes.paper}>
          <Box className={classes.modalHeader}>
            <Box className={classes.modalHeadingTxt}>
            {configJSON.makeOfferTxt}
            </Box>
            <button className={classes.modalCloseBtn} onClick={this.handleModalToggle} data-test-id="cancelBtnTestId">
              <img src={cancelIcon} alt="crossIcon" className={classes.closeIcon} />
            </button>
          </Box>
          <Box className={classes.modalBodyContainer}>
            <Box className={classes.modalBody}>
              <Box className={classes.basePriceBox}>
                <Typography className={classes.basePriceTxt}>{configJSON.basePriceTxt} <span className={classes.priceTxt}>{`₹ ${productPrice}`}</span></Typography>
              </Box>

              <Box className={classes.inputFieldBox}>
                <TextField data-test-id="offerValueTestId" fullWidth type="text" InputProps={{disableUnderline: true}} className={classes.offerInputField} placeholder="₹ 0" onChange={this.handleOfferChange} value={this.state.offerValue} />
                <Typography className={classes.errorText}>{this.state.offerValueError}</Typography>
              </Box>
              <Box className={classes.offerPercentContainer}>
                <Box data-test-id="percentBoxTestId1" className={`${classes.offerPercentBox} ${this.state.percentageStep === 1 && classes.activeBox}`} onClick={()=> this.handleOfferApply(1,5)}>
                    {configJSON.percent1}
                </Box>
                <Box data-test-id="percentBoxTestId2" className={`${classes.offerPercentBox} ${this.state.percentageStep === 2 && classes.activeBox}`} onClick={()=> this.handleOfferApply(2,10)}>
                    {configJSON.percent2}
                </Box>
                <Box data-test-id="percentBoxTestId3" className={`${classes.offerPercentBox} ${this.state.percentageStep === 3 && classes.activeBox}`} onClick={()=> this.handleOfferApply(3,15)}>
                    {configJSON.percent3}
                </Box>
              </Box>
              <Box className={classes.makeOfferModalBtnBox}>
                <Button className={classes.makeOfferModalBtn} onClick={this.handleMakeOffer} data-test-id="handleMakeOfferTestId">{configJSON.makeOfferTxt}</Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    )
  };

  renderFollowUnfollowBtn = (productDescriptionDetail:any) => {
    return(
    !productDescriptionDetail.account.is_following_seller ?
      <Button className="custom-button primary-yellow large" data-test-id="buyerOpenStayTuned" onClick={() => { this.handleCreatorFollow(productDescriptionDetail.account.id) }}>Follow</Button> :
      <Button className="custom-button primary-yellow large" data-test-id="buyerCloseStayTuned" onClick={() => { this.handleCreatorFollow(productDescriptionDetail.account.id) }}>Following</Button>)
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { history, classes } = this.props;
    const { wishListProduct } = this.state
        if (this.state.productDescription === null) {
      return <Box sx={{ width: "100%", height: "100%", bgcolor: "white" }} />;
    }
    let productDescriptionDetail = this.state.productDescription.attributes;
    let favProductId = 0
    let result = 100 / (Number(productDescriptionDetail.mrp) / Number(productDescriptionDetail.mrp - Number(this.state.productPrice)));

    result = parseFloat(result.toFixed(2)); // Format to two decimal places and convert back to float
    const selectedImageUrl = this.state.selectedImage?.url ? `${base_url}${this.state.selectedImage.url}` : '';
    return (
      <ThemeProvider theme={theme}>
        {this.renderMakeOfferModal()}
        <div id="myref"></div>
        <Loader loading={this.state.loading} />
        <Container maxWidth={"lg"} className={classes.BoxWrapper} style={{ marginTop: '135px', marginBottom: '50px' }}>
          <Box className={classes.productImageWrap} style={{ gap: "50px" }} sx={{ display: "flex" }} data-test-id="mainContainer">
            <Box 
              className={classes.imageWrap}
              sx={{ width: "40%" }}>
              {!this.state.isShowingVideo ? (
                <ReactImageMagnify {...{
                  smallImage: {
                    isFluidWidth: false,
                    height: 400,
                    width: 476,
                    src: selectedImageUrl
                  },
                  largeImage: {
                    src: selectedImageUrl,
                    width: 600,
                    height: 900
                  },
                  imageStyle: { borderRadius: '20px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', objectFit: 'cover' },
                  lensStyle: { width: '50px', height: 50 },
                  enlargedImageContainerStyle: {zIndex: 1}
                }} />
              ) : (
                <video
                  controls
                  style={{
                    width: "100%",
                    borderRadius: "20px",
                    backgroundColor: "#000",
                    height: "400px",
                  }}
                >
                  <source
                    src={`${base_url}${this.state.selectedVideo.url}`}
                    type="video/mp4"
                  />
                </video>
              )}
              <Box
                style={{ gap: "29px" }}
                sx={{ display: "flex", marginTop: "20px" }}
              >
                {this.renderImages()}
                {this.state.selectedVariant?.catalogue_variant_videos?.map(
                  (vid: any, index: number) => {
                    return (
                      <Box
                        key={index}
                        sx={{
                          position: "relative",
                          width: "100px",
                          height: "90px",
                        }}
                        onClick={() => this.handleSelectedVideo(vid)}
                      >
                        <img
                        data-test-id="videoThumb"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "20px",
                          cursor: "pointer",
                          objectFit: 'cover',
                          border: this.state.isShowingVideo
                          ? "5px #F5EA16 solid"
                          : "none",
                        }}
                        src={`${base_url}${this.state.selectedVariant?.catalogue_variant_images[0].url}`}
                        />
                        <svg
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            cursor: 'pointer'
                          }}
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="Group 41202">
                            <g id="Group 41203">
                              <path
                                id="Vector"
                                opacity="0.7"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M12 21.8182C13.2893 21.8182 14.5661 21.5642 15.7573 21.0708C16.9485 20.5774 18.0308 19.8542 18.9425 18.9425C19.8542 18.0308 20.5774 16.9485 21.0708 15.7573C21.5642 14.5661 21.8182 13.2893 21.8182 12C21.8182 10.7107 21.5642 9.43394 21.0708 8.24274C20.5774 7.05155 19.8542 5.9692 18.9425 5.0575C18.0308 4.14579 16.9485 3.42259 15.7573 2.92918C14.5661 2.43577 13.2893 2.18182 12 2.18182C9.39606 2.18182 6.89876 3.21623 5.0575 5.0575C3.21623 6.89876 2.18182 9.39606 2.18182 12C2.18182 14.6039 3.21623 17.1012 5.0575 18.9425C6.89876 20.7838 9.39606 21.8182 12 21.8182ZM12 24C18.6273 24 24 18.6273 24 12C24 5.37273 18.6273 0 12 0C5.37273 0 0 5.37273 0 12C0 18.6273 5.37273 24 12 24Z"
                                fill="#000"
                              />
                              <path
                                id="Vector_2"
                                opacity="0.7"
                                d="M16.3638 12L9.81836 16.7236V7.27637L16.3638 12Z"
                                fill="#000"
                              />
                            </g>
                          </g>
                        </svg>
                      </Box>
                    );
                  }
                )}
              </Box>
              <Box
                style={{ gap: "15px" }}
                sx={{ marginTop: "84px", display: "flex", width: "90%" }}
                className={classes.followWarp}
              >
                <img
                  src={productDescriptionDetail.host_image}
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "50px",
                  }}
                />
                <Box sx={{ flex: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    className={classes.followNameButtonWrap}
                  >
                    <Box
                      style={{textTransform: 'capitalize'}}
                      sx={{
                        color: "#000",
                        fontSize: 16,
                        fontFamily: "Poppins !important",
                        fontWeight: "500",
                        letterSpacing: '0.25px'
                      }}
                    >
                      {productDescriptionDetail.host_name}
                    </Box>
                   {this.renderFollowUnfollowBtn(productDescriptionDetail)}
                    
                  </Box>
                  <Box
                    sx={{
                      marginTop: "12px",
                      color: "#828282",
                      fontSize: 12,
                      fontFamily: "Poppins !important",
                      fontWeight: "400",
                    }}
                  >
                    {productDescriptionDetail.host_bio}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={classes.productWrap} sx={{ flex: 1 }}>
              <Box
                sx={{
                  color: "#000",
                  fontSize: 20,
                  fontFamily: "Poppins !important",
                  fontWeight: "500",
                }}
              >
                {productDescriptionDetail.title}
              </Box>
              <Box sx={{ width: "100%", borderTop: "0.80px #EAEAEC solid" }} />
              <Box
                sx={{
                  color: "#000",
                  fontSize: '18px',
                  fontFamily: "Arial !important",
                  fontWeight: "400",
                  marginTop: "18px",
                }}
              >
                ₹{" "}
                <Box
                data-test-id="productPriceTxt"
                  sx={{
                    fontSize: 18,
                    fontFamily: "Poppins !important",
                    fontWeight: "500",
                    display: "-webkit-inline-box",
                  }}
                >
                  {this.state.productPrice}{" "}
                  <Box
                    style={{ opacity: "0.8" }}
                    sx={{
                      display: "inline",
                      fontFamily: "Poppins !important",
                      fontWeight: "400",
                      color: "#909090",
                      fontSize: "20px",
                    }}
                  >
                    {this.state.productMrp}{" "}
                  </Box>
                  <Box
                    style={{ opacity: "0.8", textDecorationLine: 'line-through' }}
                    sx={{
                      display: "inline",
                      fontFamily: "Arial !important",
                      fontWeight: "400",
                      color: "#909090",
                      fontSize: "20px",
                    }}
                  >
                    ₹{productDescriptionDetail.mrp}
                  </Box>
                  <Box
                      sx={{
                        padding: "2px 18px",
                        bgcolor: "#F5EA16",
                        borderRadius: "50px",
                        marginLeft: '8px'
                      }}
                  >
                    <Typography style={{
                      color: '#3A3A3A',
                      fontFamily: "Poppins !important",
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: 'normal',
                      letterSpacing: '0.25px',
                    }}>{result}% OFF</Typography>
                  </Box>
                </Box>
              </Box>
              <SelectSize sx={{ marginTop: "16px" }}>
                <Box
                  sx={{
                    display: "inline",
                    color: "#000",
                    fontSize: 16,
                    fontFamily: "Poppins !important",
                    fontWeight: "500",
                    lineHeight: '16px'
                  }}
                >
                  Select Size (UK Size)
                </Box>
                <Box
                  sx={{
                    marginLeft: "13.5px",
                    display: "inline-block",
                    color: "#FF3E6C",
                    fontSize: 14,
                    fontFamily: "Poppins !important",
                    fontWeight: "500",
                    lineHeight: '14px'
                  }}
                  onClick={this.handleOpenSizeChart}
                >
                  Size Chart
                </Box>
              </SelectSize>
              <ColorBox>
              {this.renderVariantColor()}
              </ColorBox>
              <Box
                className={classes.sizeMultiButton}
                style={{ gap: "20px" }}
                sx={{
                  marginTop: "18px",
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                {this.renderVariantSize()}
              </Box>
              <Box
                style={{ gap: "18px" }}
                sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
              >
              </Box>
              <Box
                sx={{
                  color: "#000",
                  fontSize: 20,
                  fontFamily: "Poppins !important",
                  fontWeight: "500",
                  marginTop: "42px",
                }}
              >
                Product Description
              </Box>
              <Box
                sx={{
                  width: "100%",
                  color: "#909090",
                  fontSize: 18,
                  fontFamily: "Poppins !important",
                  fontWeight: "400",
                }}
              >
                {productDescriptionDetail.description}
              </Box>
              <Box
                data-test-id="addToCartButton"
                onClick={this.state.stockQuantity > 0 ? () => this.handleAddToCart() : undefined}
                style={{ cursor: this.state.stockQuantity > 0 ? "pointer" : "not-allowed" }}
                sx={{
                  width: "275px",
                  bgcolor: this.state.stockQuantity > 0 ? "#F5EA16" : "#cccccc",
                  borderRadius: "50px",
                  padding: "15px 87px",
                  fontWeight: 600,
                  color: this.state.stockQuantity > 0 ? "#000" : "#666666",
                  marginTop: "31px",
                }}
              >
                {this.state.stockQuantity > 0 ? "Add to cart" : "Out of Stock"}
              </Box>
              {productDescriptionDetail.bargain && productDescriptionDetail.account?.store_type === "offline" ?
                <BargainBox className="bargainBox">
                  <Button className="makeOfferBtn" onClick={this.handleModalToggle} data-test-id="openModalTestId">
                    {configJSON.makeOfferTxt}
                  </Button>
                  <Button className="sendMessageBtn">
                    {configJSON.sendMessageTxt}
                  </Button>
                </BargainBox> 
                : 
              ""}
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              borderTop: "0.80px #EAEAEC solid",
              marginTop: "40px",
            }}
          />
          <Box sx={{ marginTop: "34px", display: "flex" }} className={classes.delivaryExchangeWrap}>
            <Box className={classes.delivaryWrap} sx={{ width: "40%",paddingRight:'50px', borderRight: "0.86px #EAEAEC solid" }}>
              <Box
                sx={{
                  color: "#000",
                  fontSize: 20,
                  fontFamily: "Poppins !important",
                  fontWeight: "500",
                }}
              >
                Delivery Options
              </Box>
              <Box
                sx={{
                  marginTop: "15px",
                  maxWidth: "475px",
                  width: '100%',
                  display: "flex",
                  justifyContent: "space-between",
                  borderRadius: "50px",
                  border: "1px #D4D5D9 solid",
                  padding: "11px 30px",
                }}
              >
                <form style={{width: 0, height: 0}} onSubmit={(event) => this.getDeliveryInfo(this.state.pincode, this.state.token, event)}>
                  <Box sx={{ fontSize: "16px", mt: "2px" }}>
                    <input
                      data-test-id="inputChangePincode"
                      style={{
                        border: "none",
                        outline: "none",
                      }}
                      maxLength={6}
                      value={this.state.pincode}
                      onChange={this.pincodeValidator}
                      onSubmit={(event) => this.getDeliveryInfo(this.state.pincode, this.state.token, event)}
                      placeholder="PIN Code"
                    />
                  </Box>
                </form>
                <Box
                  data-test-id="buttonGetDelivery"
                  onClick={(event) =>
                    this.getDeliveryInfo(this.state.pincode, this.state.token, event)
                  }
                  style={{ cursor: "pointer" }}
                  sx={{ fontSize: "16px", fontWeight: "600" }}
                >
                  Change
                </Box>
              </Box>
              <Box
                style={{ gap: "9px" }}
                sx={{ marginTop: "20px", display: "flex" }}
              >
                <svg
                  width="27"
                  height="25"
                  viewBox="0 0 27 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Group 40723">
                    <path
                      id="Vector"
                      d="M25.7641 5.27234L16.3891 0.11609C16.2506 0.0399331 16.0952 0 15.9372 0C15.7792 0 15.6238 0.0399331 15.4853 0.11609L6.11033 5.27234C5.96334 5.35327 5.84077 5.47217 5.7554 5.61662C5.67003 5.76108 5.625 5.92579 5.625 6.09359C5.625 6.26138 5.67003 6.4261 5.7554 6.57056C5.84077 6.71501 5.96334 6.83391 6.11033 6.91484L14.9997 11.8039V21.853L12.1535 20.2873L11.2497 21.9289L15.4853 24.2586C15.6237 24.3349 15.7792 24.3749 15.9372 24.3749C16.0952 24.3749 16.2507 24.3349 16.3891 24.2586L21.0766 21.6805L25.7641 19.1023C25.9111 19.0214 26.0338 18.9026 26.1192 18.7581C26.2046 18.6137 26.2497 18.4489 26.2497 18.2811V6.09359C26.2497 5.92577 26.2046 5.76103 26.1192 5.61657C26.0338 5.47211 25.9111 5.35323 25.7641 5.27234ZM15.9372 2.00796L23.3669 6.09359L15.9372 10.1792L8.50752 6.09359L15.9372 2.00796ZM24.3747 17.727L16.8747 21.852V11.803L24.3747 7.67796V17.727Z"
                      fill="#000"
                    />
                    <path
                      id="Vector_2"
                      d="M7.5 12.1875H0V10.3125H7.5V12.1875ZM9.375 19.6875H1.875V17.8125H9.375V19.6875ZM11.25 15.9375H3.75V14.0625H11.25V15.9375Z"
                      fill="#000"
                    />
                  </g>
                </svg>
                <Box
                  sx={{
                    color: "#000",
                    fontSize: 16,
                    fontFamily: "Poppins !important",
                    fontWeight: "600",
                  }}
                  data-test-id="deliveryInfoId"
                >
                  {this.state.deliveryInfo
                    ? `Get it by ${this.formatDate(
                        this.state.deliveryInfo.delivery_date
                      )}`
                    : "Get it by"}
                </Box>
              </Box>
              <Box
                style={{ gap: "9px" }}
                sx={{ marginTop: "20px", display: "flex" }}
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    id="streamline:money-cash-bill-3-accounting-billing-payment-finance-cash-currency-money-bill"
                    clipPath="url(#clip0_11014_92436)"
                  >
                    <g id="Group">
                      <path
                        id="Vector"
                        d="M6.28725 19.25L19.6444 19.25C20.5123 19.25 21.2158 18.5464 21.2158 17.6786L21.2158 8.25C21.2158 7.38213 20.5123 6.67857 19.6444 6.67857L6.28725 6.67857C5.41938 6.67857 4.71582 7.38212 4.71582 8.25L4.71582 17.6786C4.71582 18.5464 5.41937 19.25 6.28725 19.25Z"
                        stroke="#000"
                        strokeWidth="1.57143"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        id="Vector_2"
                        d="M12.9661 10.6072C11.6643 10.6072 10.609 11.6626 10.609 12.9644C10.609 14.2662 11.6643 15.3215 12.9661 15.3215C14.2679 15.3215 15.3232 14.2662 15.3232 12.9644C15.3232 11.6626 14.2679 10.6072 12.9661 10.6072Z"
                        stroke="#000"
                        strokeWidth="1.57143"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        id="Vector_3"
                        d="M16.502 2.7499L2.3591 2.7499C1.94233 2.7499 1.54263 2.91546 1.24793 3.21016C0.953231 3.50486 0.78767 3.90455 0.78767 4.32132L0.787669 12.1785"
                        stroke="#000"
                        strokeWidth="1.57143"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </g>
                  <defs>
                    <clipPath id="clip0_11014_92436">
                      <rect
                        width="22"
                        height="22"
                        fill="white"
                        transform="translate(22 22) rotate(-180)"
                      />
                    </clipPath>
                  </defs>
                </svg>

                <Box
                  sx={{
                    color: "#000",
                    fontSize: 16,
                    fontFamily: "Poppins !important",
                    fontWeight: "600",
                  }}
                >
                  Pay on Delivery is available
                </Box>
              </Box>
            </Box>
            <Box className={classes.exchangeWrap} sx={{ flex: 1, paddingLeft: "32px" }}>
              <Box
                sx={{
                  color: "#000",
                  fontSize: 20,
                  fontFamily: "Poppins !important",
                  fontWeight: "500",
                }}
              >
                Exchange and Return policy
              </Box>
              <Box
                style={{ opacity: "0.80"}}
                sx={{
                  marginTop: "10px",
                  width: "100%",
                  color: "#909090",
                  fontSize: 16,
                  fontFamily: "Poppins !important",
                  fontWeight: "normal",
                  letterSpacing: 0.25,
                }}
              >
                Lorem ipsum dolor sit amet consectetur. At urna ullamcorper
                consequat odio senectus mauris in at. Egestas nec sit odio vitae
                vitae consequat. Arcu nunc diam sit natoque nunc leo in sit
                pellentesque. Praesent amet ullamcorper sed tortor facilisis.
                Pellentesque orci non viverra cursus ultrices praesent in. Enim
                aenean ut platea lectus euismod diam sit maecenas.
              </Box>
            </Box>
          </Box>
          <Box sx={{ marginTop: "34px" }}>
            {!isEmpty(this.state.relatedProduct) &&
              <Box
                sx={{
                  color: "#000",
                  fontSize: 20,
                  fontFamily: "Poppins !important",
                  fontWeight: "500",
                }}
              >
                Similar Products
              </Box>
            }
                      <Box
                        className={classes.similarProductWrap}
                        sx={{
                          margin: "0px -31px 0",
                          display: "flex",
                          flexWrap: 'wrap'
                        }}
                      >
              {this.state.relatedProduct &&
                this.state.relatedProduct
                  .slice(0, 5)
                  .map((product: any, index: number) => {
                    return (
                      
                        <Box key={index} className={classes.similarProduct} sx={{
                          position: "relative",
                          flex: '0 0 20%',
                          maxWidth: '20%',
                          padding: '0 31px',
                        }}>
                          {
                            this.checkProductStatus(wishListProduct, parseInt(product.id))
                              ? (
                                <Box className={classes.contentBlockArchive} data-test-id={`removeWishItem-${index}`} onClick={() => this.deleteProductItem(wishListProduct, parseInt(product.id))}>
                                  <img src={liveArchive} alt="wished" />
                                </Box>
                              ) : (
                                <Box className={classes.contentBlockArchive} data-test-id={`addWishItem-${index}`} onClick={() => this.updateWishListItem(product.id, product.type)}>
                                  <img src={liveArchiveBlank} alt="not-wished" />
                                </Box>
                              )
                          }
                          <Box sx={{ position: "absolute" }} />
                          <Box data-test-id="buttonTestId" onClick={() => this.handleProdNavigate(product.id)}
                            style={{ cursor: 'pointer', width: '100%' }}>
                            <img
                              style={{
                                // width: "190px",
                                width: '100%',
                                // maxWidth: '100%',
                                // height: "100%",
                                height: '263px',
                                display: 'block',
                                borderRadius: "10px",
                                objectFit: 'cover',
                                objectPosition: 'center'
                              }}
                              src={product.attributes.product_images && `${base_url}${product.attributes.product_images[0].url}`}
                            />
                          </Box>
                          <Box
                            style={{
                              marginTop: "16px",
                              display: "flex",
                              gap: "8px",
                              alignItems: "center",
                            }}
                          >
                            <img
                              style={{
                                width: "24px",
                                height: "24px",
                                borderRadius: "50px",
                              }}
                              src={`${product.attributes.host_image}`}
                            />
                            <Box
                              style={{ textTransform: 'capitalize' }}
                              sx={{
                                color: "#000",
                                fontSize: 12,
                                fontFamily: "Poppins !important",
                                fontWeight: "500",
                              }}
                            >
                              {product.attributes.host_name}
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              marginTop: "12px",
                              color: "#000",
                              fontSize: 12,
                              fontFamily: "Poppins !important",
                              fontWeight: "500",
                            }}
                            style={{
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              display: "-webkit-box"
                            }}
                          >
                            {product.attributes.title}
                          </Box>
                          <Box
                            style={{
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "100%",
                              display: "-webkit-box",
                            }}
                            sx={{
                              marginTop: "12px",
                              color: "#A5A4A4",
                              fontSize: 12,
                              fontFamily: "Poppins !important",
                              fontWeight: "normal",
                            }}
                          >
                            {product.attributes.description}
                          </Box>
                          <Box
                            sx={{
                              marginTop: "5px",
                              color: "#000",
                              fontSize: 16,
                              fontFamily: "Arial !important",
                              fontWeight: "bold",
                            }}
                          >
                            ₹{" "}
                            <Box
                              sx={{
                                display: "inline",
                                color: "#000",
                                fontSize: 16,
                                fontFamily: "Poppins !important",
                                fontWeight: "500",
                              }}
                            >
                              {product.attributes.selling_price}
                            </Box>
                          </Box>
                          <Box
                            style={{ cursor: product.attributes.inventory_details.stock_quantity > 0 ? "pointer" : "not-allowed" }}
                            sx={{
                              width: "130px",
                              bgcolor: product.attributes.inventory_details.stock_quantity > 0 ? "#F5EA16" : "#cccccc",
                              borderRadius: "50px",
                              padding: "4px 20px",
                              fontWeight: 600,
                              color: product.attributes.inventory_details.stock_quantity > 0 ? "#000" : "#666666",
                              marginTop: "20px",
                              fontSize: "14px",
                            }}
                            data-test-id={`similar-add-to-cart-${index}`}
                            onClick={() => this.handleClickSimilarAddtoCart(product)}
                          >
                            {product.attributes.inventory_details.stock_quantity > 0 ? "Add to cart" : "Out of Stock"}
                          </Box>
                        </Box>
                    );
                  })}
            </Box>
          </Box>
        </Container>
        <MessageModalWeb
        data-test-id="messageModalId"
            displayPopup={this.state.messageModel}
            closeErrorPopup={this.closeMessageModal}
            errorMessage={this.state.message} />
        <StayTuned openTunedBox={this.state.openTunedBox} data-test-id="closeStayTuned" closeTunedBox={this.StayTunedBox} />
        <Dialog
          onClose={this.handleSizeChartClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.sizeChartopen}
          data-test-id="chartDialogId"
        >
          <DialogTitle id="customized-dialog-title" >
            <Box className={classes.sizeCharTitleContainer}>
              <Box className={classes.sizeCharTitle} component={"span"}>
                {configJSON.sizeChart}
              </Box>
              <Box component={"span"}   onClick={this.handleSizeChartClose}>
                <img src={crossImage} alt="crossIcon" className={classes.crossIcon} />
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom className={classes.inregularText}>{configJSON.inRegular}</Typography>
            <Box>
              <img src={this.state.productDescription?.attributes?.manufacturing_detail?.data?.attributes?.size_chart} alt="sizeChart" className={classes.sizeChart} />
            </Box>

          </DialogContent>
        </Dialog>

      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = (theme: any) => ({
  delivaryExchangeWrap: {
    '@media (max-width: 1200px)': {
      flexDirection: 'column',
    },
  },
  delivaryWrap: {
    '@media (max-width: 1200px)': {
      borderRight: '0',
      flex: '0 0 100%',
      maxWidth: '100%',
      width: '100%'
    },
  },
  exchangeWrap: {
    '@media (max-width: 1200px)': {
      marginTop: '20px',
      flex: '0 0 100%',
      paddingLeft: 0
    },
  },
  productImageWrap: {
    '@media (max-width: 1200px)': {
      flexDirection: 'column',
    },
  },
  productWrap: {
    '@media (max-width: 1200px)': {
      margin: '0 auto'
    },
  },
  imageWrap: {
    '@media (max-width: 1200px)': {
      maxWidth: '565px',
      margin: '0 auto',
      width: '100%'
    },
  },
  followWarp: {
    '@media (max-width: 765px)': {
      // flexDirection: 'column',
    },
  },
  followNameButtonWrap: {
    '@media (max-width: 525px)': {
      // flexDirection: 'column',
    },
  },
  sizeMultiButton: {
    '@media (max-width: 919px)': {
      gap: '15px',

    },
  },
  sizeButton: {
    '@media (max-width: 919px)': {
      padding: '5px 15px'
    },
    '@media (max-width: 525px)': {
      padding: '2px 7px'
    },
  },
  colorButton: {
    '@media (max-width: 919px)': {
      width: '35px',
      height: '35px'
    },
    '@media (max-width: 625px)': {
      width: '30px',
      height: '30px'
    },
    '@media (max-width: 525px)': {
      width: '25px',
      height: '25px'
    },
    '@media (max-width: 425px)': {
      width: '20px',
      height: '20px'
    },
  },
  BoxWrapper: {
    '@media (max-width: 1150px)': {
      marginTop: '100px !important'
    },
  },
  similarProduct: {
    marginTop: '20px',
    '@media (max-width: 1185px)': {
      padding: '0 15px',
    },
    '@media (max-width: 991px)': {
      padding: '0 15px',
      flex: '0 0 25%', 
      maxWidth: '25%',
      marginTop: '20px'
    },
    '@media (max-width: 765px)': {
      padding: '0 15px',
      flex: '0 0 33.33%', 
      maxWidth: '33.33%',
      marginTop: '20px'
    },
    '@media (max-width: 665px)': {
      padding: '0 15px',
      flex: '0 0 50%', 
      maxWidth: '50%',
      marginTop: '20px'
    },
    '@media (max-width: 365px)': {
      padding: '0 15px',
      flex: '0 0 100%', 
      maxWidth: '100%',
      marginTop: '20px'
    },
  },
  similarProductWrap: {
    '@media (max-width: 1185px)': {
      margin: "20px -15px 0",
    },
  },
  contentBlockArchive: {
    position: "absolute",
    top: "12px",
    right: "40px",
    borderRadius: "25px",
    background: "#00000099",
    padding: "6px 5px 5px 5px",
    cursor: 'pointer'
  },
  sizeChart:{
    height:"430px",
    width:"400px"
  },
  sizeCharTitleContainer: {
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center"
    },
  sizeCharTitle:{
    color: '#000',
    textAlign: 'center',
    fontFamily: "Poppins !important",
    fontSize: '26px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal'
  },
  inregularText:{
      color: '#000',
      fontFamily: "Poppins !important",
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
      letterSpacing: '0.381px',
  },
  crossIcon:{
    height:"10px",
    width:"10px",
    cursor:"pointer"
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: "white",
    width: "100%",
    maxWidth:"518px",
    borderRadius: "22px",
    minHeight: "450px",
    padding: "45px 45px",
    "@media(max-width:480px)":{
      margin:"20px"
    }
  },
  backdrop: {
    background: '#0000004D',
    backdropFilter: "blur(2px)"
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between"
  },
  modalHeadingTxt: {
    fontWeight: 500,
    fontSize: "26px"
  },
  modalBody: {
    borderTop: "1.52px solid #E9E9E9",
    maxWidth: "440px",
    marginTop: "20px",
    padding: "0px 38px",
    paddingTop: "20px",
    width: "100%",
    "@media(max-width:480px)":{
      padding:"0px",
      paddingTop:"20px",
    }
  },
  modalBodyContainer: {
    display: "flex",
    justifyContent: "center",
  },
  basePriceTxt: {
    fontSize: "21px",
    lineHeight: "31px",
    fontWeight: 400
  },
  priceTxt: {
    fontSize: "26px",
    fontWeight: 900,
    marginLeft: "14px"
  },
  basePriceBox: {
    display: "flex",
    alignItems: "center",
  },
  offerInputField: {
    outline: "none",
    width: "100%",
    maxWidth: "345px",
    padding: "14px",
    borderRadius: "50px",
    border: "1px solid grey",
    appearance: "none",
  },
  inputFieldBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "30px",
    flexDirection:"column"
  },
  errorText: {
    fontSize: "14px",
    color: "red"
  },
  modalCloseBtn: {
    border: "none",
    outline: "none",
    background: "white"
  },
  closeIcon: {
    width: "18px",
    height: "18px"
  },
  offerPercentBox: {
    width: "100%",
    maxWidth: "111px",
    border: "1px solid #000000",
    borderRadius: "50px",
    textAlign: "center",
    padding: "2px",
    fontSize: "20px",
    fontWeight: 400,
    cursor:"pointer",
    "@media(max-width:480px)":{
      padding:"10px"
    }
  },
  activeBox:{
    background: "#F5EA16",
  },
  offerPercentContainer: {
    display: "flex",
    gap: "10px",
    marginTop: "30px"
  },
  makeOfferModalBtn: {
    maxWidth: "193px",
    width: "100%",
    borderRadius: "100px",
    background: "#F5EA16",
    color: "#000000",
    minHeight: "45px",
    fontSize: "18px",
    fontWeight: 400,
    textTransform: "none",
    "&:hover":{
      background: "#F5EA16",
    }
  },
  makeOfferModalBtnBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "50px"
  }
});

const ColorBox = styled(Box)({
  display: "flex",
  gap: "16px"
})

const SizeNameWrapper = styled(Box)({
  display: "flex",
  gap: "12px"
})

const SizeName = styled(Typography)({
  padding: "6px",
  borderRadius: "8px",
  border: "1px solid #000",
  textTransform: "capitalize",
  minWidth: "40px",
  textAlign: "center",
  cursor: "pointer",
  "&.activeSize":{
    backgroundColor: "yellow",
    border: "1px solid transparent"
  }
});

const SelectSize = styled(Box)({
  margin: "16px 0"
})

const BargainBox = styled(Box)({
  marginTop: "30px",
  display: "flex",
  gap: "20px",
  "& .makeOfferBtn": {
    width: "100%",
    maxWidth: "275px",
    background: "#F5EA16",
    borderRadius: "100px",
    minHeight: "54px",
    color: "#000000",
    fontSize: "16px",
    fontWeight: 600,
    textTransform: "none",
  },
  "& .sendMessageBtn": {
    width: "100%",
    border: "1px solid #F5EA16",
    maxWidth: "275px",
    minHeight: "54px",
    borderRadius: "100px",
    fontSize: "16px",
    color: "#000000",
    textTransform: "none",
    fontWeight: 600,
  }
});

// @ts-ignore
export default withStyles(styles)(withRouter(ProductDescription))
export {ProductDescription}
// Customizable Area End
