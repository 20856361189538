// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";

interface ApiPayloadType{
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    type?: string;
}

interface Role {
    id: number;
    name: string; 
    created_at: string; 
    updated_at: string; 
    page_names: string[]; 
}

interface Account {
    id: number;
    user_name: string;
    full_name: string;
    email: string;
    role: Role;
    profile_photo: string | null;
}

interface MetaData {
    total_pages: number;
    total_account: number;
}

interface SearchResponse {
    accounts: Account[];
    meta: MetaData;
}

interface Catalogue {
    id: string;
    type: string;
    checked: boolean;
    attributes: CatalogueAttributes;
}

interface CatalogueAttributes {
    category: Category;
    sub_category: SubCategory;
    brand?: Brand | null;
    title: string;
    description: string;
    status: string;
    mrp: number;
    selling_price: number;
    ask_price: number | null;
    bargain: boolean;
    on_order: boolean;
    is_ask_price: boolean;
    is_brand: boolean;
    tags: string[];
    reviews: string[];
    sku: string;
    account: Account;
    images?: string | null;
    host_name: string;
    host_bio: string;
    host_image: string;
    product_images: ProductImage[];
    inventory_details?: InventoryDetails | null;
    shipment_charge?: ShipmentCharge | null;
    packaging_detail?: PackagingDetail | null;
    manufacturing_detail?: ManufacturingDetail | null;
    product_detail?: string | null;
    product_videos?: ProductVideo[] | null;
    average_rating: number;
    catalogue_variants: CatalogueVariant[];
    catalogues_specifications: CatalogueSpecification[];
}

interface Category {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
    slug?: string | null;
}

interface SubCategory {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
}

interface Brand {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
}

interface ProductImage {
    id: number;
    url: string;
}

interface InventoryDetails {
    id: number;
    fullfilment_by: string;
    procurement_type: string;
    procurement_sla: string;
    stock_quantity: number;
    catalogue_id: number;
    account_id: number;
}

interface ShipmentCharge {
    id: number;
    local_delivery_charge: string;
    zonal_delivery_charge: string;
    national_delivery_charge: string;
}

interface PackagingDetail {
    id: number;
    package_length: string;
    package_weight: string;
    package_breadth: string;
    package_height: string;
}

interface ManufacturingDetail {
    data: ManufacturingAttributes;
}

interface ManufacturingAttributes {
    id: string;
    country: string;
    manufacture_details: string;
    packer_details: string;
    return_and_exchange: boolean;
    return_and_refund: boolean;
}

interface ProductVideo {
    id: number;
    url: string;
}

interface CatalogueVariant {
    id: string;
    checked: boolean;
    attributes: {
        id: number;
        catalogue_id: number;
        catalogue_variant_color: VariantColor;
        catalogue_variant_size: VariantSize;
        price: string;
        stock_qty: number;
        product_images: ProductImage[];
        product_videos?: ProductVideo[];
    }
}

interface VariantColor {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
}

interface VariantSize {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
}

interface CatalogueSpecification {
    id: number;
    specification: Specification;
    specification_text: string;
}

interface Specification {
    id: number;
    specification_type: string;
    name: string;
    sub_category_id: number;
    specification_data: string;
    created_at: string;
    updated_at: string;
  }
  
// Customizable Area End

export const configJSON = require("./config");

export interface Props{
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: Record<string,string>
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    loading:boolean
    isSideBarOpen:boolean
    showTitle:string,
    description:string
    thumbnailImage: null | File
    videoFile: null | File;
    errorModal:boolean
    errorMessage:string
    sucessModal:boolean
    primaryCategory: string;
    subCategory: string,
    categoriesList:Array<any>
    subCategoriesList:Array<any>
    date:any
    time:string  | Blob
    addTags: string[],
    addTagsValue: string,
    muteWordsList:string[],
    mutedWordsValue:string,
    explicitContent: boolean,
    muteWords: boolean,
    privateEvent: boolean,
    tokenPresentOrNot: string | null;
    showVideoWarning: string;
    storyTitle: string;
    storyDuration: number;
    tagUserData: Array<Account>;
    isInventoryOpen: boolean;
    productList: Catalogue[];
    checkedProductList: string[];
    checkedVarientList: string[];
    selectedProducts: CatalogueVariant[];
    prodListBackup: Catalogue[];
    inveVaritentCurId: string;
    searchProductText: string;
    invCurrentTotalPage: number;
    invCurrentTotalCount: number;
    inveCurSelectedDes: string;
    inventoryVarient: CatalogueVariant[];
    currentPage: number;
    inveCurSelectedName: string,
    taggedUserId: number[];
    isProdVarientOpen: boolean;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CreateStoryController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getCategoriesId: string =""
    getSubCategoriesId: string =""
    createScheduleLiveMsgId:string=""
    postStoryApiCallId: string = "";
    searchApiCallId: string = "";
    getProdAPICallId: string = "";
    videoRef: React.RefObject<HTMLVideoElement>;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area End
        ];

        // Customizable Area Start
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        this.videoRef = React.createRef();
        // Customizable Area End

        this.state = {
            // Customizable Area Start
            loading:false,
            isSideBarOpen:true,
            showTitle:"",
            description:"",
            thumbnailImage: null,
            videoFile: null,
            errorModal:false,
            sucessModal:false,
            errorMessage:'',
            primaryCategory: "default",
            subCategory: "default",
            categoriesList:[],
            subCategoriesList:[],
            date:null,
            time:"",
            addTags: [],
            addTagsValue: "",
            muteWordsList:[],
            mutedWordsValue:"",
            explicitContent: false,
            muteWords: false,
            privateEvent: false,
            tokenPresentOrNot: null,
            showVideoWarning: "",
            storyTitle: "",
            tagUserData: [],
            productList: [],
            isProdVarientOpen: false,
            checkedVarientList: [],
            inveCurSelectedName: "",
            selectedProducts: [],
            inveVaritentCurId: "",
            searchProductText: "",
            invCurrentTotalPage: 0,
            invCurrentTotalCount: 0,
            currentPage: 1,
            inveCurSelectedDes: "",
            inventoryVarient: [],
            checkedProductList: [],
            prodListBackup: [],
            isInventoryOpen: false,
            taggedUserId: [],
            storyDuration: 5
            // Customizable Area End
        };

    }


    // Customizable Area Start

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (apiRequestCallId === this.postStoryApiCallId) {
            if (responseJson.data.type != "error") {
                this.postStorySuccessCall();
            }
            else {
                this.postStoryFailureCall();
            }
        }
        if (apiRequestCallId === this.searchApiCallId) {
            if (responseJson.accounts.length) {
                this.setState({ invCurrentTotalPage: 0 });
                this.searchStorySuccessCall(responseJson);
            }
        }
        if (apiRequestCallId === this.getProdAPICallId) {
            if (responseJson.catalogues.data.length) {
                const prodData = responseJson.catalogues.data;
                const combinedData = [...this.state.prodListBackup, ...prodData];
                const updatedProdList = Array.from(
                    new Map(
                        combinedData.map(
                            item => [item.id, item])).values());
                this.setState({
                    prodListBackup: updatedProdList,
                    productList: prodData,
                    invCurrentTotalCount: responseJson.meta.total_counts,
                    invCurrentTotalPage: responseJson.meta.total_pages,
                    loading: false
                });
        }
          }
    };

    async componentDidMount() {
        const tokenPresentOrNot = await getStorageData('singupLogin');
        this.setState({ tokenPresentOrNot: tokenPresentOrNot });
        this.handleInventory();
    };
    handleSideBar = () => {
        this.setState({ isSideBarOpen: !this.state.isSideBarOpen })
    }
    handleBackNavigation=()=>{
        if (this.state.isInventoryOpen){
            this.setState({isInventoryOpen: false});
        }else{
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage),"LiveStreaming");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
        }
    }

    handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const fileType = files[0].type;
            if (fileType.startsWith('video/')) {
                this.handleVideoStory(files);
            } else if (fileType.startsWith('image/')) {
                this.handleImageStory(files);
            }
        }
    };

    handleVideoStory = (files: FileList) => {
        const mediaURL = URL.createObjectURL(files[0]);
        if (this.videoRef.current) {
            this.videoRef.current.src = mediaURL;
            this.videoRef.current.onloadedmetadata = () => {
                const duration = this.videoRef.current?.duration;
                if (duration) {
                    if (duration > 30) {
                        this.setState({ showVideoWarning: configJSON.showVideoWarning.replace("{duration}", duration.toFixed(2)) });
                    }
                    this.setState({ thumbnailImage: files[0], storyDuration:duration });
                }
            };
        }
    };
    handleImageStory = (files: FileList) => {
        const imageFile = files[0];
            if (imageFile.size <= 5 * 1024 * 1024) {
                this.setState({ thumbnailImage: imageFile });
            } else {
                this.setState({errorModal:true,errorMessage:configJSON.validateMessageForImage})
            }
    };

    handleRemoveImage = () => {
        this.setState({ thumbnailImage: null, showVideoWarning: "" });
    };
    errorModalClose=()=>{
        this.setState({errorModal:false,errorMessage:''})
    }
    sucessMessageClose=()=>{
      this.setState({sucessModal:false})
     }

    handleSwitchChange = (fieldName: keyof S) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        this.setState({
            [fieldName]: event.target.checked,
        } as unknown as Pick<S, keyof S>);
        if (fieldName === "muteWords" && !event.target.checked) {
            this.setState(state => ({
                muteWordsList: [],

            }))
        }
    };

    apiCall = async (apiReqData: ApiPayloadType) => {
        const { contentType, method, endPoint, body, type } = apiReqData;
        const tokenPresentOrNot = this.state.tokenPresentOrNot ?? null;
        const header = {
            "Content-Type": contentType,
            token: tokenPresentOrNot,
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        body && type != 'formData' ?
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )
            : requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    postStory = async () => {
          if (this.state.thumbnailImage){
            const formdata = new FormData();
            formdata.append("data[attributes][title]", this.state.storyTitle);
            formdata.append("data[attributes][description]", "");
            formdata.append("data[attributes][media]", this.state.thumbnailImage, this.state.thumbnailImage.name);
            formdata.append("data[attributes][duration]", this.state.storyDuration.toString());
            this.setState({ loading: true });
            this.postStoryApiCallId = await this.apiCall({
                method: configJSON.exampleAPiMethod,
                endPoint: configJSON.createStoryEndPoint,
                body: formdata,
                type: "formData"
            });
        };
    };

    changeStoryTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ storyTitle: event.target.value })
    };

    postStorySuccessCall = () => {
        this.setState({ loading: false, sucessModal: true, storyTitle: "" },()=>{
            this.handleRemoveImage();
            this.handleRemoveAllChecked();
            this.handleSellerFeedNav();
        });
    };

    postStoryFailureCall = () => {
        this.setState({ loading: false, errorMessage: configJSON.wentWrong, errorModal: true })
    };

    fetchUsers = async(event: React.ChangeEvent<HTMLInputElement>) => {
        this.searchApiCallId = await this.apiCall({
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.searchEndPoint.replace("{userName}", event.target.value),
            type: ""
        });
    };

    searchStorySuccessCall = (response: SearchResponse) => {
        const filteredTagList = response.accounts.filter((users: Account) => !this.state.taggedUserId.includes(users.id));    
        this.setState({ tagUserData :filteredTagList});
    };

    handleShownventory = () => {
        this.setState({ isInventoryOpen: true });
    };

    handleProdAddBtn = () => {
        const { checkedVarientList, prodListBackup } = this.state;
        const matchedProdVariants = prodListBackup.flatMap(item =>
            item.attributes.catalogue_variants.filter(variant =>
                checkedVarientList.includes(variant.id)
            )
        );
        this.setState({
            isInventoryOpen: false,
            selectedProducts: matchedProdVariants
        });
    };

    handleProdChecked = (event: React.ChangeEvent<HTMLInputElement>, product: Catalogue) => {
        const { checkedProductList, productList, inventoryVarient, checkedVarientList } = this.state;
        const prodTitle = product.attributes.title;
        const prodId = product.id;
        const prodDescription = product.attributes.description;
        const isChecked = event.target.checked;

        const updatedCheckedProdList = isChecked
            ? [...checkedProductList, prodId]
            : checkedProductList.filter(id => id !== prodId);

        const updatedProdList = productList.map(item =>
            item.id === prodId ? { ...item, checked: isChecked } : item
        );

        const updatedCheckedVarientList = isChecked
            ? checkedVarientList
            : checkedVarientList.filter(
                variant => !inventoryVarient.some(inventoryVariant => inventoryVariant.id === variant)
            );
        if (product.attributes.catalogue_variants.length == 0) {
            this.setState({ errorModal: true, errorMessage: "No variant available" })
            return
        };
        this.setState({
            productList: updatedProdList,
            checkedProductList: updatedCheckedProdList,
            inveVaritentCurId: prodId,
            inventoryVarient: product.attributes.catalogue_variants,
            inveCurSelectedDes: prodDescription,
            isProdVarientOpen: isChecked,
            checkedVarientList: updatedCheckedVarientList,
            inveCurSelectedName: prodTitle
        });
    };

    handleRemoveAllChecked = () => {
        const { productList } = this.state;

        const updatedProdList = productList.map(item => ({
            ...item,
            checked: false
        }));

        this.setState({
            checkedProductList: [],
            productList: updatedProdList,
            selectedProducts: [],
            checkedVarientList: []
        });
    };

    handleInventoryVarientChecked = (event: React.ChangeEvent<HTMLInputElement>, inventory: CatalogueVariant) => {
        const { checkedVarientList, inventoryVarient } = this.state;
        const inventoryVarientId = inventory.id;
        const isChecked = event.target.checked;
        const updatedCheckedProdList = isChecked ? [...checkedVarientList, inventoryVarientId] : checkedVarientList.filter(id => id !== inventoryVarientId);
        const updatedInventoryVarientList = inventoryVarient.map(item =>
            item.id === inventoryVarientId ? { ...item, checked: isChecked } : item
        );
        this.setState({ inventoryVarient: updatedInventoryVarientList, checkedVarientList: updatedCheckedProdList });
    };

    handleVarientModalClose = () => {
        const { checkedProductList, productList } = this.state;
        const updatedCheckedProdList = checkedProductList.filter(id => id !== this.state.inveVaritentCurId)
        const updatedProdList = productList.map(item =>
            item.id === this.state.inveVaritentCurId ? { ...item, checked: false } : item
        );
        this.setState({
            checkedProductList: updatedCheckedProdList,
            productList: updatedProdList,
            isProdVarientOpen: false
        });
    };

    handleVarientAddBtn = () => {
        this.setState({ isProdVarientOpen: false });
    };

    findInventoryDetails = (whichType: string, catalogid: number) => {
        const matchedItem = this.state.prodListBackup.find((item) => Number(item.id) === catalogid);
        if (matchedItem) {
            if (whichType === "name") {
                return matchedItem.attributes.title
            } else {
                return matchedItem.attributes.description
            }
        }
    };

    handleInventory = async () => {
        this.getProdAPICallId = await this.apiCall({
            endPoint: configJSON.getInventoryEndPoint + `?page=${this.state.currentPage}` + `&search=${this.state.searchProductText}`,
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType
        });
    };

    handleInventoryPostSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ currentPage: 1, searchProductText: event.target.value }, () => {
            this.handleInventory();
        })
    };

    handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        this.setState({ loading: true });
        this.setState({ currentPage: page }, () => {
            this.handleInventory();
        });
    };

    handleAddProductPost = async () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationTargetMessage), "AddNewProduct");
        this.send(message)
    };

    handleTagUsers = (value: Account[]) => {
        let userIds: number[] = [];
        const checkArray = Array.isArray(value);
        checkArray && value.map((userInfo) => {
          userIds.push(userInfo.id);
        });
        this.setState({ taggedUserId: userIds, tagUserData: [] });
      };

    handleSellerFeedNav = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationTargetMessage), "SellerFeed");
        this.send(message)
    };

    // Customizable Area End
}