Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LiveStreaming";
exports.labelBodyText = "LiveStreaming Body";
exports.searchFlixoo = "Search Flixoo Live"
exports.btnExampleTitle = "CLICK ME";
exports.create="Create";
exports.instant="Instant Go Live";
exports.schedule="Schedule a Live Stream";
exports.test="Test Live Stream";
exports.post="Create a Post";
exports.story="Create a Story";
exports.auction="Schedule an Auction";
exports.aboutTheHost= "About the host"
exports.notifyMe = "Notify Me"
exports.hostName = "Ivan Morais"
exports.hostBio = "Premium Accessories, Made of pure lather & premium metals"
exports.productName = "Product Name"
exports.scheduleDate = "15/04 7:32 PM"
exports.productDescription = "Introducing exclusive pair of limited edition Nike sneakers in white color. These sneakers are not only stylish but also highly sought after by sneaker enthusiasts and fashion enthusiasts alike."
exports.getSubCategoryAPIEdPoint = "bx_block_categories/sub_categories/for_you_sub_categories"
exports.getSubCategoryDataAPIEdPoint = "bx_block_advanced_search/search?type=products&sub_category_ids[]="
exports.favouriteItemEndPoint = "bx_block_favourite/favourites"
exports.delete = "DELETE"

exports.postMethodType = "POST"

exports.putMethodType = "PUT"

exports.scheduleLive = "Schedule Live"
exports.createStory = "Create Story"
exports.instantLive = "Live Information"
exports.showTitle = "Show Title"
exports.description = "Show Description"
exports.date  = "Date"

exports.addThumbnailLabel = "Add thumbnail"
exports.uploadImage = "Upload Images 0/6"
exports.upload = "Upload"
exports.here = "here"
exports.validationSize = "Max 5 mb"
exports.addVideo = "Add Preview video" 
exports.selectYourPrimaryCategory = "Select your primary category"
exports.selectYourSubCategory = "Select your sub category"
exports.selectAnyOne  = "Select any one"
exports.addTagsLabel = "Add Tags"
exports.add = "Add"
exports.muteWords = "Mute words"
exports.contentSettings = "Content Settings"
exports.getCategoriesEndpoint = "bx_block_categories/categories"
exports.getSubCategoriesEndpoint = "bx_block_categories/sub_categories"
exports.createSheduleLiveEndpoint = "bx_block_livestreaming/live_streams"
exports.disclaimerEndpoint = "bx_block_livestreaming/live_streams/get_disclaimer"
exports.save = "Save"
exports.share = "Share" 
exports.moderators = "Moderators"
exports.collaborators = "Collaborators "
exports.sampleName = "RohitChandra54"
exports.sampleName2 ="VikramAditi77"
exports.invited = "Invited"
exports.validateMessageForImage = "Image size should be less than or equal to 5MB"
exports.validateMessageForVideo = "Video size should be less than or equal to 5MB"
exports.sucessMessage = "Successfully Scheduled"
exports.sucessMessageForLive = "Successfully  Added"
exports.sucessDescription = "Your live event is set successfully"
exports.testMode = "Test Mode"

exports.switchTitles = {
  explicitContent: "Explicit Content",
  muteWords: "Mute Words",
};

exports.switchDescriptions = {
  explicitContent: "Turn this on if you stream contains explicit content.",
  muteWords: "Turn this on add word you like to mute from your show’s chat.",
  privateEvent: "Is this a private live event?",
}

exports.privateLiveEventModalTxt = {
  title: "Invite buyers",
  desc: "invitations won't be sent because this is a test live session designed for educational purposes",
  searchPlaceholder: "Search for buyers"
};

exports.continueTxt = "Continue";
exports.inviteTxt = "Invite";
exports.skipTxt = "Skip";
exports.doneTxt = "Done";
exports.inventoryTxt = "Inventory";
exports.inventoryModalTitleTxt = "Add quantity of products";
exports.inventoryModalSubTitleTxt = "Select the quantity of products to be added to your live stream."
exports.addToLive = "Add to Live";
exports.inventorySearchPlacholder = "Search for products";
exports.successfullyAdded = "Successfully Added";
exports.successfullyAddedDesc = "Products have been successfully added to Live inventory";
exports.goLiveTitleTxt = "Select Your Live Streaming Option";
exports.goLiveSubTitleTxt = "Almost there! Before you go live, please choose one of the two streaming options below for a smooth broadcasting experience.";
exports.goLiveWithAgoraTxt = "Go Live with Agora";
exports.goLiveWithWebRTC = "Go Live with WebRTC";
exports.goLiveBtnTxt = "Go live"
exports.getLiveStreamingInventoryEndPoint = "/bx_block_catalogue/catalogues/get_seller_catalogues?for_livestream=true"
exports.addToLiveEndPont = "bx_block_livestreaming/live_streams/add_catalogues"
exports.liveStreamHostTokenEndPoint = "bx_block_livestreaming/live_streams/generate_host_token?id="
exports.livePreview = "Live Preview"
exports.live = "Live"
exports.liveCount = "12k"
exports.liveTime = "15:00 "
exports.title = "Title"
exports.price = "Price"
exports.customer = "Customer"
exports.liveInfo = "Live Info"
exports.liveStreamId = "Live stream 02 #"
exports.liveInfoDesc = "We are selling 120 items today join soon and get it for best price and quality..."
exports.cameraHash = "#Camera"
exports.nikonHash = "#Nikon"
exports.noChat = "Cant see chats on test mode"
exports.liveChatPlaceholder = "Type your message "
exports.endLiveStreamModalTitle = "Are you sure you want to end this live?"
exports.endLiveStreamModalDesc = "Please ensure you've saved any important information before proceeding."
exports.cancel = "Cancel"
exports.endStream = "End Stream"
exports.livePurchasedItemName = "Headphone-av2"
exports.livePurchasedItemCustomer = "Item purchased by rounakk "
exports.noInventoryTxt = "No inventory found."


exports.tagPeople = "Tag people"
exports.addProducts = "Add product"
exports.storySwitchTitles = {
  explicitContent: "Activate messaging",
  muteWords: "Turn this on if you want to get messages from people regarding this product.",
};
exports.uploadStoryTxt = "Upload Story";
exports.storyUploaded = "Your story has been uploaded successfully";
exports.clearAllCheck = "Clear all check";
exports.addVariant = "Add Variant";
exports.selectProduct = "Select the variant related to the Story";
exports.storyUploadedTitle = "Story uploaded";
exports.wentWrong = "Something went wrong";
exports.getInventoryEndPoint = "bx_block_catalogue/catalogues/get_seller_catalogues";
exports.createStoryEndPoint = "bx_block_posts/stories";
exports.searchEndPoint = "bx_block_posts/posts/tag_list?role=all&search={userName}"
exports.showVideoWarning = "The uploaded video is {duration} seconds long and will be sliced into multiple stories of a maximum size of 30 seconds each.";

// Customizable Area End