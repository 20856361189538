import React, { Component } from 'react';
import {
Box,
Avatar,
Typography,
Button,
Modal,
IconButton,
styled
} from "@material-ui/core";
import { heartButton, muteButton, profileImg, redHeartButton, rightArrow, unMuteButton, leftArrow } from '../../blocks/landingpage/src/assets';
import CloseIcon from '@material-ui/icons/Close';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import Stories from 'react-insta-stories';
import SwiperCore,{ Navigation } from 'swiper';

interface StoryListingProps {
storyMuted: boolean;
toggleMute : ()=> void;
calculateHours : (createdAt: string) => string;
likeStory : (storyId : number) => void;
instaModal: boolean;
handleClose: () => void;
allStoryHeader: Array<StoryUploaders>;
instaModalIndex: number;
currentStoryIndex : number;
userStory : Array<StoryUploaders>;
handleNext: (userIndex: number, direct?: boolean, event?: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => void;
handlePrev: (userIndex: number, direct?: boolean, event?: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => void;
handlePrevNext?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, userIndex:number)=>void;
changeStory: (direction:"next"|"prev") => void;
showPrevButton: (userIndex: number) => boolean;
showNextButton: (userIndex: number) => boolean;
"data-test-id": string;
setSwiperRef : (swiper: SwiperCore) => void;
}

interface StoryUploaders {
    id: string;
    type: string;
    attributes: StoryAttributes;
}  

interface StoryAttributes {
    id: number;
      title: string;
      description: string;
      duration: number; 
      seen: boolean;
      location: string | null; 
      color: string | null; 
      activated: boolean;
      created_at: string; 
      updated_at: string; 
      creator_details: CreatorDetails;
      media: StoryMedia;
      duration_with_seconds: string;
      liked: boolean;
}

interface CreatorDetails {
    id: number;
    full_name: string;
    profile_url: string | null;
  }
  
  interface StoryMedia {
      id: number;
      filename: string;
      url: string;
      type: string;
}

interface State {
}

class StoryListing extends Component<StoryListingProps, State> {
    constructor(props: StoryListingProps) {
        super(props);
    }
    
    renderCommentBox = (item: StoryUploaders) => {
        return (
          <div className="commentBox" >
            <div className="reply-container">
              <input
                type="text"
                className="reply-input"
                placeholder={`Reply to ${item.attributes.creator_details.full_name}`}
              />
            </div>
            <div className="heart-icon" data-test-id = "likeBtn" onClick={() => { this.props.likeStory(item.attributes.id) }}>
              <img src={item.attributes.liked ? redHeartButton : heartButton} alt="heart_button" />
            </div>
          </div>
        )
      };
      
      renderStoryDetail = (item: StoryUploaders) => {
        return (
          <div className="mailInstaDiv" >
    
            <div className="username">
              <div>
                <Avatar alt="Remy Sharp" src={item.attributes.creator_details.profile_url ? item.attributes.creator_details.profile_url : profileImg} />
              </div>
              <div className="innerDiv">
                <div>
                  <span className="userNameSpan"
                  >{item.attributes.creator_details.full_name}</span>
                </div>
                <div>
                  <span className="hoursSpan">{this.props.calculateHours(item.attributes.created_at)}</span>
                </div>
              </div>
            </div>
            <div onClick={this.props.toggleMute} className="muteBox" >
             {item.attributes.media.type!=="image" && <img src={this.props.storyMuted ? unMuteButton : muteButton} alt="mute_button" />}
            </div>
          </div>)
      };

    render() {
        return (
            <MainBox>
            <InstaDialogModal
              open={this.props.instaModal}
              onClose={this.props.handleClose}
              disablePortal
              disableEnforceFocus
              disableAutoFocus
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="mainOfDialog"
            >
              <SwiperModal>
                <IconButton className="closeIcon" data-test-id={"closeStoryTestID"} onClick={this.props.handleClose}>
                  <CloseIcon />
                </IconButton>
                <Swiper
                  data-test-id="swiperTestId"
                  spaceBetween={30}
                  slidesPerView={3}
                  initialSlide={this.props.instaModalIndex}
                  navigation={false}
                  centeredSlides={true}
                  onSwiper={this.props.setSwiperRef}
                  modules={[Navigation]}
                  className="swiper_test"
                >
                  {this.props.allStoryHeader.map((item, userIndex) => (
                    <SwiperSlide>
                      <InstaModal>
                        <div className="storyBox">
                          <Stories
                            data-test-id="instaStory"
                            stories={this.props.userStory.map((item) => (
                              item.attributes.media.type == "image" ? {
                                content: () =>
                                  <div className="stroyDataBox">
                                    <img src={item.attributes.media.url}></img>
                                    {this.renderStoryDetail(item)}
                                    {this.renderCommentBox(item)}
                                  </div>
                                ,
                                duration: 5000
                              } :
                                {
                                  content: () => (<div className="stroyDataBox">
                                    <video src={item.attributes.media.url}
                                      autoPlay
                                      muted={this.props.storyMuted}
                                      playsInline />
                                    {this.renderStoryDetail(item)}
                                    {this.renderCommentBox(item)}
                                  </div>),
                                  duration: item.attributes.duration * 1000
                                }
                            ))}
                            defaultInterval={5000}
                            currentIndex={this.props.currentStoryIndex}
                            onStoryEnd={() => {
                              this.props.changeStory("next")
                            }
                            }
                            width={"100%"}
                            height={"100%"}
                            progressStyles={{ height: "3px" }}
                          />
                          <Button className={this.props.showPrevButton(userIndex)?"prevStoryButton":"hiddenPrevBtn"} data-test-id={`prevStoryTestID${userIndex}`}
                            onClick={(event) => this.props.handlePrev(userIndex, false, event)}>
                            <img src={rightArrow} />
                          </Button>
                          {this.props.showNextButton(userIndex) && <Button className="nextStoryButton" data-test-id={`nextStoryTestID${userIndex}`}
                            onClick={(event) => this.props.handleNext(userIndex, false, event)}>
                            <img src={leftArrow} />
                          </Button>}
                        </div>
                      </InstaModal>
                    </SwiperSlide>
                  ))}

                </Swiper>
              </SwiperModal>
            </InstaDialogModal>
            </MainBox>
        );
    }
}

const MainBox = styled(Box)({
  display: "flex",
  padding: "0 20px 0 0",
  overflowX: "auto",
  "& .feedBtn": {
    margin: "0 40px 0 0",
    cursor: "pointer",
  }
});

const InstaDialogModal = styled(Modal)({
  height: "100vh"
});

const SwiperModal = styled("div")({
  background: "rgba(0,0,0,0.7)",
  padding: "50px",
  height: "100vh",
  boxSizing: "border-box",
  position: "relative",
  "& .closeIcon": {
    position: "absolute",
    zIndex: 1000,
    top: "20px",
    right: "20px",
    background: "white",
    "& .MuiSvgIcon-root": {
      fill: "grey"
    }
  },
  "& .swiper": {
    display: 'flex',
    flexWrap: 'wrap',
    height: "100%",
    boxSizing: "border-box",
    "& .swiper-wrapper": {
      alignItems: "center",
      "& .swiper-slide-prev": {
        transform: 'scale(0.6)',
        transition: "transform 1s"
      },
      "& .swiper-slide-next": {
        transform: 'scale(0.6)',
        transition: "transform 1s"
      },
      "& .swiper-slide-active": {
        transform: 'scale(1)',
        transition: "transform 1s",
        width: '100%',
        marginRight: '50px',
        maxWidth: '432px',
        "& .storyBox": {
          "& > *:nth-child(1)": {
            display: "flex !important",
            "& > *:nth-child(2)": {
              borderTopRightRadius: "10px",
              borderTopLeftRadius: "10px"
            }
          },
          "&:hover": {
            "& .nextStoryButton": {
              display: "block"
            },
            "& .prevStoryButton": {
              display: "block"
            }
          }
        },
        "& .mailInstaDiv": {
          display: "flex !important"
        },
        "& .commentBox": {
          display: "flex !important"
        },
      }
    },
    "& .swiper-button-next": {
      position: 'absolute',
      top: '48%',
      left: '57%',
      color: 'black',
      background: 'white',
      borderRadius: '100%',
      boxShadow: '2px 2px 2px gray',
      padding: '29px 30px'
    },
    "& .swiper-button-prev": {
      position: 'absolute',
      top: '48%',
      left: '32.8%',
      color: 'black',
      background: 'white',
      borderRadius: '100%',
      boxShadow: '2px 2px 2px gray',
      padding: '29px 30px'
    }
  }
});

const InstaModal = styled("div")({
  position: 'relative',
  height: "100%",
  aspectRatio: "406/770",
  boxSizing: "border-box",
  borderRadius: "10px",
  "& .storyBox": {
    width: "100%",
    height: "100%",
    borderRadius: "10px",
    position: "relative",
    "& > *:nth-child(1)": {
      borderRadius: "10px",
      height: "100%",
      display: "none !important",
      "& > *:nth-child(2)": {
        borderRadius: "10px"
      },
    },
    "& .redHeartButton": {
      height: "40px",
      width: "38px",
      transition: "width 1s"
    }
  },
  "& .thumbnailVid": {
    objectFit: "cover"
  },
  "& .stroyDataBox": {
    background: "black",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    "& img": {
      display: "block",
      borderRadius: 4,
      width: "100%",
      height: "100%",
      objectFit: "scale-down"
    },
    "& video": {
      width: "100%"
    }
  },
  "& .nextStoryButton": {
    display: "none",
    position: "absolute",
    "& img": {
      borderRadius: "50%",
      boxShadow: '2px 2px 2px gray'
    },
    top: "50%",
    zIndex: 1000,
    right: -30
  },
  "& .prevStoryButton": {
    display: "none",
    position: "absolute",
    top: "50%",
    zIndex: 1000,
    left: -30,
    "& img": {
      borderRadius: "50%",
      boxShadow: "2px 2px 2px gray"
    }
  },
  "& .hiddenPrevBtn": {
    display: "none"
  },
  "& .mailInstaDiv": {
    display: 'none',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'absolute',
    top: '20px',
    width: '100%',
    padding: '0 20px 0 5px',
    maxWidth: '432px',
    "& .muteBox": {
      zIndex: 1000
    }
  },
  "& .username": {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    padding: '5px 19px'
  },
  "& .innerDiv": {
    display: 'flex',
    gap: '10px'
  },
  "& .userNameSpan": {
    fontSize: '12px',
    color: '#FFFFFF',
    fontWeight: 700
  },
  "& .hoursSpan": {
    fontSize: '12px',
    color: '#FFFFFF',
    fontWeight: 700,
  },
  "& .reply-container": {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#1a1a1a',
    borderRadius: '30px',
    padding: '5px 10px',
    boxSizing: "border-box",
    flex: 1,
    opacity: 0.5,
    border: '2px solid #FFFFFF'
  },
  "& .reply-input": {
    flexGrow: 1,
    border: 'none',
    outline: 'none',
    background: 'none',
    color: 'white',
    fontSize: '16px',
    padding: "0px !important",
    width: '70%'
  },
  "& .heart-icon": {
    color: 'white',
    fontSize: '20px',
    cursor: 'pointer',
    zIndex: 1000
  },
  "& .commentBox": {
    padding: "0px 20px",
    zIndex: 1000,
    width: "100%",
    justifyContent: "space-between",
    gap: 10,
    alignItems: "center",
    display: "none",
    boxSizing: "border-box",
    flexWrap: 'wrap',
    position: "absolute",
    bottom: 20
  },
  "& .leftIcon": {
    position: 'absolute',
    top: '400px',
    right: '-30px',
    padding: '0px 30px',
    zIndex: 100000
  },
  "& .storyBorder": {
    width: "94px",
    height: "94px",
    borderRadius: "50%",
    padding: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(to bottom, #EB3AA7 0%, #6649D9 100%)"
  },
  "& .story": {
    borderRadius: "50%",
    width: "100% !important",
    height: "100% !important"
  },
  "& .userData": {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    color: "white",
    fontFamily: "Poppins"
  },
  "& .userHour": {
    fontWeight: 500,
    fontSize: "14px",
    color: "white",
    lineHeight: "24px",
    fontFamily: "Poppins"
    }
  });

export default StoryListing;
