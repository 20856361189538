import React, { Component } from 'react';
import {
    Box,
    styled,
    Typography,
    Avatar,
    Button
} from "@material-ui/core";
import { commentIcon, heartIcon, shareBtn, userAdd } from './assets';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import FavoriteIcon from '@material-ui/icons/Favorite';

interface ImageOrVideo {
    id: number;
    filename: string;
    url: string;
    type: string;
}

interface CreatorDetails {
    id: number;
    full_name: string;
    profile_url: string | null;
}

interface PostAttributes {
    id: number;
    name: string | null;
    description: string | null;
    body: string;
    location: string | null;
    account_id: number;
    active_message: boolean;
    created_at: string;
    updated_at: string;
    images_and_videos: ImageOrVideo[];
    creator_details: CreatorDetails;
    like_count: number;
    is_liked: boolean;
    comment_count: number;
    is_following_seller: boolean;
    last_liked_by: null | string
}

interface PostDataPayload {
    id: string;
    type: string;
    attributes: PostAttributes;
}

interface PostItemProps {
    item: PostDataPayload;
    postId: string;
    postKeyId: number;
    handleToggleLikePost: (postId: string) => void;
    handleToggleComment: (postId: string,commentbox:string) => void;
    clickFollowButton?: (creatorId: number,postId: string) => void;
    handlePostShareToggle: (postId: string) => void;
}

interface State {
    currentProductIndex: number;
}

class PostCard extends Component<PostItemProps, State> {
    constructor(props: PostItemProps) {
        super(props);
    }

    render() {
        const { item, handleToggleLikePost, postId, handleToggleComment, clickFollowButton = ()=>{}, handlePostShareToggle } = this.props;
        return (
            <PostCardBox>
                <Box className="postCardBox" >
                    <Box className="postCardHeaderBox">
                        <Box className="headerLeftBox">
                            <Box className="avatarBox">
                                <Avatar src={item.attributes.creator_details.profile_url || ''} alt={item.attributes.creator_details.full_name} />
                                {console.log(item.attributes,'check creator details')}
                                
                            </Box>
                            <Box className="userInfoBox">
                                <Typography className="userNameTxt">{item.attributes.creator_details.full_name}<span className="userTypeTxt">Seller</span></Typography>
                                <Typography className="postedTimeTxt">{item.attributes.created_at}</Typography>
                            </Box>
                        </Box>
                        <Box className="headerRightBox">
                            {item.attributes.is_following_seller ?
                                <Box className="followBox">
                                    <Button onClick={() => clickFollowButton(item.attributes.creator_details.id, postId)} className="followUnfollowBtn">Following</Button>
                                </Box> :
                                <img src={userAdd} onClick={() => clickFollowButton(item.attributes.creator_details.id, postId)} />}
                        </Box>
                    </Box>

                    <Box>
                        <Box className="postImageBox">
                            {item?.attributes?.images_and_videos?.length === 1 ? (
                                <img src={item?.attributes?.images_and_videos[0]?.url} className="postImage" />
                            ) : (
                                <Carousel
                                    showThumbs={false}
                                    swipeable={true}
                                    renderArrowNext={(onClickHandler, hasNext, label) =>
                                        hasNext && (
                                            <button
                                                type="button"
                                                onClick={onClickHandler}
                                                title={label}
                                                className='nextArrowBtn'
                                            >
                                                <ArrowForwardIos className="postSlideBtn"/>
                                            </button>
                                        )
                                    }
                                    renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                        hasPrev && (
                                            <button
                                                type="button"
                                                onClick={onClickHandler}
                                                title={label}
                                                className="prevArrowBtn"
                                            >
                                                <ArrowBackIos className="postSlideBtn"/>
                                            </button>
                                        )
                                    }
                                >
                                    {item?.attributes?.images_and_videos.map((image: { url: string }, index: number) => (
                                        <Box key={index} className="imageContainer">
                                            <img src={image.url} className="postImage" />
                                        </Box>
                                    ))}
                                </Carousel>
                            )}
                        </Box>

                        <Box className="postBottomBox">
                            <Box className="likeCommentBox">
                               <Box className="likeCmtInnerBox">{item.attributes.is_liked ? <FavoriteIcon className="liked" onClick={() => handleToggleLikePost(postId)}/> : <img src={heartIcon} className={`heartIcon`} onClick={() => handleToggleLikePost(postId)} /> }<span>{item.attributes.like_count > 0 && item.attributes.like_count}</span></Box>
                                <Box className="likeCmtInnerBox" onClick={() => handleToggleComment(postId,"commentbox")}>
                                    <img src={commentIcon} className="commentIcon" /><span>{item.attributes.comment_count > 0 && item.attributes.comment_count}</span>
                                </Box>
                                <Box className="shareBox" onClick={()=> handlePostShareToggle(postId)}>
                                    <img src={shareBtn} className="shareIcon"/>
                                </Box>
                            </Box>
                            <Box className="likedByDetailBox">
                                <Avatar src={''} alt="Person" className="likedByImg" /> <Typography className="likedInfoTxt"> Liked by <span className="boldText" >craig_love</span> and <span className="boldText" >44,686 others</span></Typography>
                            </Box>
                            <Box className="descriptionBox">
                                <Typography className="descriptionTxt">
                                    <span className="boldText">{item.attributes.creator_details.full_name}</span> {item.attributes.body}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </PostCardBox>
        );
    }
}

const PostCardBox = styled(Box)({
    "& .postMainContainer": {
        height: "532px",
        overflowY: "auto",
        scrollBehavior: "smooth",
    },
    "& .postContainer": {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    "& .postHeaderBox": {
        width: "100%",
        height: "100px",
        "@media(max-width:1220px)": {
            padding: "10px",
        },
    },
    "& .postCardBox": {
        maxWidth: "510px",
        width: "100%",
        marginBottom: "46px",
        "@media(max-width:1220px)": {
            padding: "10px",
        },
        minWidth:"510px",
        "@media(max-width:1200px)": {
            minWidth:"410px",
        },
        "@media(max-width:410px)":{
            width:"100%",
            minWidth:"0px"
        }
    },
    "& .postCardHeaderBox": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    "& .headerLeftBox": {
        display: "flex",
        gap: "20px",
        justifyContent: "center",
        alignItems: "center"
    },
    "& .userNameTxt": {
        fontSize: "14px",
        fontWeight: 600,
        color: "#444444"
    },
    "& .postedTimeTxt": {
        color: "#444444B2",
        fontSize: "12px",
        fontWeight: 400,
    },
    "& .postImageBox": {
        marginTop: "16px",
        maxWidth: "510px",
        width: "100%",
        maxHeight: "508px",
        borderRadius: "10px",
    },
    "& .imageContainer":{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        height:"100%"
    },
    "& .postImage": {
        width: "100%",
        maxHeight: "508px",
        maxWidth: "510px",
        objectFit: "contain",
        borderRadius: "10px",
    },
    "& .postBottomBox": {
        marginTop: "12px",
        wordBreak:"break-all"
    },
    "& .likeCommentBox":{
        display:"flex"
    },
    "& .likeCmtInnerBox":{
        display:"flex",
        gap:"8px"
    },
    "& .liked":{
        color:"red",
    },
    "& .commentIcon":{
        marginLeft:"14px",
        height:"24px",
        width: "24px"
    },
    "& .shareIcon":{
        marginLeft:"14px",
        height:"20px",
        width: "20px",
        marginTop:"1px"
    },
    "& .likedByImg": {
        width: "17px",
        height: "17px",
        marginRight: "16px"
    },
    "& .likedByDetailBox": {
        margin: "8px 0px",
        display: "flex"
    },
    "& .boldText": {
        fontSize: "14px",
        fontWeight: 600,
        color: "#444444"
    },
    "& .likedInfoTxt": {
        fontSize: "14px",
        fontWeight: 400,
        color: "#444444"
    },
    "& .descriptionTxt": {
        fontSize: "14px",
        fontWeight: 400,
        color: "#444444"
    },
    "& .userTypeTxt": {
        marginLeft: "10px",
        fontWeight: 500,
        fontSize: "12px",
        color: "#444444CC"
    },
    "& .nextArrowBtn":{
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        background: 'rgba(0, 0, 0, 0.5)',
        borderRadius: '50%',
        color: '#fff',
        border: 'none',
        cursor: 'pointer',
        zIndex: 2,
        padding: '4px',
        right:"15px"
    },
    "& .prevArrowBtn":{
        top: '50%',
        transform: 'translateY(-50%)',
        position: 'absolute',
        borderRadius: '50%',
        background: 'rgba(0, 0, 0, 0.5)',
        border: 'none',
        color: '#fff',
        zIndex: 2,
        cursor: 'pointer',
        left:"15px",
        padding: '4px',
    },
    "& .postSlideBtn":{
        width:"20px",
        height:"20px"
    },
    "& .followUnfollowBtn": {
        textTransform: "none",
        border: "1px solid #F5EA16",
        borderRadius: "50px",
        padding: "6px 16px"
    },
})

export default PostCard;
