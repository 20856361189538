Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.PostAPiMethod = "POST";
exports.exampleApiContentType = "application/json";

exports.PostApiMethodType = "GET";
exports.patchPostAPiMethod = "PATCH";
exports.postGetUrl = "posts/posts";
exports.postContentType = "application/json";
exports.deletePostAPiMethod = "DELETE"
exports.CategoryGetUrl = "categories/categories";
exports.btnExampleTitle = "CLICK ME";
exports.getAllCatergoryEndPoint = "categories/categories";
exports.postPostCreationEndPoint = "bx_block_posts/posts";
exports.getInventoryEndPoint = "bx_block_catalogue/catalogues/get_seller_catalogues";
exports.getTagUserEndPoint = "bx_block_livestreaming/live_streams/invite_users_list?role=seller";
exports.getPostListingDataEndPoint = "bx_block_posts/posts";
exports.postLikeUnlikeEndPoint = "bx_block_like/likes";
exports.searchTagUserApiEndPoint = "bx_block_posts/posts/tag_list";

exports.postCommentEndPoint = "bx_block_comments/comments/comment_on_post";
exports.getPostCommentListEndPoint = "bx_block_comments/comments/list_of_post_comments";
exports.postCommentOnCommentEndPoint = "bx_block_comments/comments/comment_on_post_comment";
exports.likeCommentEndPoint = "bx_block_like/likes/like_dislike_post_comments";

exports.categoriesTxt = "Categories";
exports.feedTxt = "Feed";
exports.getAllStoriesEndPoint = "bx_block_posts/stories";
exports.viewStoryEndPoint = "bx_block_posts/stories/user_stories?user_id={userId}";

exports.followSellar = "bx_block_followers/follows";
exports.getFollowSellarListEndPoint = "bx_block_followers/follows";
exports.unFollowSellarEndPoint = "bx_block_followers/follows";
exports.facebookTxt="Facebook";
exports.whatsappTxt="Whatsapp";
exports.telegramTxt="Telegram";
exports.copyLink="Copy Link";
exports.shareTxt="Share To";
exports.instagramTxt="Instagram";
exports.postTxt="POST";
exports.commentTxt="Comments";
// Customizable Area End