export const sliderImg1 = require("../assets/sliderImg1.svg");
export const sliderImg2 = require("../assets/sliderImg2.svg");
export const sliderImg3 = require("../assets/sliderImg3.svg");
export const sliderImg4 = require("../assets/sliderImg4.svg");
export const sliderImg5 = require("../assets/sliderImg5.svg");
export const sliderImg6 = require("../assets/sliderImg6.svg");
export const circularImg1 = require("../assets/circularImg1.svg");
export const circularImg2 = require("../assets/circularImg2.svg");
export const circularImg3 = require("../assets/circularImg3.svg");
export const circularImg4 = require("../assets/circularImg4.svg");
export const circularImg5 = require("../assets/circularImg5.svg");
export const circularImg6 = require("../assets/circularImg6.svg");
export const sliderUserProfile = require("../assets/sliderUserProfile.svg");
export const shose = require("../assets/shose.svg");
export const coins = require("../assets/coins.svg");
export const oval = require("../assets/oval.svg");
export const save = require("../assets/save.svg");
export const rectangle = require("../assets/rectangle.svg");
export const radar = require("../assets/radar.svg");
export const arrowNext = require("../assets/arrowNext.svg");
export const arrowPrev = require("../assets/arrowPrev.svg");
export const Star = require("../assets/star.svg");
export const searchBar = require("../assets/searchBar.svg");
export const faceBook = require("../assets/faceBook.svg");
export const buyNowLive = require("../assets/buyNowLive.svg");
export const weAreImg1 = require("../assets/weAreImg1.svg");
export const weAreImg2 = require("../assets/weAreImg2.svg");
export const weAreImg3 = require("../assets/weAreImg3.svg");
export const weAreImg4 = require("../assets/weAreImg4.svg");
export const bottomImg = require("../assets/bottomImg.svg");
export const categ1 = require("../assets/categ1.svg");
export const creater = require("../assets/creater.svg");
export const About1 = require("../assets/About1.svg");
export const About2 = require("../assets/About2.svg");
export const verctorBlack = require("../assets/verctorBlack.svg");
export const vectorTransparent = require("../assets/vectorTransparent.svg");
export const frameBanner1 = require("../assets/frameBanner1.svg");
export const frameBanner2 = require("../assets/frameBanner2.svg");
export const bannerIcon1 = require("../assets/bannerIcon1.svg");
export const bannerIcon2 = require("../assets/bannerIcon2.svg");
export const bannerIcon3 = require("../assets/bannerIcon3.svg");

export const closeIc = require("../assets/close.svg");
export const dashboardIc = require("../assets/dashboard.svg");
export const dashboardGrayIc = require("../assets/dashboard.svg");
export const createIc = require("../assets/create.svg");
export const createGrayIc = require("../assets/create-gray.svg");
export const feedIc = require("../assets/feed.svg");
export const feedGrayIc = require("../assets/feed-gray.svg");
export const ordersIc = require("../assets/orders.svg");
export const ordersGrayIc = require("../assets/orders-gray.svg");
export const inventoryIc = require("../assets/inventory.svg");
export const inventoryGrayIc = require("../assets/inventory-gray.svg");
export const messageIc = require("../assets/message.svg");
export const messageGrayIc = require("../assets/message-gray.svg");
export const paymentIc = require("../assets/payments.svg");
export const paymentGrayIc = require("../assets/payments-gray.svg");
export const settingIc = require("../assets/setting.svg");
export const settingGrayIc = require("../assets/setting-gray.svg");
export const searchIc = require("../assets/search-black.svg");
export const calendarIc = require("../assets/calendar.svg");
export const notificationIc = require("../assets/notification.svg");
export const notificationRedDotIc = require("../assets/notification-red-dot.svg");
export const hamburgerIc = require("../assets/hamburger.svg");
export const clockYellow = require("../assets/clock-yellow-img.png");
export const progressStep1 = require("../assets/progress-step1.png");
export const progressStep2 = require("../assets/progress-step2.png");
export const progressStep3 = require("../assets/progress-step3.png");
export const progressStep4 = require("../assets/progress-step4.png");
export const videoPlaceholder = require("../assets/video-placeholder-img.png");
export const rightArrowProgress = require("../assets/progress-right-arrow.svg");
export const PlayIcBlack = require("../assets/video-circle-black.svg");
export const MessageQuesIc = require("../assets/message-question.svg");
export const PlayIcWhite = require("../assets/video-circle-white.svg");
export const clockIc = require("../assets/clock.svg");
export const downArrowIc = require("../assets/down-arrow.svg");
export const congratulations = require("../assets/congratulations-img.png");
export const arrowDown = require("../assets/arrowDown.svg");
export const add = require("../assets/add.svg");
export const live = require("../assets/live.svg");
export const story1 = require("../assets/story1.svg");
export const story2 = require("../assets/story2.svg");
export const story3 = require("../assets/story3.svg");
export const story4 = require("../assets/story4.svg");
export const story5 = require("../assets/story5.svg");
export const story6 = require("../assets/story6.svg");
export const story7 = require("../assets/story7.svg");
export const liveBg1 = require("../assets/liveBg1.svg");
export const liveBg2 = require("../assets/liveBg2.svg");
export const liveBg3 = require("../assets/liveBg3.svg");
export const liveUser = require("../assets/liveUser.svg");
export const liveRadar = require("../assets/liveRadar.svg");
export const liveArchive = require("../assets/liveArchive.svg");
export const liveArchiveBlank = require("../assets/liveArchiveBlank.svg");
export const contentCreator1 = require("../assets/contentCreator1.svg");
export const contentCreator2 = require("../assets/contentCreator2.svg");
export const crown = require("../assets/crown.svg");
export const rectangleTag = require("../assets/rectangleTag.svg");
export const unarchive1 = require("../assets/unarchive1.svg");
export const unarchive2 = require("../assets/unarchive2.svg");
export const topSelling1 = require("../assets/topSelling1.svg");
export const topSelling2 = require("../assets/topSelling2.svg");
export const topSelling3 = require("../assets/topSelling3.svg");
export const topSelling4 = require("../assets/topSelling4.svg");
export const topSelling5 = require("../assets/topSelling5.svg");
export const onOrder1 = require("../assets/onOrder1.svg");
export const onOrder2 = require("../assets/onOrder2.svg");
export const onOrder3 = require("../assets/onOrder3.svg");
export const onOrder4 = require("../assets/onOrder4.svg");
export const close = require("../assets/close.svg");
export const hamburger = require("../assets/hamburger.svg");
export const chooseCategories = require("../assets/chooseCategories.svg");
export const checkbox = require("../assets/checkbox .svg");
export const businessAnouce = require("../assets/business-anouncment.svg");
export const busineessPattern = require("../assets/business-pattern.png");
export const imgFilter = require("../assets/setting-4.png");
export const imgConfetti = require("../assets/confetti.png");
export const imgGridBtn = require("../assets/gridList.png");
export const noDataFound = require("../assets/noDataFound.jpg");
export const whiteAppLogo = require("../assets/whiteAppLogo.svg");
export const filterImage = require("../assets/filterImage.png");
export const faqbannerImage = require("../assets/faq_cover_img.jpeg");
export const whiteSearchBar = require("../assets/whiteSearchBar.png");
export const noLiveEventsFound = require("../assets/noLiveEventsFound.jpeg");
export const noRecord = require("../assets/noRecord.png");
export const uploadIcon = require("../assets/uploadMedia.svg");
export const cancelIcon = require("../assets/CrossIcon.svg");
export const checkIcon = require("../assets/checkIcon.svg");

export const muteButton = require("../assets/Group1.png");
export const unMuteButton = require("../assets/volume-high.svg");
export const heartButton = require("../assets/Vector.svg");
export const redHeartButton = require("../assets/redHeart.svg");
export const leftArrow = require("../assets/left_icon.png");
export const rightArrow = require("../assets/right_icon.png");
export const profileImg =  require("../assets/profile_icon.png");