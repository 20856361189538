import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
interface ProfilePercentagRes {
    percentage: number,
    missing_details: string[]
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    isOpen?: boolean;
    closeModal?: any;
    activetabName?: string
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    whatWeAreData: any;
    performanceCounterData: any;
    aboutPlateformData: any;
    categoriesData: any;
    meetTheCreatersData: any;
    landingPageBannerData: any;
    bottomImage:any;
    isVisibleModal: boolean;
    isOpen: boolean;
    modalOpen: boolean;
    displaySection: string;
    videoTutorial: any;
    selectedVideo: string;
    faqData: any;
    openModalVideoPlayer: boolean
    analyticsData: any;
    sellerEmailVerified: boolean;
    sellerPhoneVerified: boolean;
    sellerAdminVerified: boolean;
    sellerBusinessPopup: boolean;
    sellerActivities: boolean;
    sellerBusinessData: any;
    sellerAddress: any;
    sellerBankDetail: any;
    sellerProfileName: string;
    sellerBusinessName: string;
    sellerProfilePercentage: number;
    sellerProfileRedirect: string;
    videoTitleWrapText: string;
    generalFaqData: any;
    generalAboutData: any;
    generalPrivacyData: any;
    generalTermsData: any;
    openTunedBox: boolean;
    isNotificationBoxOpen: boolean;
    allNotifications: any;
    readNotificatons: any;
    openNotificationBox: boolean
    selectedTab: number;
    DynamicSellerBanner: any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class LandingPageControllerWeb extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiGetWhatWeAre: any;
    apiGetPerformanceCounter: any;
    apiAboutPlateform: any;
    apiGetCategories: any;
    apiMeetTheCreaters: any;
    apiLandingPageBanner: any;
    apiBottomImage: any;
    apiGetVideoTutorial: any;
    apiGetSellerFaq: any;
    apiGetSellerAnalytics: any;
    apiGetSellerProfileData: any;
    apiUpdateBusinessPopup: any;
    apiGetGeneralFaq: any;
    apiGetAboutData: any;
    apiGetPrivacyData: any;
    apiGetTermsData: any;
    getAllNotifiction: any;
    readAllNotification: any;
    apiSellerSideBanner: any;
    getSellerProfilePercentage: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.getAllNotification = this.getAllNotification.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            whatWeAreData: [],
            performanceCounterData: '',
            aboutPlateformData: [],
            categoriesData: [],
            meetTheCreatersData: [],
            landingPageBannerData: [],
            bottomImage: [],
            isVisibleModal: false,
            isOpen: true,
            modalOpen: false,
            displaySection: '',
            videoTutorial: [],
            selectedVideo: "",
            faqData: [],
            openModalVideoPlayer: false,
            analyticsData: [],
            sellerEmailVerified: false,
            sellerPhoneVerified: false,
            sellerAdminVerified: false,
            sellerBusinessPopup: false,
            sellerActivities: false,
            sellerBusinessData: null,
            sellerAddress: null,
            sellerBankDetail: null,
            sellerProfileName: '',
            sellerBusinessName: '',
            sellerProfilePercentage: 0,
            sellerProfileRedirect: '/BankingDetails',
            generalFaqData: [],
            generalAboutData: null,
            videoTitleWrapText: '',
            generalPrivacyData: null,
            generalTermsData: null,
            openTunedBox: false,
            isNotificationBoxOpen: false,
            allNotifications: [],
            DynamicSellerBanner: [],
            readNotificatons:[],
            openNotificationBox: false,
            selectedTab: 1,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    showModal = () => {
        this.setState({ isVisibleModal: true });
    };

    hideModal = () => {
        this.setState({ isVisibleModal: false });
    };

    toggleDrawer = () => {
        this.setState({ isOpen: !this.state.isOpen })
    }

    modalOpen = () => {
        if (this.state.sellerProfilePercentage == 100 && this.state.sellerAdminVerified && !this.state.sellerBusinessPopup) {
            this.setState({ modalOpen: true })
        }
    }

    modalClose = () => {
        this.setState({ modalOpen: false })
        this.updateSellerBusinessPopup();
    }

    clickFaqButton = () => {
        this.setState({ displaySection: 'faq' })
    }

    clickVideoButton = () => {
        this.setState({ displaySection: 'video' })
    }

    videoTitleWrapToggle = (id: string) => {
        this.setState({ videoTitleWrapText: id })
    }


    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

        switch (apiRequestCallId) {
            case this.apiGetWhatWeAre:
                this.setState({
                    whatWeAreData: responseJson.data
                });

                break;

            case this.apiGetPerformanceCounter:
                this.setState({
                    performanceCounterData: responseJson
                });
                break;

            case this.apiAboutPlateform:
                this.setState({
                    aboutPlateformData: responseJson.data
                });
                break;

            case this.apiGetCategories:
                this.setState({
                    categoriesData: responseJson.data
                });
                break;

            case this.apiMeetTheCreaters:
                this.setState({
                    meetTheCreatersData: responseJson.data
                });
                break;

            case this.apiLandingPageBanner:
                this.setState({
                    landingPageBannerData: responseJson.data
                });
                break;

            case this.apiBottomImage:
                this.setState({
                    bottomImage: responseJson.data
                });
                break;

            case this.apiGetVideoTutorial:
                this.setVideoTutorialData(responseJson);
                break;

            case this.apiGetSellerFaq:
                this.setFaqsData(responseJson);
                break;
            case this.apiGetSellerAnalytics:
                this.setAnalyticsData(responseJson);
                break;
            case this.apiGetSellerProfileData:
                this.handleRedirection(responseJson)
                this.setSellerProfileData(responseJson);
                break;
            case this.apiGetGeneralFaq:
                this.setGeneralFaqData(responseJson);
                break;
            case this.apiGetAboutData:
                this.setAboutData(responseJson);
                break;
            case this.apiGetPrivacyData:
                this.setPrivacyData(responseJson);
                break;
            case this.apiGetTermsData:
                this.setTermsData(responseJson);
                break;
            case this.getAllNotifiction:
                this.setState({
                    allNotifications: responseJson.data
                });
                break;
            case this.readAllNotification:
                this.setState({
                    readNotificatons: responseJson
                });
                break;
            case this.apiSellerSideBanner:
                this.setSellerBanner(responseJson);
                break;
            case this.getSellerProfilePercentage:
                this.setSellerProfilePercentage(responseJson);
                break;
        }
        // Customizable Area End
    }


    // Customizable Area Start
    async componentDidMount() {
       await this.getWhatWeAre();
       await this.getPerformanceCoounter();
       await this.getAboutPlateform();
       await this.getCategories();
       await this.getMeetTheCreatters();
       await this.getLandingPageBanner();
       await this.getBottomImage();
       await this.getVideoTutorialData();
       await this.getSellerFaqsData();
       await this.getSellerAnalyticsData();
       await this.getSellerProfileData();
       await this.removeLocalStorageToken();
       await this.getGeneralFaqsData();
       await this.getGeneralAboutData();
       await this.getGeneralPrivacyData();
       await this.getGeneralTermsData();
       await this.getAllNotification();
       await this.getSellerPageBanner();
       await this.getProfilePercentage();
        window.scrollTo(0, 0);
        const headingElement = document.getElementById("myref")
        if (headingElement) headingElement.scrollIntoView({ behavior: 'smooth' })
    }

    getProfilePercentage = async () => {
        this.getSellerProfilePercentage = await this.getSellerProfilePercentageApiCall();
    };

    getSellerProfilePercentageApiCall = async () => {
        let token = await getStorageData("singupLogin");
        const header = {
          "Content-Type": configJSON.validationApiContentType,
          token: token
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getSellerProfilePercentageEndPoint
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    setSellerProfilePercentage = (responseJson: ProfilePercentagRes) => {
        this.setState({sellerProfilePercentage : responseJson.percentage})
    };

    handleImageClick = (videoUrl: string) => {
        this.setState({ selectedVideo: videoUrl });
        this.handleOpenModalVideoPlayer();
    };

    openStayTunedBox = () => {
        this.setState({
            openTunedBox: true
        });
    }
    closeTunedBox = () => {
        this.setState({
            openTunedBox: false
        })
    }

    setVideoTutorialData = (responseJson: any) => {
        if (responseJson && responseJson.data) {
            this.setState({
                videoTutorial: responseJson.data
            });
        }
    }

    setFaqsData = (responseJson: any) => {
        if (responseJson && responseJson.data && responseJson.data.faqs) {
            this.setState({
                faqData: responseJson.data.faqs
            })
        }
    }

    setAnalyticsData = (responseJson: any) => {
        if (responseJson && !responseJson.message) {
            this.setState({
                analyticsData: responseJson
            })
        }
    }

    setSellerProfileData = async (responseJson: any) => {
         setStorageData("profileImage",responseJson.account.data.attributes.profile_photo)
        if (responseJson && responseJson.meta) {
            this.setState({
                sellerEmailVerified: responseJson.meta.email_verified,
                sellerPhoneVerified: responseJson.meta.phone_verified,
                sellerAdminVerified: responseJson.meta.admin_verified,
                sellerBusinessPopup: responseJson.meta.business_popup,
                sellerActivities: responseJson.meta.activities,
            })
            await setStorageData("adminVerified",responseJson.meta.admin_verified);
            if(responseJson.meta.admin_verified){
                await setStorageData("sbUserId",responseJson.meta.sendbird_credential.user_id);
                await setStorageData("sbToken",responseJson.meta.sendbird_credential.access_token);
            }
        }
        if (responseJson && responseJson.business_detail.data) {
            this.setState({
                sellerBusinessData: responseJson.business_detail.data
            })
        }
        if (responseJson && responseJson.address.data) {
            this.setState({
                sellerAddress: responseJson.address.data
            })
        }
        if (responseJson && responseJson.bank_detail) {
            this.setState({
                sellerBankDetail: responseJson.bank_detail
            })
        }
        if (responseJson && responseJson.account.data) {            
            this.setState({
                sellerProfileName: responseJson.account.data.attributes.full_name,
                sellerBusinessName: responseJson.account.data.attributes.business_name
            })
        }
    }


    setGeneralFaqData = (responseJson: any) => {
        if (responseJson && responseJson.data && responseJson.data.faqs) {
            this.setState({
                generalFaqData: responseJson.data.faqs
            })
        }
    }

    setAboutData = (responseJson: any) => {
        if (responseJson && responseJson.data) {
            this.setState({
                generalAboutData: responseJson.data
            })
        }
    }

    setPrivacyData = (responseJson: any) => {
        if (responseJson && responseJson.data) {
            this.setState({
                generalPrivacyData: responseJson.data
            })
        }
    }

    setTermsData = (responseJson: any) => {
        if (responseJson && responseJson.data) {
            this.setState({
                generalTermsData: responseJson.data
            })
        }
    }

    setSellerBanner = (responseJson: any) => {
        if (responseJson && responseJson.data) {
            const activeBanners = responseJson.data.filter((item: any) => item.attributes.status === "active");
            this.setState({
                DynamicSellerBanner: activeBanners
            });
        }
    }


    handleOpenModalVideoPlayer: any = () => {
        this.setState({ openModalVideoPlayer: true });
    };

    handleCloseModalVideoPlayer: any = () => {
        this.setState({ openModalVideoPlayer: false });
    };

    getWhatWeAre = () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiGetWhatWeAre = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.whatWeAreEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            headers
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }



    getAboutPlateform = () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiAboutPlateform = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.aboutThePlateformEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            headers
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getCategories = () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiGetCategories = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.categoriesEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            headers
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getMeetTheCreatters = () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiMeetTheCreaters = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.meetTheCreatersEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            headers
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getLandingPageBanner = () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiLandingPageBanner = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.landingPageBanner
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            headers
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getBottomImage = () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiBottomImage = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.bottommImageEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            headers
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    getAllNotification = async () => {
        const headers = {
            "Content-Type": "application/json",
            token: await getStorageData('singupLogin')
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAllNotifiction = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getAllNotifications}`
        );


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            headers
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        this.receive = this.receive.bind(this);
    }

    getPerformanceCoounter = () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiGetPerformanceCounter = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.performaceDataEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            headers
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    markAllNotificationsAsRead = async () => {
        const headers = {
            "Content-Type": "application/json",
            token: await getStorageData('singupLogin')
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.readAllNotification = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.readAllNotifications}`
        );


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            headers
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        this.receive = this.receive.bind(this);
    }

    handleNotificationBoxToggle = () => {
        this.setState((prevState) => ({ isNotificationBoxOpen: !prevState.isNotificationBoxOpen }));
        this.getAllNotification();
        this.markAllNotificationsAsRead();
    };

    handleTabChange = (event: any, newValue: number) => {
        if (newValue === 0) {
            return;
        }
        this.setState({
            selectedTab: newValue,
            openTunedBox: true
        });

    };


    removeLocalStorageToken = async () => {
        await removeStorageData("loginRedirectVerification");
    }

    updateSellerBusinessPopup: any = async () => {
        const sellerLoginToken = await getStorageData("singupLogin");
        if (sellerLoginToken) {
            const header = {
                "token": sellerLoginToken,
            };

            const formData = new FormData()
            formData.append("business_popup", "true")

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.apiUpdateBusinessPopup = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                formData
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.sellerUpdateProfileApiEndPoint
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.exampleAPiMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    }

    getSellerFaqsData: any = async () => {
        const sellerLoginToken = await getStorageData("singupLogin");
        if (sellerLoginToken) {
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.apiGetSellerFaq = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.sellerFaqApiEndPoint
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.validationApiMethodType
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    }

    getSellerAnalyticsData: any = async () => {
        const sellerLoginToken = await getStorageData("singupLogin");
        if (sellerLoginToken) {
            const header = {
                "token": sellerLoginToken,
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.apiGetSellerAnalytics = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.sellerAnalyticsApiEndPoint
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.validationApiMethodType
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    }
    getVideoTutorialData: any = async () => {
        const sellerLoginToken = await getStorageData("singupLogin");
        if (sellerLoginToken) {
            const header = {
                "token": sellerLoginToken,
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.apiGetVideoTutorial = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.videoTutorialApiEndPoint
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.validationApiMethodType
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    }
    
    getSellerProfileData: any = async () => {
        const sellerLoginToken = await getStorageData("singupLogin");
        if (sellerLoginToken) {
            const header = {
                "token": sellerLoginToken,
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.apiGetSellerProfileData = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.sellerProfileApiEndPoint
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.validationApiMethodType
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    }

   

    getGeneralFaqsData: any = () => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiGetGeneralFaq = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.generalFaqApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getGeneralPrivacyData: any = () => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiGetPrivacyData = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.privacyDataApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    
    getGeneralAboutData: any = () => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiGetAboutData = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.aboutDataApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    

    getGeneralTermsData: any = () => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiGetTermsData = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.termsDataApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getSellerPageBanner: any = async () => {
        const sellerLoginToken = await getStorageData("singupLogin");
        if (sellerLoginToken) {
            const header = {
                "token": sellerLoginToken,
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.apiSellerSideBanner = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.getSellerBanner+"?platform=web"
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.validationApiMethodType
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    }
    handleRedirection=(response:any)=>{
        if(response?.errors && response?.errors[0]?.token){
            window.location.href =  window.location.origin + "/SellerLogin"
        }
    }
    // Customizable Area End
}