import { Route } from "react-router-dom";
import { ModalRoute } from "react-router-modal";
import React from "react";
import PropTypes from "prop-types";
import "react-router-modal/css/react-router-modal.css";
import AppHeader from "../AppHeader.web";
import AppFooterWeb from "../AppFooter.web";
import AppHeaderSeller from "../AppHeaderSeller.web.tsx"

function Wrapper({ element, history, match, routeMap, closeModal }) {
  const navigate = (to, params) => {
    let url = routeMap[to].path;
    // replace params ids in the url with actual values
    if (params && Object.keys(params).length > 0) {
      Object.keys(params).forEach(param => {
        const re = RegExp(`\:${param}\\??`); // eslint-disable-line no-useless-escape
        url = url.replace(re, escape(params[param]));
      });
    }
    // removing empty params from url - every string between /: and ?
    url = url.replace(/\/:(.*?)(?=\/|$)/g, "");
    // if the route is not a modal
    if (!routeMap[to].modal) {
      history.push(url);
      // if the route is a modal
    } else {
      // checking if the url ends with a slash or not
      const slash = /\/$/.test(match.url) ? "" : "/";
      // current url in the browser + slash + modal url with parameters
      url = match.url + slash + url;
      // removing the */ from the url
      url = url.replace(/\*\/?/g, "");
      history.push(url);
    }
  };

  const getParam = (param, alternative) => {
    return match.params[param] || alternative;
  };

  const goBack = () => {
    history.goBack();
  };

  const push = (path,param,stateData) => {
    history.push({
      pathname: path,
      search: param,
      state: {
          data: stateData,
      },
  });
  }

  return React.cloneElement(element, {
    navigation: { navigate, getParam, goBack, push },
    closeModal
  });
}

Wrapper.propTypes = {
  element: PropTypes.element,
  history: PropTypes.object,
  routeMap: PropTypes.object,
  closeModal: PropTypes.func,
  match: PropTypes.object
};

const WebRoutesGenerator = ({ routeMap }) => {
  return Object.keys(routeMap).map(route => {
    const currentRoute = routeMap[route];
    let headerSeller = ["/PostCreationSeller","/TestLive","/InstantGoLive","/ScheduleLive","/SellerDashboard", "/SellerProfile", "/SellerUserBusinessEdit","/InventoryManagement","/AddNewProduct", "/AddProductVariants", "/SellerOrderList", "/SingleOrderStatus","/LiveStreaming","/ProductDetails/:navigationBarTitleText","/EditProduct/:navigationBarTitleText","/Settings","/SellerChat", "/GoLiveScreen", "/CreateStory", "/SellerFeed"]
    let headerNotVisible=["/GetStarted","/SecondScreenStarted","/AccountRegistration","/RegistrationOTP","/LocationAccess","/GetStartedSignup", "/SellerSignUp","/BankingDetails", "/SellerEmailMobileVarification", "/EditMobileNumber", "/EditEmail","/SubmittedDetails", "/BuyerLogin", "/GetStartedLogin", "/BuyerForgotPassword", "/BuyerVerifyOtp", "/BuyerResetPassword", "/SellerForgotPassword", "/SellerVerifyOtp", "/SellerResetPassword", "/SellerLogin","/LoginWithMobile","/OtpVerify", ]
    let headerNotVisibleFooterVisible = ["/About", "/FAQ", "/Privacy", "/Terms"]
    const ComponentWithHeader = ({ children, ...props }) => {
      return (
        <>
          {
            headerSeller.includes(currentRoute.path) ?
              <AppHeaderSeller /> :
              <AppHeader {...props} />
          }
          <div style={{ overflowX: 'hidden', flex: 1 }}>
            {React.cloneElement(children, props)}
            {(!headerSeller.includes(currentRoute.path) && currentRoute.path !== "/ChatScreen") && <AppFooterWeb />}
          </div>
        </>
      )
    }
   console.log(currentRoute.path,"-------------currentRoute")
    const Component = currentRoute.component;
    // headerNotVisible.includes(currentRoute.path)?<AppHeader><Component/></AppHeader>:<><Component/></>
    if (currentRoute.modal) {
      return (
        <ModalRoute
          key={currentRoute.path}
          path={currentRoute.path}
          component={props => (
            <Wrapper element={currentRoute.path=='/GetStarted'?<AppHeader {...props}><Component/></AppHeader>:<AppHeader {...props}/>} {...props} routeMap={routeMap} />
          )}
        />
      );
    } else if (headerNotVisibleFooterVisible.includes(currentRoute.path)) {
      return (
        <Route
          key={currentRoute.path}
          path={currentRoute.path}
          exact={currentRoute.exact}
          render={props => (
            <Wrapper element={headerNotVisibleFooterVisible.includes(currentRoute.path)?   
              <><Component/><AppFooterWeb/></>
  
              :<Component/>} {...props} routeMap={routeMap} />
          )}
        />
      );
    } else {
      return (
        <Route
          key={currentRoute.path}
          path={currentRoute.path}
          exact={currentRoute.exact}
          render={props => (
            <Wrapper element={
              !headerNotVisible.includes(currentRoute.path) ?
                <ComponentWithHeader>
                  <Component />
                </ComponentWithHeader>
                : <Component />} {...props} routeMap={routeMap} />
          )}
        />
      );
    }
  });
};

WebRoutesGenerator.propTypes = {
  routeMap: PropTypes.object
};

export default WebRoutesGenerator;
