// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import { connect } from 'react-firebase'
import AppHeader from "../../components/src/AppHeader.web";
import AppHeaderSeller from "../../components/src/AppHeaderSeller.web.tsx";
import AppFooter from "../../components/src/AppFooter.web";
import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";
import firebase from 'firebase/app';
import "firebase/messaging";
import { removeStorageData, setStorageData } from "../../framework/src/Utilities";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import UserProfiles from "../../blocks/CustomisableUserProfiles/src/UserProfile.web";
import UserProfilesEdit from "../../blocks/CustomisableUserProfiles/src/UserProfileEdit.web.tsx";
import AutomaticCheckoutCalculation2 from "../../blocks/AutomaticCheckoutCalculation2/src/AutomaticCheckoutCalculation2";
import Ordermanagement from "../../blocks/ordermanagement/src/Ordermanagement";
import OrderDetails from "../../blocks/ordermanagement/src/OrderDetails";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import SplitPayments from "../../blocks/SplitPayments/src/SplitPayments";
import Documentation from "../../blocks/Documentation/src/Documentation";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import Mentionstagging from "../../blocks/Mentionstagging/src/Mentionstagging";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Favourites from "../../blocks/favourites/src/Favourites";
import AddFavourites from "../../blocks/favourites/src/AddFavourites";
import Settings from "../../blocks/Settings/src/Settings";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import LikeAPost from "../../blocks/LikeAPost/src/LikeAPost";
import PrivacySettings from "../../blocks/PrivacySettings/src/PrivacySettings.web";
import AdminConsole2 from "../../blocks/AdminConsole2/src/AdminConsole2";
import OrderCreation from "../../blocks/OrderCreation/src/OrderCreation";
import InvoiceBilling from "../../blocks/InvoiceBilling/src/InvoiceBilling";
import Videos from "../../blocks/videos/src/Videos";
import CameraAccess from "../../blocks/cameraaccess/src/CameraAccess";
import PaymentAdmin from "../../blocks/PaymentAdmin/src/PaymentAdmin";
import QrCodes from "../../blocks/qrcodes/src/QrCodes";
import PeopleManagement2 from "../../blocks/PeopleManagement2/src/PeopleManagement2";
import HelpCentre from "../../blocks/helpcentre/src/HelpCentre";
import HelpCentreQA from "../../blocks/helpcentre/src/HelpCentreQA";
import HelpCentreSub from "../../blocks/helpcentre/src/HelpCentreSub";
import Customform from "../../blocks/customform/src/Customform";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import LiveFeedCapture from "../../blocks/LiveFeedCapture/src/LiveFeedCapture";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import DeepLinking from "../../blocks/DeepLinking/src/DeepLinking";
import Notifications from "../../blocks/notifications/src/Notifications";
import ServiceSpecificSettingsAdmin from "../../blocks/ServiceSpecificSettingsAdmin/src/ServiceSpecificSettingsAdmin";
import Addresses from "../../blocks/addressmanagement/src/Addresses";
import AddAddress from "../../blocks/addressmanagement/src/AddAddress";
import DocumentOpener from "../../blocks/documentopener/src/DocumentOpener";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import CreateStory from "../../blocks/LiveStreaming/src/CreateStory.web.tsx";
import Analytics from "../../blocks/analytics/src/Analytics";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import ItemAvailability from "../../blocks/ItemAvailability/src/ItemAvailability";
import LinkShare from "../../blocks/LinkShare/src/LinkShare";
import Interactivefaqs from "../../blocks/interactivefaqs/src/Interactivefaqs";
import AddInteractivefaqs from "../../blocks/interactivefaqs/src/AddInteractivefaqs";
import BuyerFAQ from "../../blocks/interactivefaqs/src/BuyerFaq.web.tsx"
import FormApprovalWorkflow from "../../blocks/FormApprovalWorkflow/src/FormApprovalWorkflow";
import AuditTrail from "../../blocks/AuditTrail/src/AuditTrail";
import MultilevelApproval from "../../blocks/MultilevelApproval/src/MultilevelApproval";
import DataStorage from "../../blocks/DataStorage/src/DataStorage";
import CustomAdvertisements from "../../blocks/CustomAdvertisements/src/CustomAdvertisements";
import BhimUpiIntegration2 from "../../blocks/BhimUpiIntegration2/src/BhimUpiIntegration2";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import ProductDescription from "../../blocks/ProductDescription/src/ProductDescription";
import CFRazorpayOrPaypalIntegration2 from "../../blocks/CFRazorpayOrPaypalIntegration2/src/CFRazorpayOrPaypalIntegration2";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Multiselect from "../../blocks/Multiselect/src/Multiselect";
import TermsAndConditions3 from "../../blocks/TermsAndConditions3/src/TermsAndConditions3.web";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import LiveFeedScheduling from "../../blocks/LiveFeedScheduling/src/LiveFeedScheduling";
import Share from "../../blocks/share/src/Share";
import MatchAlgorithm from "../../blocks/MatchAlgorithm/src/MatchAlgorithm";
import Fedexintegration from "../../blocks/fedexintegration/src/Fedexintegration";
import FedexintegrationOrderTracking from "../../blocks/fedexintegration/src/FedexintegrationOrderTracking";
import ProductQuickview from "../../blocks/ProductQuickview/src/ProductQuickview";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Followers from "../../blocks/Followers/src/Followers";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import ImageManagement from "../../blocks/ImageManagement/src/ImageManagement";
import MultipageForms2 from "../../blocks/MultipageForms2/src/MultipageForms2";
import ProductRecommendationEngine from "../../blocks/ProductRecommendationEngine/src/ProductRecommendationEngine";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import CFCustomForm215 from "../../blocks/CFCustomForm215/src/CFCustomForm215";
import HamburgerMenu from "../../blocks/HamburgerMenu/src/HamburgerMenu";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import ImageThumbnail from "../../blocks/ImageThumbnail/src/ImageThumbnail";
import EmailNotifications2 from "../../blocks/EmailNotifications2/src/EmailNotifications2";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import InventoryManagement3 from "../../blocks/InventoryManagement3/src/InventoryManagement3";
import InventoryManagement from "../../blocks/InventoryManagement3/src/InventoryManagement.web"
import AddNewProduct from "../../blocks/InventoryManagement3/src/AddNewProduct.web"
import ProductDetails from "../../blocks/InventoryManagement3/src/ProductDetails.web"
import AddProductVariants from "../../blocks/InventoryManagement3/src/AddProductVariants.web"
import Chat from "../../blocks/chat/src/Chat";
import ChatView from "../../blocks/chat/src/ChatView";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import VideoManagement from "../../blocks/VideoManagement/src/VideoManagement";
import CF3rdPartyUrlRedirect3 from "../../blocks/CF3rdPartyUrlRedirect3/src/CF3rdPartyUrlRedirect3";
import LandingPageNew from "../../blocks/landingpage/src/LandingPageNew.web";
import Timeclock from "../../blocks/Timeclock/src/Timeclock";
import CustomisedOrderStatus from "../../blocks/CustomisedOrderStatus/src/CustomisedOrderStatus";
import PhotoLibrary3 from "../../blocks/PhotoLibrary3/src/PhotoLibrary3";
import CreateComment from "../../blocks/comments/src/CreateComment";
import CFTagProductToLiveSelling from "../../blocks/CFTagProductToLiveSelling/src/CFTagProductToLiveSelling";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import GetStartedSignup from "../../blocks/email-account-registration/src/GetStartedSignup.web";
import GetStartedLogin from "../../blocks/email-account-login/src/GetStartedLogin.web";
import AccountRegistration from "../../blocks/email-account-registration/src/AccountRegistration.web";
import RegistrationOTP from "../../blocks/email-account-registration/src/RegistrationOTP.web";
import SellerSignUp from "../../blocks/email-account-registration/src/SellerSignUp.web";
import SellerDashboard from '../../blocks/landingpage/src/SellerDashboard.web'
import BankingDetails from "../../blocks/email-account-registration/src/BankingDetails.web";
import SubmittedDetails from "../../blocks/email-account-registration/src/SubmittedDetails.web";
import SellerEmailMobileVarification from "../../blocks/email-account-registration/src/SellerEmailMobileVarification.web";
import EditMobileNumber from "../../blocks/email-account-registration/src/EditMobileNumber.web";
import EditEmail from "../../blocks/email-account-registration/src/EditEmail.web";
import BuyerHome from "../../blocks/landingpage/src/BuyerHome.web";
import SellerProfile from "../../blocks/CustomisableUserProfiles/src/SellerProfile.web";
import SellerUserBusinessEdit from "../../blocks/CustomisableUserProfiles/src/SellerUserBusinessEdit.web"
import EmailAccountLogin from '../../blocks/email-account-login/src/EmailAccountLogin.web'
import LoginWithMobile from '../../blocks/email-account-login/src/LoginWithMobile.web'
import OtpVerify from '../../blocks/email-account-login/src/OtpVerify.web'
import BuyerMarketPlace from "../../blocks/landingpage/src/BuyerMarketPlace.web.tsx";
import BuyerForgotPassword from "../../blocks/forgot-password/src/BuyerForgotPassword.web.tsx";
import BuyerVerifyOtp from "../../blocks/forgot-password/src/BuyerVerifyOtp.web.tsx";
import BuyerResetPassword from "../../blocks/forgot-password/src/BuyerResetPassword.web.tsx";
import SellerForgotPassword from "../../blocks/forgot-password/src/SellerForgotPassword.web.tsx";
import SellerVerifyOtp from "../../blocks/forgot-password/src/SellerVerifyOtp.web.tsx";
import SellerResetPassword from "../../blocks/forgot-password/src/SellerResetPassword.web.tsx";
import SearchrResult from "../../blocks/landingpage/src/SearchResults.web.tsx"
import BuyerShoppingCart from "../../blocks/shoppingcart/src/BuyerShoppingCart.web";
import About from "../../blocks/landingpage/src/About.web"
import Privacy from "../../blocks/landingpage/src/Privacy.web"
import FAQ from "../../blocks/landingpage/src/FAQ.web"
import Terms from "../../blocks/landingpage/src/Terms.web"
import SellerOrderList from "../../blocks/CustomisedOrderStatus/src/SellerOrderList.web.tsx";
import SingleOrderStatus from "../../blocks/CustomisedOrderStatus/src/SingleOrderStatus.web.tsx";
import BuyerOrderDetails from "../../blocks/CustomisedOrderStatus/src/BuyerOrderDetails.web.tsx";
import BuyerQrCodePayment from "../../blocks/qrcodes/src/BuyerQrCodePayment.web.tsx"
import Cfdigitalmall from "../../blocks/cfdigitalmall/src/Cfdigitalmall.web.tsx"
import StoreView from   "../../blocks/cfdigitalmall/src/StoreView.web.tsx"
import LocationAccess from "../../blocks/location/src/LocationAccess.web.tsx"
import BuyerMultipleCart from "../../blocks/cfmultiplecarts1/src/Cfmultiplecarts1.web";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ChatScreen from "../../blocks/chat/src/Chat.web.tsx"
import SellerChatScreen from "../../blocks/chat/src/SellerChat.web.tsx";
import CashFreeOrderStatus from "../../blocks/shoppingcart/src/CashFreeOrderStatus.web.tsx"
import PostCreationSeller from "../../blocks/postcreation/src/PostCreationNew.web.tsx";
import LiveStreaming from "../../blocks/LiveStreaming/src/LiveStreaming.web.tsx";
import BuyerFeed from "../../blocks/postcreation/src/BuyerFeed.web.tsx";
import SellerFeed from "../../blocks/postcreation/src/SellerFeed.web.tsx";
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { useGoogleLogin, GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import PostShareView from "../../blocks/postcreation/src/PostShareView.web.tsx";

const routeMap = {
  BuyerMultipleCart:{
    component:BuyerMultipleCart,
   path:"/BuyerMultipleCart"},
  Cfdigitalmall:{
    component:Cfdigitalmall,
   path:"/Cfdigitalmall"},
CustomisableUserProfiles:{
 component:CustomisableUserProfiles,
path:"/CustomisableUserProfiles"},
About:{
  component: About,
  path: "/About"
},
BuyerAbout:{
  component: About,
  path: "/BuyerAbout"
},
FAQ:{
  component: FAQ,
  path: "/FAQ"
},
Terms:{
  component: Terms,
  path: "/Terms"

},
BuyerTerms:{
  component: TermsAndConditions3,
  path: "/BuyerTerms"

},
Privacy:{
  component: Privacy,
  path: "/Privacy"
},
BuyerPrivacy:{
  component: PrivacySettings,
  path: "/BuyerPrivacy"
},
UserProfiles:{
  component:UserProfiles,
 path:"/UserProfiles"},
 UserProfilesEdit:{
  component:UserProfilesEdit,
 path:"/UserProfileEdit"},
 SellerProfile:{
  component:SellerProfile,
 path:"/SellerProfile"},
 SellerUserBusinessEdit:{
  component:SellerUserBusinessEdit,
 path:"/SellerUserBusinessEdit"},
AutomaticCheckoutCalculation2:{
 component:AutomaticCheckoutCalculation2,
path:"/AutomaticCheckoutCalculation2"},
Ordermanagement:{
 component:Ordermanagement,
path:"/Ordermanagement"},
OrderDetails:{
 component:OrderDetails,
path:"/OrderDetails"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
SplitPayments:{
 component:SplitPayments,
path:"/SplitPayments"},
Documentation:{
 component:Documentation,
path:"/Documentation"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
Mentionstagging:{
 component:Mentionstagging,
path:"/Mentionstagging"},
ShoppingCartOrders:{
 component:ShoppingCartOrders,
path:"/ShoppingCartOrders"},
AddShoppingCartOrderItem:{
 component:AddShoppingCartOrderItem,
path:"/AddShoppingCartOrderItem"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
Wishlist:{
 component:Favourites,
path:"/Wishlist"},
AddFavourites:{
 component:AddFavourites,
path:"/AddFavourites"},
Settings:{
 component:Settings,
path:"/Settings"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
LikeAPost:{
 component:LikeAPost,
path:"/LikeAPost"},
PrivacySettings:{
 component:PrivacySettings,
path:"/PrivacySettings"},
AdminConsole2:{
 component:AdminConsole2,
path:"/AdminConsole2"},
OrderCreation:{
 component:OrderCreation,
path:"/OrderCreation"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
Videos:{
 component:Videos,
path:"/Videos"},
CameraAccess:{
 component:CameraAccess,
path:"/CameraAccess"},
PaymentAdmin:{
 component:PaymentAdmin,
path:"/PaymentAdmin"},
QrCodes:{
 component:QrCodes,
path:"/QrCodes"},
PeopleManagement2:{
 component:PeopleManagement2,
path:"/PeopleManagement2"},
HelpCentre:{
 component:HelpCentre,
path:"/HelpCentre"},
HelpCentreQA:{
 component:HelpCentreQA,
path:"/HelpCentreQA"},
HelpCentreSub:{
 component:HelpCentreSub,
path:"/HelpCentreSub"},
Customform:{
 component:Customform,
path:"/Customform"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
LiveFeedCapture:{
 component:LiveFeedCapture,
path:"/LiveFeedCapture"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
DeepLinking:{
 component:DeepLinking,
path:"/DeepLinking"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
ServiceSpecificSettingsAdmin:{
 component:ServiceSpecificSettingsAdmin,
path:"/ServiceSpecificSettingsAdmin"},
Addresses:{
 component:Addresses,
path:"/Addresses"},
AddAddress:{
 component:AddAddress,
path:"/AddAddress"},
DocumentOpener:{
 component:DocumentOpener,
path:"/DocumentOpener"},
BulkUploading:{
 component:BulkUploading, 
path:"/BulkUploading"},
CreateStory:{
  component:CreateStory,
  path:"/CreateStory"
},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
ItemAvailability:{
 component:ItemAvailability,
path:"/ItemAvailability"},
LinkShare:{
 component:LinkShare,
path:"/LinkShare"},
Interactivefaqs:{
 component:Interactivefaqs,
path:"/Interactivefaqs"},
AddInteractivefaqs:{
 component:AddInteractivefaqs,
path:"/AddInteractivefaqs"},
FormApprovalWorkflow:{
 component:FormApprovalWorkflow,
path:"/FormApprovalWorkflow"},
AuditTrail:{
 component:AuditTrail,
path:"/AuditTrail"},
MultilevelApproval:{
 component:MultilevelApproval,
path:"/MultilevelApproval"},
DataStorage:{
 component:DataStorage,
path:"/DataStorage"},
CustomAdvertisements:{
 component:CustomAdvertisements,
path:"/CustomAdvertisements"},
BhimUpiIntegration2:{
 component:BhimUpiIntegration2,
path:"/BhimUpiIntegration2"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
ProductDescription:{
 component:ProductDescription,
path:"/ProductDescription/:navigationBarTitleText"},
CFRazorpayOrPaypalIntegration2:{
 component:CFRazorpayOrPaypalIntegration2,
path:"/CFRazorpayOrPaypalIntegration2"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Multiselect:{
 component:Multiselect,
path:"/Multiselect"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
LiveFeedScheduling:{
 component:LiveFeedScheduling,
path:"/LiveFeedScheduling"},
Share:{
 component:Share,
path:"/Share"},
MatchAlgorithm:{
 component:MatchAlgorithm,
path:"/MatchAlgorithm"},
Fedexintegration:{
 component:Fedexintegration,
path:"/Fedexintegration"},
FedexintegrationOrderTracking:{
 component:FedexintegrationOrderTracking,
path:"/FedexintegrationOrderTracking"},
ProductQuickview:{
 component:ProductQuickview,
path:"/ProductQuickview"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
Followers:{
 component:Followers,
path:"/Followers"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
RolesPermissions:{
 component:RolesPermissions,
path:"/RolesPermissions"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
ImageManagement:{
 component:ImageManagement,
path:"/ImageManagement"},
MultipageForms2:{
 component:MultipageForms2,
path:"/MultipageForms2"},
ProductRecommendationEngine:{
 component:ProductRecommendationEngine,
path:"/ProductRecommendationEngine"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
CFCustomForm215:{
 component:CFCustomForm215,
path:"/CFCustomForm215"},
HamburgerMenu:{
 component:HamburgerMenu,
path:"/HamburgerMenu"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
ImageThumbnail:{
 component:ImageThumbnail,
path:"/ImageThumbnail"},
EmailNotifications2:{
 component:EmailNotifications2,
path:"/EmailNotifications2"},
BuyerLogin:{
 component:EmailAccountLoginBlock,
path:"/BuyerLogin"},
InventoryManagement3:{
 component:InventoryManagement3,
path:"/InventoryManagement3"},
Chat:{
 component:Chat,
path:"/Chat"},
ChatView:{
 component:ChatView,
path:"/ChatView"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
VideoManagement:{
 component:VideoManagement,
path:"/VideoManagement"},
CF3rdPartyUrlRedirect3:{
 component:CF3rdPartyUrlRedirect3,
path:"/CF3rdPartyUrlRedirect3"},
Timeclock:{
 component:Timeclock,
path:"/Timeclock"},
CustomisedOrderStatus:{
 component:CustomisedOrderStatus,
path:"/CustomisedOrderStatus"},
PhotoLibrary3:{
 component:PhotoLibrary3,
path:"/PhotoLibrary3"},
Comment:{
 component:Comment,
path:"/Comment"},
CreateComment:{
 component:CreateComment,
path:"/CreateComment"},
CFTagProductToLiveSelling:{
 component:CFTagProductToLiveSelling,
path:"/CFTagProductToLiveSelling"},

  AccountRegistration: {
    component: AccountRegistration,
    path: "/AccountRegistration"
  },
  RegistrationOTP: {
    component: RegistrationOTP,
    path: "/RegistrationOTP"
  },
  GetStartedSignup: {
    component: GetStartedSignup,
    path: "/GetStartedSignup"
  },
  GetStartedLogin: {
    component: GetStartedLogin,
    path: "/GetStartedLogin"
  },

  SellerSignUp: {
    component: SellerSignUp,
    path: "/SellerSignUp"
  },
  SellerDashboard: {
    component: SellerDashboard,
    path: "/SellerDashboard"
  },
  BankingDetails: {
    component: BankingDetails,
    path: "/BankingDetails"
  },
  SubmittedDetails: {
    component: SubmittedDetails,
    path: "/SubmittedDetails"
  },
  SearchDetails: {
    component: SearchrResult,
    path: "/Search"
  },
  BuyerFaqDetails: {
    component: BuyerFAQ,
    path: "/BuyerFaq"
  },
SellerEmailMobileVarification: {
  component: SellerEmailMobileVarification,
  path: "/SellerEmailMobileVarification"
},
EditMobileNumber: {
  component: EditMobileNumber,
  path: "/EditMobileNumber"
},
EditEmail: {
  component: EditEmail,
  path: "/EditEmail"
},
BuyerDashboard: {
  component: BuyerHome,
  path: "/",
  exact: true
},
BuyerMarketPlace: {
  component: BuyerMarketPlace,
  path: "/BuyerMarketPlace"
},
BuyerForgotPassword: {
  component: BuyerForgotPassword,
  path: "/BuyerForgotPassword"
},
BuyerVerifyOtp: {
  component: BuyerVerifyOtp,
  path: "/BuyerVerifyOtp"
},
BuyerResetPassword: {
  component: BuyerResetPassword,
  path: "/BuyerResetPassword"
},
SellerForgotPassword: {
  component: SellerForgotPassword,
  path: "/SellerForgotPassword"
},
SellerVerifyOtp: {
  component: SellerVerifyOtp,
  path: "/SellerVerifyOtp"
},
SellerResetPassword: {
  component: SellerResetPassword,
  path: "/SellerResetPassword"
},
InventoryManagement:{
  component: InventoryManagement,
  path: "/InventoryManagement"
},
AddNewProduct:{
  component: AddNewProduct,
  path: "/AddNewProduct"
},
EditProduct:{
  component: AddNewProduct,
  path: "/EditProduct/:navigationBarTitleText"
},
AddProductVariants:{
  component: AddProductVariants,
  path: "/AddProductVariants"
},
  // Home: {
  //   component: LandingPageNew,
  //   path: '/',
    
  // },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  SellerLogin:{
    component:EmailAccountLogin,
    path:"/SellerLogin",
  },
  LoginWithMobile:{
    component:LoginWithMobile,
    path:"/LoginWithMobile"    
  },
  OtpVerify:{
    component:OtpVerify,
    path:"/OtpVerify"
  },
  BuyerShoppingCart: {
    component: BuyerShoppingCart,
    path: "/BuyerShoppingCart/:navigationBarTitleText"
  },
  SellerOrderList: {
    component: SellerOrderList,
    path: "/SellerOrderList"
  },
  SingleOrderStatus: {
    component: SingleOrderStatus,
    path: "/SingleOrderStatus"
  },
  BuyerOrderDetails: {
    component: BuyerOrderDetails,
    path: "/BuyerOrderDetails"
  },
  ProductDetails:{
    component: ProductDetails,
    path: "/ProductDetails/:navigationBarTitleText"
  },
  BuyerQrCodePayment:{
    component: BuyerQrCodePayment,
    path: "/BuyerQrCodePayment"
  },
  ChatScreen:{
    component:ChatScreen,
    path:"/ChatScreen"
  },
  SellerChatScreen:{
    component: SellerChatScreen,
    path:"/SellerChat"
  },
  CashFreeOrderStatusScreen:{
    component: CashFreeOrderStatus,
    path:"/Payment/Cashfree/Order/:orderId"
  },
  Store:{
    component: StoreView,
    path: "/Store/:navigationBarTitleText",
    exact:true
  },
  LocationAccess:{
    component: LocationAccess,
    path: "/LocationAccess",
    exact:true
  },
  PostCreationSeller:{
    component: PostCreationSeller,
    path: "/PostCreationSeller",
    exact:true
  },
  LiveStreaming:{
    component: LiveStreaming,
    path: "/LiveStreaming",
    exact:true
  },
  BuyerFeed: {
    component: BuyerFeed,
    path: "/BuyerFeed",
    exact: true,
  },
  SellerFeed: {
    component: SellerFeed,
    path: "/SellerFeed",
    exact: true,
  },
  PostShareView: {
    component: PostShareView,
    path: "/Post/Share/:postId"
  }
};

const firebaseAPI = firebase.initializeApp({
  // apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  // authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  // databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  // projectId: "rnmasterapp-c11e9",
  // storageBucket: "rnmasterapp-c11e9.appspot.com",
  // messagingSenderId: "649592030497",
  // appId: "1:649592030497:web:7728bee3f2baef208daa60",
  // measurementId: "G-FYBCF3Z2W3"

  apiKey: "AIzaSyDhTvxhzlBzlSDc8ZBuaLtQYZ0JQ1V4Dzg",
  authDomain: "flixoolive.firebaseapp.com",
  projectId: "flixoolive",
  storageBucket: "flixoolive.appspot.com",
  messagingSenderId: "748958312232",
  appId: "1:748958312232:web:eb5f0e3ccd09d04372d21d",
  measurementId: "G-RHKYGJSV6N"
});

export const messaging=firebase.messaging(firebaseAPI)

if ('serviceWorker' in navigator) {
  window.addEventListener('load', function () {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
      .then(function (registration) {
        console.log('tttt firebase Service Worker registered with scope:', registration.scope);
      })
      .catch(function (error) {
        console.error('tttt firebase Service Worker registration failed:', error);
      });
  });
}

function showNotification() {
  const notification = new Notification('My Website Notification', {
    body: 'This is a notification body!',
    icon: 'https://yourwebsite.com/icon.png', // Optional notification icon
    // Other notification options (e.g., badge, sound)
  });

  notification.onclick = () => {
    window.open('https://yourwebsite.com/'); // Open your website on notification click
  };

  notification.onclose = () => {
  };
}

class App extends Component {

  constructor() {
    super();
    this.mainRef = React.createRef();
  }

  async componentDidUpdate() {
    this.mainRef?.current?.scrollTo(0, 0);
  }

  async componentDidMount(){
    const messaging = firebase.messaging();
    if (Notification.permission === 'granted') {
      messaging.getToken()
      .then(() => {
        return messaging.getToken();
      })
      .then(async(token) => {
        await setStorageData("device_token",token);
      })
      .catch(error => {
        console.log("error", error);
      });
    } else {
      await removeStorageData("device_token");
    }
  }

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <GoogleOAuthProvider clientId="703100623610-adc5i8fnsasb8bkk6cgco5ff50o8r88g.apps.googleusercontent.com">
      <div ref={this.mainRef} style={{ height: '100vh', width: '100vw', overflowY: 'auto' }}>
        {WebRoutesGenerator({ routeMap })}      
        <ModalContainer />
      </div>
      </GoogleOAuthProvider>
    );
  }
}

export default App;
