import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import {
  circularImg1,
  circularImg2,
  circularImg3,
  circularImg4,
  circularImg5,
  circularImg6,
  shose,
  coins,
  oval,
  sliderImg1,
  sliderImg2,
  sliderImg3,
  sliderUserProfile,
  save,
  rectangle,
  radar,
  sliderImg4,
  sliderImg5,
  sliderImg6,
  story1,
  story2,
  story3,
  story4,
  story5,
  story6,
  story7,
  liveUser,
  liveBg1,
  liveBg2,
  liveBg3,
  contentCreator1,
  contentCreator2,
  topSelling1,
  topSelling2,
  topSelling3,
  topSelling4,
  topSelling5,
  onOrder1,
  onOrder2,
  onOrder3,
  onOrder4,
} from "./assets";
import React, { createRef } from "react";
import { RouteComponentProps } from "react-router-dom";
import moment from "moment";
import SendbirdChat, { FileCompat, SendbirdChatWith } from "@sendbird/chat";
import { GroupChannel, GroupChannelFilter, GroupChannelListOrder, GroupChannelModule } from "@sendbird/chat/groupChannel";
import { FileMessageCreateParams, UserMessageCreateParams } from "@sendbird/chat/message";
import eventEmitter from "../../../components/src/StoreCount";
export const SENDBIRD_INFO = { appId: '03C717AD-A9AE-41BF-AD88-05A83357E859' };
import SwiperCore, { Navigation } from 'swiper';
export interface Category {
  type: string;
  id: number;
  attributes: {
    name: string;
    id: number;
    light_icon: string
    slug: string;
    updated_at: string;
    selected: boolean
    created_at: string;
  }
}

export interface OnOrderData {
  title: string;
  description: string;
  sellerId: number;
  accountFullName: string;
  hostImage: string;
  productImageUrl: string;
  productId: string,
}

interface AccountPayLoad {
  id: number;
  full_name: string;
}

interface ProductImage {
  url: string;
  id: number,
}

interface CatalogueAttributes {
  title: string;
  description: string;
  account: AccountPayLoad;
  host_image?: string;
  product_images: ProductImage[];
}

interface Catalogue {
  id: string;
  type: string;
  attributes: CatalogueAttributes;
}

interface ProductIds {
  product_id: number,
  favourite_id: number
}

interface WishListCatalogue {
  product_ids : ProductIds[]
}

interface OnOrderApiResponse {
  catalogues: {
    data: Catalogue[];
  };
  wishlist_items : WishListCatalogue
}

interface ApiPayloadType{
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  type?: string;
}

interface CreateDataPayload{
    attributes: {
      id: string
    }
}
interface CreateOnOrderApiRes{
  channel_url: string;
  data: CreateDataPayload;
}

export interface StoryUploaders {
  id: string;
  type: string;
  attributes: StoryAttributes;
}

interface StoryAttributes {
  id: number;
    title: string;
    description: string;
    duration: number; 
    seen: boolean;
    location: string | null; 
    color: string | null; 
    activated: boolean;
    created_at: string; 
    updated_at: string; 
    creator_details: CreatorDetails;
    media: StoryMedia;
    duration_with_seconds: string;
    liked: boolean;
}

interface CreatorDetails {
  id: number;
  full_name: string;
  profile_url: string | null;
}

interface StoryMedia {
    id: number;
    filename: string;
    url: string;
    type: string;
}

interface AllStoryResponse {
  data: Array<StoryUploaders>
}

interface LikeResponse {
  message: string;
  data: {
    id: number;
    type: string;
    attributes: {
      likeable_id: number;
      likeable_type: string;
      like_by_id: number;
      created_at: string;
      updated_at: string;
    }
  };
}
SwiperCore.use([Navigation]);
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends RouteComponentProps {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  isOpen?: boolean;
  history: any;
  closeModal?: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  isVisibleModal: boolean;
  openItemIndex: number | null;
  displayPopup: boolean;
  showSidebar: boolean;
  scrollPosition: number;
  chooseCatergoriesData: any;
  isChecked: boolean;
  checkedDataPost: any;
  currentID: string | null;
  chooseCategoriesOpenClose: boolean
  getSubCategoriesdata: any
  subCateId: any;
  showLoader: boolean;
  searchValue: any;
  getTopSearchData: any;
  getLiveEventsSearchData: any;
  getShowsSearchedData: any;
  showAllLiveEvents: boolean;
  showAllTopSelling: boolean;
  showingAll: boolean;
  openTunedBox: boolean;
  wishListLiveStream: any;
  wishListProduct: any;
  message: string;
  messageModel: boolean;
  subCategoryIDs: any;
  tokenPresentOrNot:string | null
  seletAllCategories:boolean;
  viewType: string;
  categoriesSidbarData: Array<{[key:string]: string}>
  categoryData: Array<Category>;
  categorySortValue: string;
  showAllOnOrders: boolean;
  isRequestModal: boolean;
  isSuccessModal: boolean;
  onOrderData: OnOrderData[];
  selectedOnOrder: OnOrderData | null;
  wishListOnOrder: Array<ProductIds>;
  selectedFiles: File[];
  imgUploadErr: string;
  expectedDate: Date | null;
  description: string;
  quantity: number,
  descriptionErr: boolean;
  channelUrl: string;
  channel:  GroupChannel | null
  hasUserInteracted: boolean
  instaModal: boolean
  instaModalIndex: number
  allStoryHeader: Array<StoryUploaders>;
  userStory : Array<StoryUploaders>;
  goToSlide: string;
  storyMuted: boolean;
  storyPaused: boolean;
  currentStoryIndex: number;
  currentSlide: number;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      loading: false,
      isVisibleModal: false,
      openItemIndex: null,
      displayPopup: false,
      showSidebar: false,
      scrollPosition: 0,
      chooseCatergoriesData: [],
      isChecked: false,
      checkedDataPost: [],
      currentID: '',
      chooseCategoriesOpenClose: false,
      getSubCategoriesdata: [],
      subCateId: '',
      showLoader: false,
      searchValue: '',
      getTopSearchData: [],
      getLiveEventsSearchData: [],
      getShowsSearchedData: [],
      showAllLiveEvents: false,
      showAllTopSelling: false,
      showingAll: false,
      openTunedBox: false,
      wishListLiveStream: [],
      wishListProduct: [],
      message: '',
      messageModel: false,
      subCategoryIDs: [],
      tokenPresentOrNot:null,
      seletAllCategories:false,
      viewType: "",
      categoriesSidbarData :[
        {
          name: "newest"
        },
        {
          name: "most popular"
        },
        {
          name: "most products"
        },
        {
          name: "alphabetical (A to Z)"
        },
        {
          name: "Alphabetical (Z to A)"
        },
      ],
      categoryData: [],
      categorySortValue: "newest",
      showAllOnOrders: false,
      isRequestModal: false,
      isSuccessModal: false,
      onOrderData: [],
      selectedOnOrder: null,
      wishListOnOrder: [],
      selectedFiles: [],
      imgUploadErr: "",
      expectedDate: new Date(),
      description: "",
      quantity: 1,
      descriptionErr: false,
      channelUrl: "",
      channel: null,
      hasUserInteracted: false,
      instaModal: false,
      allStoryHeader: [],
      userStory : [],
      goToSlide: "",
      storyMuted: false,
      storyPaused: false,
      currentStoryIndex: 0,
      currentSlide: 0,
      instaModalIndex: 0
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    switch (apiRequestCallId) {
      case this.apiChoosecategories:
        this.setState({
          chooseCatergoriesData: responseJson?.data, showLoader: false
        });

        const ID = await getStorageData('categoryID')
        if (ID) {
          const index = responseJson?.data.findIndex((item: any) => item.id === ID);
          this.toggleAsideMenuItem(ID, index);
        }
        break;



      case this.apiSubCategoriers:
        this.setState(prevState => {
          return {
            getSubCategoriesdata: responseJson?.data, showLoader: false
          };
        }, () => {
        });
        break;
      case this.apiTopSearch:
        if (responseJson?.catalogues) {
          this.setState({
            getTopSearchData: responseJson?.catalogues.data,
            getLiveEventsSearchData: responseJson?.live_streams?.data,
            showLoader: false,
            loading: false,
            wishListLiveStream: responseJson?.wishlist_items?.live_stream_ids, wishListProduct: responseJson?.wishlist_items?.product_ids
          })
        } else if (responseJson.errors) {
          this.setState({
            loading: false,
            message: responseJson.errors[0].token,
            messageModel: true
          })
        }
        break;
      case this.apiAddToWishList:
        this.checkItemAddedWishList(responseJson);
        break;
      case this.apiDeleteFromWishList:
        this.checkItemRemovedWishList(responseJson);
        break;
      case this.getOnOrderDataApiID:
        this.onOrderApiSuccessCallBack(responseJson);
        break;
      case this.postOnOrderDataApiId:
        this.createOnOrderSuccessCallBack(responseJson)
        break;
      case this.apiGetcartitemscount:
        if(responseJson){
         localStorage.setItem("cartItemsCount",responseJson.total_items_in_cart);
         eventEmitter.emit('cartUpdated', responseJson.total_items_in_cart,[]);
        }
          break;
      default:
        // this.setState({showLoader: false})
        break;
    }
    if (this.isApiSuccess(responseJson)) {
      this.apiSuccess(apiRequestCallId, responseJson);
    }
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let viewType = message.getData(getName(MessageEnum.LandingPageViewType));
      if (viewType) {
        this.setState({
          viewType
        })
      }
    }
    if (apiRequestCallId===this.allUserStoriesCallId){
        this.allUserStoriesSuccessCallBack(responseJson)
    };
    if (apiRequestCallId=== this.viewStoryCallId){
      this.viewStorySuccessCallBack(responseJson)
    };
    if (apiRequestCallId=== this.likeStoryCallId){
      this.likeStorySuccessCallBack(responseJson)
    };
    // Customizable Area End
  }

  // Customizable Area Start
  apiChoosecategories: any;
  apiChooseCategoriesPost: any;
  apiTopSearch: any;
  apiSubCategoriers: any;
  apiAddToWishList: string = ''
  apiDeleteFromWishList: string = ''
  apiGetcartitemscount: string = "";
  getCategoryDataApiID: string = "";
  getStoresApiID: string = "";
  getOnOrderDataApiID: string = "";
  postOnOrderDataApiId: string = "";
  allUserStoriesCallId: string = "";
  viewStoryCallId: string = "";
  likeStoryCallId:string = "";
  fileInputRef: React.RefObject<HTMLInputElement> = createRef();
  dateInputRef: React.RefObject<HTMLInputElement> = createRef();
  sendBird: SendbirdChatWith<GroupChannelModule[]> | null = null;
  swiperInstance: SwiperCore | null = null;
  async componentDidMount() {
    const tokenPresentOrNot =  await getStorageData('buerLoginToken')
    this.setState({tokenPresentOrNot:tokenPresentOrNot})
    this.getCategories();
    this.getCartItems();
    this.getOnOrderData();
    if(tokenPresentOrNot !== null){
      this.initializeSendBirdSDK();
      this.fetchAllUserStories();
    }
    const headingElement = document.getElementById("myref")
    if(headingElement) headingElement.scrollIntoView({behavior: 'smooth'})
    this.getChooseCategoriesData()
    this.getTopSearchData('')
    const model = await getStorageData('ChooseCategoriesModal')
    if (model) {
      this.setState({ chooseCategoriesOpenClose: Boolean((getStorageData('ChooseCategoriesModal') as unknown as string)) })
      // removeStorageData('ChooseCategoriesModal')
    }
  }

  initializeSendBirdSDK = async () => {
    const buyerData = await getStorageData("Buyer_Data");
    const buyerObj = JSON.parse(buyerData);
    let buyerId = await buyerObj?.sendbird_credential?.user_id;
    let buyerToken = await buyerObj?.sendbird_credential?.access_token;
    const sendbirdChat = SendbirdChat.init({
      appId: SENDBIRD_INFO.appId,
      localCacheEnabled: true,
      modules: [new GroupChannelModule()]
    });
    try {
      await sendbirdChat.connect(buyerId, buyerToken);
    } catch (error) {
    }
    await sendbirdChat.setChannelInvitationPreference(true);
    this.sendBird = sendbirdChat;
  };

  loadChannels = async (channelURL: string, productId: string) => {
    const groupChannelFilter = new GroupChannelFilter();
    groupChannelFilter.includeEmpty = true;
    const collection = this.sendBird?.groupChannel.createGroupChannelCollection({
      filter: groupChannelFilter,
      order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
    });
    const channels = await collection?.loadMore();
    if (channels && channels.length > 0) {
      let channelIndex = channels.findIndex((channel) => channel.url === channelURL);
      this.setState({ channel: channels[channelIndex] }, ()=>{
        this.sendMessageSendBird(productId);
      });
    }
  };

  sendMessageSendBird = async (productId: string) => {
    const { channel, description, selectedOnOrder, quantity, expectedDate, selectedFiles } = this.state;
    if (!channel) return;
    try {
      if(selectedOnOrder){
      const messageData = JSON.stringify({ productId: productId, title: selectedOnOrder.title, description: description, quantity: quantity, expectedDate: expectedDate, productUrl: selectedOnOrder.productImageUrl,  onOrderStatus: "pending"  })
      const param: UserMessageCreateParams = {
        message: messageData,
        data: JSON.stringify({ delivered: false, read: false }),
        customType: 'on_order',
      };
      channel?.sendUserMessage(param).onSucceeded(async (sentMessage) => {
        if (sentMessage) {
          const params = {
            customType: 'on_order',
            data: JSON.stringify({ delivered: true, read: false }),
          };
          try {
            await channel.updateUserMessage(sentMessage.messageId, params);
          } catch (error) {
          }
          sentMessage['data'] = JSON.stringify({ delivered: true, read: false });
        }
      });
      if(selectedFiles.length > 0){
        this.handleFileSend();
      }
      this.setState({selectedOnOrder: null, description: "", quantity: 1, expectedDate: new Date()});
    }
    } catch (error) {
    }
  };

  handleFileSend = async () => {
    const { channel, selectedFiles } = this.state;
    if (!channel) return;
    selectedFiles.forEach((files) => {
      const fileMessageParams: FileMessageCreateParams = {
        file: files as FileCompat,
        customType: "image",
        data: JSON.stringify({ delivered: false, read: false }),
      }
      channel?.sendFileMessage(fileMessageParams).onSucceeded(async (sentMessage) => {
        if (sentMessage) {
          const params = {
            customType: "image",
            data: JSON.stringify({ delivered: true, read: false }),
          };
          try {
            await channel.updateFileMessage(sentMessage.messageId, params);
          } catch (error) {
          }
          sentMessage['data'] = JSON.stringify({ delivered: true, read: false });
        }
      });
    })
    this.setState({selectedFiles : []});
  };

  checkItemAddedWishList = (responJson: any) => {
    if (responJson.message) {
      this.getTopSearchData('');
      this.getOnOrderData();
      this.closeMessageModal();
      this.setState({ message: responJson.message })
    } else {
      this.closeMessageModal();
      this.setState({ message: responJson.errors })
    }
  }

  checkItemRemovedWishList = (responJson: any) => {
    if (responJson.message) {
      this.getTopSearchData('');
      this.getOnOrderData();
      this.closeMessageModal();
      this.setState({ message: responJson.message })
    } else {
      this.closeMessageModal();
      this.setState({ message: responJson.errors[0] })
    }
  }

  checkWishStatus = (producArray: any, ID: any) => {
    const index = producArray?.findIndex((item: any) => item.live_stream_id === ID);
    if (index !== -1) { return true; } 
    else {
        return false;
    }
}
checkTokenPresentOrNot=()=>{
  if(this.state.tokenPresentOrNot === null){
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),"GetStartedLogin");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  }
}

deleteWishItem = (producArray: any, ID: any) => {
  this.checkTokenPresentOrNot()
    const index = producArray.find((item: any) => item.live_stream_id === ID).favourite_id;
    if (index) { this.deleteWishListItem(index)
    } 
    else {
        return false;
    }
}

checkProductStatus = (producArray: any, ID: any) => {
    const index = producArray?.findIndex((item: any) => item.product_id === ID);
    if (index !== -1) { return true; } 
    else {
        return false;
    }
}

deleteProductItem = (producArray: any, ID: any) => {
  this.checkTokenPresentOrNot()
    const index = producArray.find((item: any) => item.product_id === ID).favourite_id;
    if (index) { this.deleteWishListItem(index) } 
    else {
        return false;
    }
}

  closeMessageModal = () => {
    this.setState({ messageModel: !this.state.messageModel })
  }

  getChooseCategoriesData = async () => {
    const tokenPresentOrNot = this.state.tokenPresentOrNot ?? null
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: tokenPresentOrNot
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiChoosecategories = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getChooseCatergoriesDataEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getSubCategoriesData = async (id: number) => {
    this.setState({ showLoader: true })
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData('buerLoginToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSubCategoriers = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.subCategoriesDataEndPoint}?category_id=${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTopSearchDataWithSubCategory = (items: any, item: any) => {
    removeStorageData("categoryID");
    const index = this.state.subCategoryIDs.indexOf(item.id)
    if (index !== -1) {
      const updatedSubCategoryIDs = [...this.state.subCategoryIDs]
      updatedSubCategoryIDs.splice(index, 1);
      this.setState({
        subCategoryIDs: updatedSubCategoryIDs
      }, () => {
        this.getTopSearchData(`search=${items?.attributes?.name}&sub_category_id=${this.state.subCategoryIDs.join(',')}`)
      })
    } else {
      this.setState({
        subCategoryIDs: [...this.state.subCategoryIDs, item.id]
      }, () => {
        this.getTopSearchData(`search=${items?.attributes?.name}&sub_category_id=${this.state.subCategoryIDs.join(',')}`)
      })
    }
  }

  getTopSearchData = async (subCateQueryUrl: string) => {
    this.setState({ loading: true });
    const tokenPresentOrNot = this.state.tokenPresentOrNot ?? null
    const headers = {
      token: tokenPresentOrNot,
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiTopSearch = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.getTopSearchEndPoint}${subCateQueryUrl}`
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleOnChangeChecbox = (item: any, event: any) => {
    const checkedValue = event.target.value;
    const isChecked = event.target.checked;
    const currentID = item?.id;
    this.setState({ isChecked, currentID })

    console.log(isChecked, 'isChecked')
    this.setState((prevState) => {
      let newArray = [...prevState.checkedDataPost];
      if (isChecked) {
        newArray.push(checkedValue);
        if (newArray.length < 5) {
          console.log('helloMannnnn')
        }
      } else {
        newArray = newArray.filter((value) => value !== checkedValue);
      }
      return { checkedDataPost: newArray };
    }, () => {
      console.log(this.state.checkedDataPost, 'updated checkedDataPost');
    });
  };

  chooseCategoryDataUpdated = async () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData('buerLoginToken')
    };
    let raw = {
      "categories_ids": this.state.checkedDataPost
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postchooseCategoriesDataEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(raw)
    )
    this.apiChooseCategoriesPost = requestMessage.messageId;
    await removeStorageData('ChooseCategoriesModal')
    this.closeMakeOfferPopup()

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  sliderData = [
    {
      id: 1,
      title: 'Sports',
      imageSrc: circularImg1,
    },
    {
      id: 2,
      title: 'Toys & hobbies',
      imageSrc: circularImg2,
    },
    {
      id: 3,
      title: 'Coins & money',
      imageSrc: circularImg3,
    },
    {
      id: 4,
      title: 'Electronics',
      imageSrc: circularImg4,
    },
    {
      id: 5,
      title: 'Men’s fashion',
      imageSrc: circularImg5,
    },
    {
      id: 6,
      title: 'Women’s fashion',
      imageSrc: circularImg6,
    },
    {
      id: 7,
      title: 'Electronics',
      imageSrc: circularImg1,
    },
  ];

  cardContentData = [
    {
      id: 1,
      cardImg: shose,
      cardProfile: oval,
      cardUserName: 'Ivan Morais',
      cardContentBlack: 'Limited edition sneakers',
      cardContentGray: 'Nike limited editon sneakers in white color',

    },
    {
      id: 2,
      cardImg: coins,
      cardProfile: oval,
      cardUserName: 'Ivan Morais',
      cardContentBlack: 'Rare coin collection',
      cardContentGray: 'Rare roman coin collection ',
    },
    {
      id: 3,
      cardImg: shose,
      cardProfile: oval,
      cardUserName: 'Ivan Morais',
      cardContentBlack: 'Limited edition sneakers',
      cardContentGray: 'Nike limited editon sneakers in white color',
    },
    {
      id: 4,
      cardImg: coins,
      cardProfile: oval,
      cardUserName: 'Ivan Morais',
      cardContentBlack: 'Rare coin collection',
      cardContentGray: 'Rare roman coin collection ',
    },
  ];

  squreSliderData = [
    {
      id: 1,
      title: 'Peter thornton',
      proffestion: 'Women Fasion',
      imageSrc: sliderImg2,
      profileImgSrc: sliderUserProfile,
      rightIconSrc: save,
      liveImg: radar,
    },
    {
      id: 2,
      title: 'Devon Miles',
      proffestion: 'Electronics',
      imageSrc: sliderImg3,
      profileImgSrc: sliderUserProfile,
      rightIconSrc: save,
      rectangleImg: rectangle,
      liveImg: radar,
    },
    {
      id: 3,
      title: 'Peter thornton',
      proffestion: 'Sneakers',
      imageSrc: sliderImg2,
      profileImgSrc: sliderUserProfile,
      rightIconSrc: save,
      rectangleImg: rectangle,
      liveImg: radar,
    },
    {
      id: 4,
      title: 'Kate Tanner',
      proffestion: 'Women Fasion',
      imageSrc: sliderImg1,
      profileImgSrc: sliderUserProfile,
      rightIconSrc: save,
      rectangleImg: rectangle,
      liveImg: radar,
    },
    {
      id: 5,
      title: 'Murdock Tanner',
      proffestion: 'Sneakers',
      imageSrc: sliderImg2,
      profileImgSrc: sliderUserProfile,
      rightIconSrc: save,
      rectangleImg: rectangle,
      liveImg: radar,
    },
    {
      id: 6,
      title: 'Kate Tanner',
      proffestion: 'Electronics',
      imageSrc: sliderImg3,
      profileImgSrc: sliderUserProfile,
      rectangleImg: rectangle,
      rightIconSrc: save,
      liveImg: radar,
    },
    {
      id: 7,
      title: 'Murdock Tanner',
      proffestion: 'Women Fasion',
      imageSrc: sliderImg2,
      profileImgSrc: sliderUserProfile,
      rectangleImg: rectangle,
      rightIconSrc: save,
      liveImg: radar,
    },
  ];

  squreSliderDataLive = [
    {
      id: 1,
      title: 'Peter thornton',
      proffestion: 'Women Fasion',
      imageSrc: sliderImg4,
      rightIconSrc: save,
      rectangleImg: rectangle,
      liveImg: radar,
      profileImgSrc: oval,
    },
    {
      id: 2,
      title: 'Devon Miles',
      proffestion: 'Electronics',
      imageSrc: sliderImg5,
      rightIconSrc: save,
      rectangleImg: rectangle,
      liveImg: radar,
      profileImgSrc: oval,
    },
    {
      id: 3,
      title: 'Peter thornton',
      proffestion: 'Sneakers',
      imageSrc: sliderImg6,
      rightIconSrc: save,
      rectangleImg: rectangle,
      liveImg: radar,
      profileImgSrc: oval,
    },
    {
      id: 4,
      title: 'Kate Tanner',
      proffestion: 'Women Fasion',
      imageSrc: sliderImg4,
      profileImgSrc: oval,
    },
    {
      id: 5,
      title: 'Murdock Tanner',
      proffestion: 'Sneakers',
      imageSrc: sliderImg5,
      rightIconSrc: save,
      rectangleImg: rectangle,
      liveImg: radar,
      profileImgSrc: oval,
    },
    {
      id: 6,
      title: 'Kate Tanner',
      proffestion: 'Electronics',
      imageSrc: sliderImg4,
      rightIconSrc: save,
      rectangleImg: rectangle,
      liveImg: radar,
      profileImgSrc: oval,
    },
    {
      id: 7,
      title: 'Murdock Tanner',
      proffestion: 'Women Fasion',
      imageSrc: sliderImg6,
      rightIconSrc: save,
      rectangleImg: rectangle,
      liveImg: radar,
      profileImgSrc: oval,
    }
  ];

  
  asideMenu = [
    {
      title: "Furniture & Interior",
      items: ["A", "B", "C"]
    },
    {
      title: "Antiques",
      items: ["A", "B", "C"]
    },
    {
      title: "Sports",
      items: ["A", "B", "C"]
    },
    {
      title: "Toys",
      items: ["A", "B", "C"]
    },
    {
      title: "Beauty & Make up",
      items: ["A", "B", "C"]
    },
    {
      title: "Sneakers",
      items: ["A", "B", "C"]
    }
  ]

  liveEvents = [
    {
      liveEventBackgroundImage: liveBg1,
      liveEventUserImage: liveUser,
      liveEventUserName: "Ivan Morais",
      liveEventTitle: "Lorem ipsum dolor sit amet",
      liveEventDescription: "Lorem ipsum dolor sit amet consectetur. Id nisl orci in"
    },
    {
      liveEventBackgroundImage: liveBg2,
      liveEventUserImage: liveUser,
      liveEventUserName: "Ivan Morais",
      liveEventTitle: "Lorem ipsum dolor sit amet",
      liveEventDescription: "Lorem ipsum dolor sit amet consectetur. Id nisl orci in"
    },
    {
      liveEventBackgroundImage: liveBg1,
      liveEventUserImage: liveUser,
      liveEventUserName: "Ivan Morais",
      liveEventTitle: "Lorem ipsum dolor sit amet",
      liveEventDescription: "Lorem ipsum dolor sit amet consectetur. Id nisl orci in"
    },
    {
      liveEventBackgroundImage: liveBg3,
      liveEventUserImage: liveUser,
      liveEventUserName: "Ivan Morais",
      liveEventTitle: "Lorem ipsum dolor sit amet",
      liveEventDescription: "Lorem ipsum dolor sit amet consectetur. Id nisl orci in"
    },
    {
      liveEventBackgroundImage: liveBg2,
      liveEventUserImage: liveUser,
      liveEventUserName: "Ivan Morais",
      liveEventTitle: "Lorem ipsum dolor sit amet",
      liveEventDescription: "Lorem ipsum dolor sit amet consectetur. Id nisl orci in"
    },
    {
      liveEventBackgroundImage: liveBg3,
      liveEventUserImage: liveUser,
      liveEventUserName: "Ivan Morais",
      liveEventTitle: "Lorem ipsum dolor sit amet",
      liveEventDescription: "Lorem ipsum dolor sit amet consectetur. Id nisl orci in"
    },
  ]

  creatorContent = [
    {
      backgroundImage: contentCreator1,
      userImage: liveUser,
      userName: "Ivan Morais",
      title: "Lorem ipsum dolor sit amet",
      description: "Lorem ipsum dolor sit amet consectetur. Id nisl orci in"
    },
    {
      backgroundImage: contentCreator2,
      userImage: liveUser,
      userName: "Ivan Morais",
      title: "Lorem ipsum dolor sit amet",
      description: "Lorem ipsum dolor sit amet consectetur. Id nisl orci in"
    },
    {
      backgroundImage: contentCreator2,
      userImage: liveUser,
      userName: "Ivan Morais",
      title: "Lorem ipsum dolor sit amet",
      description: "Lorem ipsum dolor sit amet consectetur. Id nisl orci in"
    },
    {
      backgroundImage: contentCreator2,
      userImage: liveUser,
      userName: "Ivan Morais",
      title: "Lorem ipsum dolor sit amet",
      description: "Lorem ipsum dolor sit amet consectetur. Id nisl orci in"
    },
    {
      backgroundImage: contentCreator1,
      userImage: liveUser,
      userName: "Ivan Morais",
      title: "Lorem ipsum dolor sit amet",
      description: "Lorem ipsum dolor sit amet consectetur. Id nisl orci in"
    },
    {
      backgroundImage: contentCreator1,
      userImage: liveUser,
      userName: "Ivan Morais",
      title: "Lorem ipsum dolor sit amet",
      description: "Lorem ipsum dolor sit amet consectetur. Id nisl orci in"
    },
  ]

  topSelling = [
    {
      archived: true,
      backgroundImage: topSelling1,
      userImage: liveUser,
      userName: "Ivan Morais",
    },
    {
      archived: false,
      backgroundImage: topSelling2,
      userImage: liveUser,
      userName: "Ivan Morais",
    },
    {
      archived: true,
      backgroundImage: topSelling3,
      userImage: liveUser,
      userName: "Ivan Morais",
    },
    {
      archived: false,
      backgroundImage: topSelling4,
      userImage: liveUser,
      userName: "Ivan Morais",
    },
    {
      archived: true,
      backgroundImage: topSelling5,
      userImage: liveUser,
      userName: "Ivan Morais",
    },
    {
      archived: true,
      backgroundImage: topSelling1,
      userImage: liveUser,
      userName: "Ivan Morais",
    },
  ]

  onOrder = [
    {
      onOrderBackgroundImage: onOrder1,
      onOrderUserImage: liveUser,
      onOrderUserName: "Ivan Morais",
      onOrderTitle: "Lorem ipsum dolor sit amet",
      onOrderDescription: "Lorem ipsum dolor sit amet consectetur. Id nisl orci in"
    },
    {
      onOrderBackgroundImage: onOrder1,
      onOrderUserImage: liveUser,
      onOrderUserName: "Ivan Morais",
      onOrderTitle: "Lorem ipsum dolor sit amet",
      onOrderDescription: "Lorem ipsum dolor sit amet consectetur. Id nisl orci in"
    },
    {
      onOrderBackgroundImage: onOrder2,
      onOrderUserImage: liveUser,
      onOrderUserName: "Ivan Morais",
      onOrderTitle: "Lorem ipsum dolor sit amet",
      onOrderDescription: "Lorem ipsum dolor sit amet consectetur. Id nisl orci in"
    },
    {
      onOrderBackgroundImage: onOrder3,
      onOrderUserImage: liveUser,
      onOrderUserName: "Ivan Morais",
      onOrderTitle: "Lorem ipsum dolor sit amet",
      onOrderDescription: "Lorem ipsum dolor sit amet consectetur. Id nisl orci in"
    },
    {
      onOrderBackgroundImage: onOrder4,
      onOrderUserImage: liveUser,
      onOrderUserName: "Ivan Morais",
      onOrderTitle: "Lorem ipsum dolor sit amet",
      onOrderDescription: "Lorem ipsum dolor sit amet consectetur. Id nisl orci in"
    },
    {
      onOrderBackgroundImage: onOrder3,
      onOrderUserImage: liveUser,
      onOrderUserName: "Ivan Morais",
      onOrderTitle: "Lorem ipsum dolor sit amet",
      onOrderDescription: "Lorem ipsum dolor sit amet consectetur. Id nisl orci in"
    },
  ]

  bargain = [
    {
      bargainBackgroundImage: onOrder4,
      bargainUserImage: liveUser,
      bargainUserName: "Ivan Morais",
      bargainTitle: "Lorem ipsum dolor sit amet",
      bargainDescription: "Lorem ipsum dolor sit amet consectetur. Id nisl orci in"
    },
    {
      bargainBackgroundImage: onOrder1,
      bargainUserImage: liveUser,
      bargainUserName: "Ivan Morais",
      bargainTitle: "Lorem ipsum dolor sit amet",
      bargainDescription: "Lorem ipsum dolor sit amet consectetur. Id nisl orci in"
    },
    {
      bargainBackgroundImage: onOrder2,
      bargainUserImage: liveUser,
      bargainUserName: "Ivan Morais",
      bargainTitle: "Lorem ipsum dolor sit amet",
      bargainDescription: "Lorem ipsum dolor sit amet consectetur. Id nisl orci in"
    },
    {
      bargainBackgroundImage: onOrder3,
      bargainUserImage: liveUser,
      bargainUserName: "Ivan Morais",
      bargainTitle: "Lorem ipsum dolor sit amet",
      bargainDescription: "Lorem ipsum dolor sit amet consectetur. Id nisl orci in"
    },
    {
      bargainBackgroundImage: onOrder4,
      bargainUserImage: liveUser,
      bargainUserName: "Ivan Morais",
      bargainTitle: "Lorem ipsum dolor sit amet",
      bargainDescription: "Lorem ipsum dolor sit amet consectetur. Id nisl orci in"
    },
    {
      bargainBackgroundImage: onOrder2,
      bargainUserImage: liveUser,
      bargainUserName: "Ivan Morais",
      bargainTitle: "Lorem ipsum dolor sit amet",
      bargainDescription: "Lorem ipsum dolor sit amet consectetur. Id nisl orci in"
    },
  ]

  closeMakeOfferPopup = () => {
    this.setState({ displayPopup: false });
    this.setState({ chooseCategoriesOpenClose: false })
  }

  openMakeOfferPopup = async () => {
    this.setState({ displayPopup: true });
  }

  toggleAsideMenuItem = (id: number, index: number) => {
    removeStorageData("categoryID");
    this.getSubCategoriesData(id);
    this.getTopSearchData(`category_id=${id}`);
    this.setState((prevState) => {
      return {
        openItemIndex: prevState.openItemIndex === index ? null : index,
        getSubCategoriesdata: [],
        subCategoryIDs: []
      }
    });
  };

  handleScroll = (e: any) => {
    const { scrollLeft, scrollWidth, clientWidth } = e.target;
    const scrollPosition = (scrollLeft / (scrollWidth - clientWidth)) * 100;
    this.setState({ scrollPosition });
  };

  toggleDrawer = (open: boolean) => (
    event: any,
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    this.setState({ showSidebar: open });
  };

  showALL = () => {
    this.setState({
      showAllLiveEvents: !this.state.showAllLiveEvents,
      showingAll: !this.state.showingAll
    });
  }

  showAllTopSelling = () => {
    this.setState({
      showAllTopSelling: !this.state.showAllTopSelling,
      showingAll: !this.state.showingAll
    });
  }

  handleShowAllOnOrders = () => {
    this.setState({
      showAllOnOrders: !this.state.showAllOnOrders,
      showingAll: !this.state.showingAll
    });
  };

  handleOnClickSubmit = async () => {
    const { description } = this.state;
    if (description.trim() === "") {
      this.setState({ descriptionErr: true });
      return;
    }
    await this.postOnOrderData();
  };

  toogleSuccessModal = () =>{
    this.setState({ isSuccessModal: !this.state.isSuccessModal });
  };

  handleOnOrderRequestModal = (selectedOrder : OnOrderData) =>{
    this.checkTokenPresentOrNot();
    this.setState({isRequestModal: !this.state.isRequestModal, selectedOnOrder: selectedOrder,selectedFiles: [], description: "", descriptionErr: false, imgUploadErr: ""})
  };

  toggleOnOrderModal = () =>{
    this.setState({isRequestModal: !this.state.isRequestModal})
  };

  apiCall = async (apiReqData: ApiPayloadType) => {
    const { contentType, method, endPoint, body, type } = apiReqData;
    this.setState({ loading: true });
    const tokenPresentOrNot = this.state.tokenPresentOrNot ?? null;
    const header = {
      "Content-Type": contentType,
      token: tokenPresentOrNot,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    body && type != 'formData' ?
      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getOnOrderData = async () => {
    this.getOnOrderDataApiID = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getOnOrderCollectionEndPoint,
      type: ""
    });
  };

  postOnOrderData = async () => {
    const { description, quantity, expectedDate, selectedFiles, selectedOnOrder } = this.state;
    this.setState({ isRequestModal: false});
    const formdata = new FormData();
    formdata.append("catalogue_id", String(selectedOnOrder?.productId));
    formdata.append("quantity", String(quantity));
    formdata.append("request_detail",description);
    formdata.append("expected_delivery",moment(expectedDate).format("DD/MM/YYYY"));

    selectedFiles.length && selectedFiles.forEach((file) => {
      formdata.append("attachments[]",file, file.name);
    });

    this.postOnOrderDataApiId = await this.apiCall({
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.createOnOrderEndPoint,
      body: formdata,
      type: "formData"
    });
   
  };

  createOnOrderSuccessCallBack = (responJson : CreateOnOrderApiRes) =>{
    this.setState({ isSuccessModal: true, isRequestModal: false, channelUrl : responJson.channel_url, loading: false }, async ()=>{
      this.loadChannels(responJson.channel_url || '', responJson.data.attributes.id || '');
    });
  };

  onOrderApiSuccessCallBack = (responseJson : OnOrderApiResponse) => {
    let onOrderSet : OnOrderData[] = []; 
    responseJson.catalogues.data.forEach((item) => {
      onOrderSet.push({
          title: item.attributes.title,
          description: item.attributes.description,
          sellerId: item.attributes.account.id,
          accountFullName: item.attributes.account.full_name,
          hostImage: item.attributes.host_image || '',
          productImageUrl: item.attributes?.product_images ? item.attributes?.product_images[0]?.url : '',
          productId: item.id,
      })
    });
    this.setState({ onOrderData: onOrderSet, wishListOnOrder: responseJson?.wishlist_items.product_ids });
  };

  truncatedDescription = (description: string) => {
    if (description.length <= 45) {
      return description;
    }
    return description.substring(0, 45) + ' ...';
  };

  truncatedTitle = (title: string) => {
    if (title.length <= 24) {
      return title;
    }
    return title.substring(0, 24) + ' ...';
  };

  handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
      const newImages: File[] = Array.from(files);
      const validFiles: File[] = [];
      const invalidFiles: File[] = [];
      let isSizeExceed: boolean = false;

      newImages.forEach((file) => {
        const isDuplicate = this.state.selectedFiles.some((selectedFile) => selectedFile.name === file.name && selectedFile.size === file.size);
        if (!validImageTypes.includes(file.type)) {
          invalidFiles.push(file);
        } else if (file.size > 5 * 1024 * 1024) {
          this.setState({ imgUploadErr: "Some files exceed the 5 MB size limit!" });
          isSizeExceed = true;
        } else if(!isDuplicate) {
          validFiles.push(file);
        }
      });

      if (this.state.selectedFiles.length + validFiles.length > 4) {
        this.setState({imgUploadErr: "You can upload a maximum of 4 images only"});
        return;
      }

      if (invalidFiles.length > 0) {
        this.setState({ imgUploadErr: "Only PNG, JPEG, or JPG files are allowed." });
      }

      if (!isSizeExceed && validFiles.length > 0) {
        this.setState((prevState) => ({
          selectedFiles: [...prevState.selectedFiles, ...validFiles],
        }));
      }
    }
  };

  handleRemoveImage = (indexToRemove: number) => {
    const { selectedFiles } = this.state;
      const newArr = selectedFiles.filter((_, i) => i !== indexToRemove);
      this.setState({ selectedFiles: newArr, imgUploadErr: "" })
  };

  handleUploadClick = () => {
    if (this.fileInputRef.current) {
      this.setState({imgUploadErr : ""});
      this.fileInputRef.current.click();
    }
  };

  handleDateChange = (date: Date | null) => {
    this.setState({ expectedDate: date });
  };

  handleDescriptionChange = (event : React.ChangeEvent<HTMLTextAreaElement>) => {
      this.setState({description: event.target.value, descriptionErr: false})
  };

  updateOrDeleteItem = (itemQuantity: number, isIncrement: boolean) => {
    if (isIncrement) {
      this.setState({ quantity: itemQuantity + 1 });
    } else if (!isIncrement && itemQuantity > 1) {
      this.setState({ quantity: itemQuantity - 1 });
    }
  };

  showModal = () => {
    this.setState({ isVisibleModal: true });
  };

  hideModal = () => {
    this.setState({ isVisibleModal: false });
  };

  goToFeed = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "BuyerFeed");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  closeTunedBox = () => {
    this.setState({
      openTunedBox: false
    })
  }

  updateWishListItem = async (id: number, type: string) => {
    this.checkTokenPresentOrNot()
    const buyertoken = await getStorageData("buerLoginToken");
    if (buyertoken) {
      const header = {"token": buyertoken,
        "Content-Type": "application/json"
      };

      if (type == "catalogue") { type = "product" 
    }
      const body = {
        "data": {
          "favouriteable_id": id,
          "type": type
        }
      }

      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiAddToWishList = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.favouriteItemEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  deleteWishListItem = async (id: number) => {
    const buyertoken = await getStorageData("buerLoginToken");
    if (buyertoken) {
      const header = {"token": buyertoken, "Content-Type": "application/json" };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiDeleteFromWishList = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.favouriteItemEndPoint}/${id}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.delete
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  handleSelectAllCategories=( event: React.ChangeEvent<HTMLInputElement>)=>{
    const isChecked = event.target.checked
    this.setState({  seletAllCategories: event.target.checked},
      () => {
        if (isChecked) {
            this.selectAllCategories();
        } else {
            this.unselectAllCategories();
        }
    });
  }

  selectAllCategories = () => {
    const { chooseCatergoriesData } = this.state; 
    const selectedCategories = chooseCatergoriesData.map((category:{id:string}) => category.id);
    this.setState({ checkedDataPost: selectedCategories });
  }

  unselectAllCategories=()=>{
    this.setState({ checkedDataPost: [] });
  }

  apiCallFunction = async (data: {method: string; endPoint: string;}) => {
    this.setState({
      loading: true
    })
    let { method, endPoint } = data;
    const header = { 
      "Content-Type": "application/json"};
    let apiRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
    return apiRequestMessage.messageId;
  };

  getCategories = async() => {
    this.getCategoryDataApiID = await this.apiCallFunction({
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getCategoriesEndPoint
    })
  }

  isApiSuccess = (response: {data:  Array<Category>}) => {
    return response ? true : false
  }

  apiSuccess = (apiCallId: string, response: {data:  Array<Category>}) => {
    if (apiCallId === this.getCategoryDataApiID) {
      this.setState({
        categoryData: response.data
      })
    }
    this.setState({
      loading: false
    })
  }

  handleCategorySort = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      categorySortValue: (event.target as HTMLInputElement).value
    })
  };

  handleNavigate = (prodId: number) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ProductDescription");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), prodId);
    this.send(message);
  };

  handleNavigateToChat = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ChatScreen");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  getCartItems = async () => {
    const buyertoken = await getStorageData("buerLoginToken");
    if (buyertoken) {
      const header = {"token": buyertoken, "Content-Type": "application/json" };
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.apiGetcartitemscount = requestMessage.messageId;

      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getCartDataEndPoint}`);
      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header));

      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType);

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  handleStoryStart = () => {
    this.setState({ hasUserInteracted : true})
  };

  handleClickOpen = (index: number) => {
    this.viewStory(index);
    this.setState({ instaModalIndex: index, currentSlide: index })
  };

  viewStory = async(index: number) => {
    this.viewStoryCallId = await this.apiCall(
      {
        contentType: configJSON.validationApiContentType,
        method: configJSON.validationApiMethodType,
        endPoint: configJSON.viewStoryEndPoint.replace("{userId}",this.state.allStoryHeader[index].attributes.creator_details.id),
        type: ""
      }
    );
  };

  handleClose = () => {
    this.setState({ instaModal: false })
  };

  handlePrev = (userIndex: number, direct?: boolean, event?: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => {
    event?.stopPropagation();
    if (this.state.currentStoryIndex - 1 >= 0 && !direct) {
      this.changeStory("prev");
    } else {
      if (this.swiperInstance) {
        this.viewStory(userIndex - 1);
        this.setState({ goToSlide: "prev", currentSlide: userIndex - 1 });
      }
    }
  };

  handleNext = (userIndex: number, direct?: boolean, event?: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => {
    event?.stopPropagation();
    let { allStoryHeader } = this.state;
    if (this.state.currentStoryIndex + 1 < this.state.userStory.length && !direct) {
      this.changeStory("next");
    } else {
      if (this.swiperInstance) {
        allStoryHeader[this.state.currentSlide].attributes.seen = true;
        this.setState({
          allStoryHeader
        }, () => {
          this.viewStory(userIndex + 1);
          this.setState({ goToSlide: "next", currentSlide: userIndex + 1 });
        })
      }
    }
  };

  setSwiperRef = (swiper: SwiperCore) => {
    this.swiperInstance = swiper;
  }; event?: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>

  handlePrevNext = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, userIndex: number) => {
    const element = event.currentTarget;
    const parent = element.parentElement
    const classNames = parent?.classList;
    if (classNames?.contains('swiper-slide-prev')) {
      this.handlePrev(userIndex + 1, true, event);
    } else if (classNames?.contains('swiper-slide-next')) {
      this.handleNext(userIndex - 1, true, event);
    }
  };

  fetchAllUserStories = async() => {
    this.allUserStoriesCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getAllStoriesEndPoint,
      type: ""
    });
  };

  allUserStoriesSuccessCallBack = (response: AllStoryResponse) => {
    if (response.data) {
      this.setState({
        allStoryHeader: response.data
      })
    }
  };

  viewStorySuccessCallBack = (response: AllStoryResponse) => {
    let { goToSlide } = this.state;
    this.setState({ instaModal: true, userStory : response.data, currentStoryIndex: 0 }, () => {
      if (goToSlide === "next") {
        this.swiperInstance?.slideNext();
      } else if (goToSlide === "prev") {
        this.swiperInstance?.slidePrev();
      }
      this.setState({ goToSlide: "" });
    });
  };

  calculateHours = (createdAt: string) => {
    const createdTime = new Date(createdAt).getTime();
    const currentTime = Date.now();
    const timeDifference = currentTime - createdTime;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    if (hours > 0) {
      return `${hours}h`;
    } else if (minutes > 0) {
      return `${minutes}m`;
    } else {
      return `${seconds}s`;
    }
  };

toggleMute = () => {
  this.setState({ storyMuted: !this.state.storyMuted });
};

  likeStory = async (storyId: number) => {
    this.likeStoryCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.likeStoryEndPoint,
      body: {
        "data": {
          "attributes": {
            "likeable_id": storyId,
            "likeable_type": "BxBlockPosts::Story"
          }
        }
      },
      type: ""
    })
  };

  likeStorySuccessCallBack = (response: LikeResponse) => {
    let { userStory } = this.state;
    const oldStory = userStory;
    if (response.data) {
      oldStory.forEach((item, index) => {
        if (item.attributes.id === response.data.attributes.likeable_id) {
          userStory[index].attributes.liked = !item.attributes.liked;
        }
      })
    };
    if (response.message) {
      userStory[this.state.currentStoryIndex].attributes.liked = !userStory[this.state.currentStoryIndex].attributes.liked;
    }
    this.setState({ userStory });
  };

  showNextButton = (userIndex: number) => {
    const { allStoryHeader, userStory, currentStoryIndex } = this.state;
    if (userIndex + 1 < allStoryHeader.length || (userIndex + 1 === allStoryHeader.length && currentStoryIndex + 1 < userStory.length)) {
      return true;
    }
    return false
  };

  showPrevButton = (userIndex: number) => {
    const { currentStoryIndex } = this.state;
    if (userIndex > 0 || (userIndex === 0 && currentStoryIndex !== 0)) {
      return true;
    }
    return false
  };
  
  changeStory = (direction: "next" | "prev") => {
    let { currentStoryIndex, userStory, currentSlide, allStoryHeader } = this.state;

    if (userStory.length === 0) return;

    let newIndex = currentStoryIndex;

    if (direction === 'next') {
      newIndex = currentStoryIndex + 1;
    } else if (direction === 'prev') {
      newIndex = currentStoryIndex - 1;
    }
    if (newIndex < 0) {
      this.handlePrev(currentSlide, true);
      newIndex = 0;
    } else if (newIndex === userStory.length) {
      if (currentSlide + 1 < allStoryHeader.length) {
        this.handleNext(currentSlide, true);
      } else {
        allStoryHeader[currentSlide].attributes.seen = true;
        this.handleClose();
      }
      newIndex = 0;
    }

    this.setState({ currentStoryIndex: newIndex, allStoryHeader });
  };

  // Customizable Area End
}
